import { type MouseEvent, type ReactNode, useCallback } from 'react';

import logoImg from '../../assets/logo.png';
import { useSidebar } from '../../hooks/useSidebar';
import { SidebarItem } from '../SidebarItem';
import {
  Container,
  Logo,
  LogoText,
  LogoutIcon,
  MenuIcon,
  NavLogoContainer,
  OverlayToggleToMobile,
  SpaceAccordion,
  SpaceToEnd,
  ToggleSidebar,
  ToggleSidebarContainerToMobile,
} from './styles';

interface ISidebarProps {
  children?: ReactNode;
  footerChildren?: ReactNode;
  isShrunken?: boolean;
  logoRedirectTo?: string;
  logoText?: string;
  onSignOut?: () => Promise<void>;
}

const titleLogOut = 'Log out';

export const Sidebar = ({
  children,
  footerChildren,
  isShrunken = false,
  logoRedirectTo = '',
  logoText = 'Benefit Education',
  onSignOut,
}: ISidebarProps): JSX.Element => {
  const { handleShrinkSidebar, openedMenuItems, handleOpenedMenuItems } =
    useSidebar();

  const handleShrinkSidebarToAccordion = useCallback(
    (values: string[]) => {
      handleOpenedMenuItems(values);

      const someValueTrue = values.some((value) => value);
      if (someValueTrue) handleShrinkSidebar(false);
    },
    [handleShrinkSidebar, handleOpenedMenuItems]
  );

  const handleToggleIsShrunken = useCallback(
    (e: MouseEvent<HTMLElement>, preventDefault = false) => {
      preventDefault && e.preventDefault();
      e.stopPropagation();

      if (window.matchMedia('(max-width: 600px)').matches) {
        const isShrunkenToMobile = false;
        handleShrinkSidebar(isShrunkenToMobile);
      }
    },
    [handleShrinkSidebar]
  );

  const handleToggleIsShrunkenAndPrevent = useCallback(
    (e: MouseEvent<HTMLElement>) => {
      handleToggleIsShrunken(e, true);
    },
    [handleToggleIsShrunken]
  );

  const handleLogout = useCallback(() => {
    void onSignOut?.();
  }, [onSignOut]);

  return (
    <>
      <OverlayToggleToMobile
        $isShrunken={isShrunken}
        onClick={handleToggleIsShrunken}
      />
      <Container $isShrunken={isShrunken} data-cy="sidebar">
        <NavLogoContainer
          $isShrunken={isShrunken}
          data-cy="navLink-logo"
          onClick={handleToggleIsShrunken}
          to={logoRedirectTo}
        >
          <Logo src={logoImg} />
          <LogoText $isShrunken={isShrunken}>{logoText}</LogoText>
          <ToggleSidebarContainerToMobile $isShrunken={isShrunken}>
            <ToggleSidebar
              data-cy="button-menu"
              onClick={handleToggleIsShrunkenAndPrevent}
              type="button"
            >
              <MenuIcon />
            </ToggleSidebar>
          </ToggleSidebarContainerToMobile>
        </NavLogoContainer>
        <SpaceAccordion
          type="multiple"
          onValueChange={handleShrinkSidebarToAccordion}
          data-cy="extra-nav-link-container"
          value={openedMenuItems}
        >
          {children}

          <SpaceToEnd>{footerChildren}</SpaceToEnd>
        </SpaceAccordion>
        <SidebarItem
          title={titleLogOut}
          dataCy="button-logout"
          isShrunken={isShrunken}
          onClick={handleLogout}
          type="button"
          icon={<LogoutIcon />}
        ></SidebarItem>
      </Container>
    </>
  );
};
