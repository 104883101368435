import { IoPersonCircleSharp } from 'react-icons/io5';
import { MdKeyboardArrowDown, MdKeyboardArrowUp } from 'react-icons/md';
import styled from 'styled-components';

import { colors } from '@gbs-monorepo-packages/styles';

import { LazyImage } from '../LazyImage';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  padding: 1rem 0.5rem;
  cursor: pointer;

  &:hover {
    background-color: ${colors.backgroundHover};
  }
`;

export const TitleContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  font-size: 0.875rem;
`;

export const ItemsContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding: 0.5rem 0 0.5rem 3rem;
`;

export const IconContainer = styled.div``;

export const LazyImageCustom = styled(LazyImage)`
  border-radius: 50%;
  height: 40px;
  width: 40px;
  object-fit: cover;
`;

export const PersonCircleIcon = styled(IoPersonCircleSharp)`
  color: ${colors.text};
  width: 2.75rem;
  height: 2.75rem;
`;

export const IndicatorButton = styled.button`
  min-width: 22px;
  width: 22px;
  height: 22px;
  background-color: transparent;
  border: none;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;

  &:hover {
    border: 1px solid ${colors.gray250};
  }
`;

interface IIndicatorIconProps {
  $read: boolean;
}

export const IndicatorIcon = styled.div<IIndicatorIconProps>`
  width: 8px;
  height: 8px;
  border-radius: 50%;
  background-color: ${({ $read }) =>
    $read ? 'transparent' : `${colors.blue300}`};
`;

export const InfoContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  margin-left: 0.5rem;
`;

export const Title = styled.div`
  font-weight: 500;
  margin-bottom: 0.5rem;
  word-break: break-word;
`;

export const DescriptionContainer = styled.div`
  display: flex;
  flex-direction: row;
  padding: 0.5rem 0 0.5rem 0.5rem;

  &:hover {
    background-color: ${colors.backgroundHover};
  }
`;

export const Description = styled.div`
  display: flex;
  flex-grow: 1;
  align-items: center;
  gap: 0.875rem;
  font-size: 0.875rem;
`;

export const Time = styled.div`
  padding-left: 0.3rem;
  font-size: 0.75rem;
  color: ${colors.gray300};
  white-space: nowrap;
  align-content: center;
`;

export const GroupTime = styled(Time)`
  flex-grow: 1;
  text-align: end;
`;

export const ExpandedMessageContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-top: 0.3rem;
  gap: 0.5rem;
`;

export const ExpandedMessage = styled.p`
  font-size: 0.875rem;

  &:hover {
    text-decoration: underline;
  }
`;

export const ArrowUp = styled(MdKeyboardArrowUp)`
  height: 1rem;
  width: 1rem;
`;

export const ArrowDown = styled(MdKeyboardArrowDown)`
  height: 1rem;
  width: 1rem;
`;
