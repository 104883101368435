import styled from 'styled-components';

import {
  BaseLoading,
  BaseTabs,
  BaseTabsContent,
  BaseTabsList,
  BaseTabsTrigger,
} from '@gbs-monorepo-packages/common';
import { colors, radii } from '@gbs-monorepo-packages/styles';
import * as Accordion from '@radix-ui/react-accordion';

export const MainContainer = styled.main`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  overflow: hidden;
  background-color: ${colors.lightGray50};
  margin: 0;
  padding: 1.875rem;
`;

export const TabsRoot = styled(BaseTabs)`
  display: flex;
  flex-direction: column;
  width: 100%;
  flex-grow: 1;
`;

export const TabsList = styled(BaseTabsList)`
  flex-shrink: 0;
  display: flex;
  border-bottom: 1px solid ${colors.lightGray200};
`;

export const TabsTrigger = styled(BaseTabsTrigger)`
  font-family: inherit;
  background-color: ${colors.white300};
  padding: 0 20px;
  height: 45px;
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 15px;
  line-height: 1;
  color: ${colors.labelText};
  user-select: none;
  border: none;

  &:first-child {
    border-top-left-radius: ${radii.xl};
  }
  &:last-child {
    border-top-right-radius: ${radii.xl};
  }

  &[data-state='active'] {
    color: ${colors.labelText};
    font-weight: bold;
    border-bottom: 2px solid currentColor;
  }
`;

export const TabsContent = styled(BaseTabsContent)`
  flex-grow: 1;
  padding: 20px;
  background-color: ${colors.white300};
  border-bottom-left-radius: ${radii.xl};
  border-bottom-right-radius: ${radii.xl};
  outline: none;
`;

export const Header = styled.header`
  align-items: center;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin-bottom: 3rem;
`;

export const TitlePage = styled.h1`
  font-size: 2rem;
  font-weight: 700;
  @media (max-width: 390px) {
    font-size: 1.8rem;
  }
  @media (max-width: 337px) {
    font-size: 1.5rem;
  }
  @media (max-width: 279px) {
    font-size: 1.2rem;
  }
`;

export const UploadButtonContainer = styled.div`
  align-items: center;
  display: inline-flex;
  height: 100%;
  justify-content: flex-end;
`;

export const ButtonContent = styled.div`
  align-items: center;
  display: flex;
  justify-content: space-between;
`;

export const MainContent = styled.div`
  background-color: ${colors.white300};
  display: flex;
  flex-direction: column;
  height: calc(100% - 2rem);
  padding: 1rem;
`;

export const LoadingContainer = styled.div`
  display: flex;
  flex-grow: 1;
  justify-content: center;
`;

export const Loading = styled(BaseLoading)`
  height: 3.25rem;
  width: 3.25rem;
`;

export const NoTemplatesContainer = styled.div`
  display: flex;
  flex-grow: 1;
  justify-content: center;
  width: 100%;
  height: 100%;
`;

export const NoTemplatesText = styled.p`
  font-size: 1rem;
  font-weight: 400;
  color: ${colors.textLight};
`;

export const TemplateCardContainer = styled.div`
  scroll-snap-align: start;
  border-bottom: 1px solid ${colors.gray100};
  border-radius: ${radii.xs};
  color: ${colors.textLight};
  display: flex;
  min-height: 4rem;
  padding: 0.5rem 1rem;
  scroll-snap-align: start;
  text-decoration: none;

  &:hover {
    background-color: ${colors.backgroundHover};
  }
`;

export const AccordionRoot = styled(Accordion.Root)`
  display: flex;
  flex-direction: column;
  width: 100%;
  flex-grow: 1;
`;

export const GroupTemplateCardContainer = styled.div`
  border-bottom: 1px solid ${colors.lightGray200};
  color: ${colors.textLight};
  margin-bottom: 1rem;
  font-size: 15px;
  line-height: 1;
`;
