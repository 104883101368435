import {
  MdAdd,
  MdClose,
  MdOutlineVisibility,
  MdOutlineVisibilityOff,
} from 'react-icons/md';
import styled from 'styled-components';

import {
  BaseTabs,
  BaseTabsContent,
  BaseTabsList,
  BaseTabsTrigger,
  FormModal,
} from '@gbs-monorepo-packages/common';
import { colors, radii, scrollbar } from '@gbs-monorepo-packages/styles';

export const CustomFormModal = styled(FormModal)`
  height: 26rem;

  & form {
    flex-grow: 1;
  }
`;

export const FieldsetCheckBox = styled.fieldset`
  all: unset;
  align-items: center;
  border: none;
  display: flex;
  flex-direction: row;
  margin-bottom: 0.75rem;
  min-height: 1.5rem;
`;

export const LabelCheckBox = styled.label`
  color: ${colors.labelText};
  font-size: 0.875rem;
  line-height: 1.3;
  overflow: hidden;
  padding: 1px 6px;
  user-select: none;
`;

export const CheckContainer = styled.div`
  align-items: center;
  display: inline-flex;
  height: 100%;
  justify-content: flex-end;
  padding-right: 0.5rem;
`;

export const Label = styled(LabelCheckBox)`
  background-color: ${colors.white300};
  left: 16px;
  max-width: 90%;
  position: absolute;
  text-overflow: ellipsis;
  top: 50%;
  transform-origin: 0;
  transform: translateY(-50%);
  transition: transform 0.15s ease-in-out, top 0.15s ease-in-out,
    padding 0.15s ease-in-out;
  white-space: nowrap;
  z-index: 1;
`;

export const LockedIcon = styled(MdOutlineVisibility)``;
export const UnLockedIcon = styled(MdOutlineVisibilityOff)``;

export const CustomBaseTabs = styled(BaseTabs)`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
`;

export const CustomTabsTrigger = styled(BaseTabsTrigger)`
  font-family: inherit;
  background-color: ${colors.white300};
  padding: 0 20px;
  height: 2rem;
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 15px;
  line-height: 1;
  color: ${colors.labelText};
  user-select: none;
  border: none;

  &:first-child {
    border-top-left-radius: ${radii.sm};
  }
  &:last-child {
    border-top-right-radius: ${radii.sm};
  }

  &[data-state='active'] {
    color: ${colors.labelText};
    font-weight: bold;
    border-bottom: 2px solid currentColor;
  }
`;

export const CustomTabList = styled(BaseTabsList)`
  display: flex;
  flex-direction: row;
  margin-bottom: 1rem;
`;

export const CustomTabContainer = styled(BaseTabsContent)`
  display: flex;
  flex-direction: column;

  &[data-state='active'] {
    flex-grow: 1;
  }
`;

export const ExcludedWordsFieldset = styled.fieldset`
  color: ${colors.labelText};
  padding: 1rem;
  border-radius: ${radii.sm};
  margin: 0;

  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  flex-grow: 1;
  align-content: flex-start;

  max-height: 15.9rem;
  overflow: auto;

  gap: 0.5rem;

  ${scrollbar}
`;

export const ExcludedWordsFieldsetLegend = styled.legend`
  padding: 0 0.5rem 0 0.5rem;
`;

interface IExcludedWordCardProps {
  isInput?: boolean;
}

export const ExcludedWordCard = styled.div<IExcludedWordCardProps>`
  padding: 0.4rem;
  border: ${({ isInput = false }) => (isInput ? '2px' : '1px')} solid
    ${colors.labelText};
  border-radius: 0.4rem;

  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 0.2rem;

  &:hover {
    background-color: ${({ isInput = false }) =>
      isInput ? colors.white300 : colors.lightGray100};
  }
`;

export const ExcludedWordCardTitle = styled.p`
  color: ${colors.labelText};
  line-break: anywhere;
`;

export const ExcludedWordCardInput = styled.input``;

export const CloseIcon = styled(MdClose)`
  cursor: pointer;
  min-width: 1rem;
`;

export const AddIcon = styled(MdAdd)`
  cursor: pointer;
`;
