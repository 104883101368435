import { type IResponseData } from '@gbs-monorepo-packages/common';

import { api } from './api';

const translationPath = 'api/translations';

export interface ISearchExcludedWordsByClientProps {
  clientId: number;
}

export interface IUpdateTranslationSettingsProps {
  clientId: number;
  settings: ITranslationSettings;
}

export interface ITranslationSettings {
  id: number | null;
  /** Excluded words altogether separated by || */
  excludedWords: string | null;
}

export const searchTranslationSettingsByClient = async ({
  clientId,
}: ISearchExcludedWordsByClientProps): Promise<ITranslationSettings> => {
  const result = await api
    .get<IResponseData<ITranslationSettings>>(
      `${translationPath}/settings/client/${clientId.toString()}`
    )
    .then((resp) => resp.data.data)
    .catch((err) => {
      throw err;
    });

  return result;
};

export const updateTranslationSettings = async ({
  clientId,
  settings,
}: IUpdateTranslationSettingsProps): Promise<ITranslationSettings> => {
  const data = new FormData();

  data.append('translationExcludingWords', settings.excludedWords ?? '');

  const result = await api
    .post<IResponseData<ITranslationSettings>>(
      `${translationPath}/settings/client/${clientId.toString()}`,
      data
    )
    .catch((err) => {
      throw err;
    });

  return result.data.data;
};
