/* eslint-disable no-restricted-syntax */
import {
  type AxiosError,
  type AxiosInstance,
  type AxiosRequestConfig,
} from 'axios';

import {
  type IFrontendUrls,
  INIT_PAGE,
  type IPaginationMetaProps,
  type IPaginationResponseData,
  type IResponseDefaultContent,
  type IRole,
  type IThrowsError,
  Logger,
  NO_LIMIT_PAGE,
  Roles,
} from '@gbs-monorepo-packages/common';

import { generateUserUrlSearchParams } from '../utils';

export interface IAuthUrls {
  profileUpdate: string;
  refresh: string;
  resetPassword: string;
  signIn: string;
  signOut: string;
  unregister: string;
}
export interface IResponseWithCode {
  code: number;
  message: string;
}

export interface IResponseAccountDTO extends IResponseDefaultContent {
  data: IUserDTO;
  token: string;
}

export type IResponseAccountWithCodeDTO = IResponseAccountDTO &
  IResponseWithCode;

export interface IForgotCredentialsDTO {
  email: string;
}

export interface IResetPasswordCredentialsDTO {
  email: string;
  password: string;
}

export interface ISignInCredentialsDTO {
  email: string;
  password: string;
}

export interface IUnregisterCredentialsDTO {
  id: number;
}

export interface ISignOutCredentialsDTO {
  email: string;
}

export interface IClientCompanyDTO {
  id: number;
  clientId: number;
  firstName: string;
  lastName: string;
}

interface IAccountFile {
  cloudStorageKey: string;
  path: string;
  extension: string;
  createdAt: string;
}

export interface IGetManagersProps {
  page: number;
  limit: number;
  filter: string;
  all?: boolean;
}

export interface IPaginationManagersDTO {
  data: IUserDTO[];
  meta: IPaginationMetaProps;
}

export interface IGetUsersByRolesProps {
  roles: string;
  page: number;
  limit: number;
  filter: string;
  all?: boolean;
  clientId: number | null;
}

export interface IPaginationUsersByRolesDTO {
  data: IUserDTO[];
  meta: IPaginationMetaProps;
}

export interface ISettingsDTO {
  setting: number;
  settingValue: string;
}

export interface IUserDTO {
  id: number;
  email: string;
  firstName: string;
  lastName: string;
  permissions?: string[]; // not implemented yet
  roles: string[];
  status?: string;
  clientId: number;
  clientCompany?: {
    id: number;
    clientName: string;
    clientCompanyFile: {
      cloudStorageKey: string;
      createdAt: string;
      extension: string;
      path: string;
    };
    isAgency: boolean | null;
  };
  currentRole?: IRole | null;
  phone?: string;
  accountFile?: IAccountFile | null;
  settings?: ISettingsDTO[];
}

export type IInviteUserDTO = Omit<IUserDTO, 'id' | 'permissions' | 'status'>;

const edgeRoute: string =
  import.meta.env.VITE_EDGE_URL ?? 'http://localhost:5173';

const adminRoute: string =
  import.meta.env.VITE_ADMIN_URL ?? 'http://localhost:5174';

const clientRoute: string =
  import.meta.env.VITE_CLIENT_URL ?? 'http://localhost:5175';
export class AuthService {
  private readonly api: AxiosInstance;
  private readonly url = {
    forgotPassword: 'reset-password',
    resetPassword: 'reset-password/change-password',
    refresh: 'api/refresh',
    signIn: 'api/login',
    signOut: 'api/logout',
    unregister: 'delete-account',
    getManagers: 'api/admin-user/managers',
    usersByRoles: 'api/admin-user/users/roles',
  };

  private readonly frontendUrl: IFrontendUrls = {
    edgeRoute,
    adminRoute,
    clientRoute,
  };

  constructor(
    axiosInstance: AxiosInstance,
    url?: Partial<IAuthUrls>,
    frontendUrl?: Partial<IFrontendUrls>
  ) {
    this.api = axiosInstance;
    if (url) {
      this.url = { ...this.url, ...url };
    }
    if (frontendUrl) {
      this.frontendUrl = { ...this.frontendUrl, ...frontendUrl };
    }
  }

  public async forgotPassword({ email }: IForgotCredentialsDTO): Promise<void> {
    const response = await this.api
      .post<IResponseDefaultContent>(this.url.forgotPassword, { email })
      .then((resp) => resp.data)
      .catch((err: IThrowsError) => {
        // throw err;
        Logger.debug('auth err:', err);
      });

    Logger.debug('response: ', response);
  }

  public async resetPassword({
    email,
    password,
  }: IResetPasswordCredentialsDTO): Promise<IUserDTO> {
    const { data } = await this.api
      .patch<IResponseAccountDTO>(this.url.resetPassword, {
        email,
        password,
      })
      .then((resp) => resp.data)
      .catch((err: IThrowsError) => {
        throw err;
      });

    return data;
  }

  public async refresh(): Promise<void> {
    const response = await this.api
      .get<IResponseAccountWithCodeDTO>(this.url.refresh)
      .then((resp) => {
        localStorage.setItem('JWT_TOKEN', resp.data.token);
        return resp.data;
      })
      .catch((err: AxiosError<IResponseWithCode>) => {
        throw err;
      });

    Logger.debug('response: ', response);
  }

  public async signIn({
    email,
    password,
  }: ISignInCredentialsDTO): Promise<IResponseAccountWithCodeDTO> {
    const data = await this.api
      .post<IResponseAccountWithCodeDTO>(this.url.signIn, { email, password })
      .then((resp) => resp.data)
      .catch((err: AxiosError<IResponseWithCode>) => {
        throw err;
      });

    return data;
  }

  public getUserMainPortal({ roles }: IUserDTO): string {
    switch (true) {
      case roles.includes(Roles.ADMIN):
      case roles.includes(Roles.BROKER_CONSULTANT):
      case roles.includes(Roles.RENEWAL_COORDINATOR):
      case roles.includes(Roles.RENEWAL_ANALYST):
      case roles.includes(Roles.ACCOUNT_MANAGER):
      case roles.includes(Roles.CLIENT_MANAGER):
      case roles.includes(Roles.ACCOUNT_SUPPORT):
        return this.frontendUrl.adminRoute;
      case roles.includes(Roles.CLIENT):
        return this.frontendUrl.clientRoute;
      default:
        return this.frontendUrl.edgeRoute;
    }
  }

  public getRedirectPortal(usrData: IUserDTO, token: string): string {
    const currentPortal = window.location.origin;
    let redirectPortal = this.getUserMainPortal(usrData);

    if (currentPortal === redirectPortal) {
      return '';
    }

    const userInfoUrlSearchParams = generateUserUrlSearchParams(usrData, token);

    redirectPortal += `/permission-redirect?${userInfoUrlSearchParams.toString()}`;

    return redirectPortal;
  }

  public async signOut(): Promise<void> {
    const response = await this.api
      .get<string>(this.url.signOut)
      .then((resp) => {
        localStorage.removeItem('JWT_TOKEN');
        return resp.data;
      })
      .catch((err: AxiosError<string>) => {
        throw err;
      });

    Logger.debug('response: ', response);
  }

  public async unregister({ id }: IUnregisterCredentialsDTO): Promise<void> {
    const response = await this.api
      .delete<IResponseDefaultContent>(`${this.url.unregister}?id=${id}`)
      .then((resp) => resp.data)
      .catch((err: IThrowsError) => {
        // throw err;
        Logger.debug('auth err:', err);
      });

    Logger.debug('response: ', response);
  }

  public async getManagers(
    { page, limit, filter, all = false }: IGetManagersProps,
    config?: AxiosRequestConfig
  ): Promise<IPaginationManagersDTO> {
    const result = await this.api
      .get<IPaginationResponseData<IUserDTO[]>>(
        `${this.url.getManagers}?page=${all ? INIT_PAGE : page}&limit=${
          all ? NO_LIMIT_PAGE : limit
        }&filter=${all ? '' : encodeURIComponent(filter)}`,
        config
      )
      .then((resp) => resp.data)
      .catch((err: IThrowsError) => {
        throw err;
      });

    return result;
  }

  public async usersByRoles(
    {
      roles,
      page,
      limit,
      filter,
      all = false,
      clientId = null,
    }: IGetUsersByRolesProps,
    config?: AxiosRequestConfig
  ): Promise<IPaginationUsersByRolesDTO> {
    const result = await this.api
      .get<IPaginationResponseData<IUserDTO[]>>(
        `${this.url.usersByRoles}?roles=${roles}&page=${
          all ? INIT_PAGE : page
        }&limit=${all ? NO_LIMIT_PAGE : limit}&filter=${
          all ? '' : encodeURIComponent(filter)
        }${clientId !== null ? `&clientId=${clientId}` : ''}`,
        config
      )
      .then((resp) => {
        return resp.data;
      })
      .catch((err: IThrowsError) => {
        throw err;
      });

    return result;
  }

  getEdgeRoute(): string {
    return this.frontendUrl.edgeRoute;
  }

  getAdminRoute(): string {
    return this.frontendUrl.adminRoute;
  }

  getClientRoute(): string {
    return this.frontendUrl.clientRoute;
  }
}
