import { getRouteFrom } from '@gbs-monorepo-packages/common';
export interface IRoutePath {
  prefixPath: string;
  path: string;
}

const rootPath = {
  prefixPath: '/',
  path: '',
} as const;

export const CLIENTS = { ...rootPath, path: 'companies' } as const;
export const CLIENT = { ...rootPath, path: 'companies/:clientId' } as const;
export const COURSE_VIEWER = {
  ...rootPath,
  path: 'view/:viewURL',
} as const;
export const SUBDOMAIN_COURSE_VIEWER = {
  ...rootPath,
  path: ':viewURL',
} as const;
export const DASHBOARD = { ...rootPath, path: 'dashboard' } as const;
export const EDIT_COURSE = {
  ...rootPath,
  path: 'company/:clientId/courses/:courseId/edit',
} as const;
export const CREATE_TEMPLATE = {
  ...rootPath,
  path: 'company/:clientId/template/:templateId/edit',
} as const;
export const FORGOT_PASSWORD = {
  ...rootPath,
  path: 'forgot-password',
} as const;
export const HOME = { ...rootPath, path: 'home' } as const;
export const LOGIN = { ...rootPath, path: 'login' } as const;
export const REDIRECT_LOGIN = { ...rootPath, path: 'redirect-login' } as const;
export const ALL_MEMBERS = { ...rootPath, path: 'members' } as const;
export const PROFILE = { ...rootPath, path: 'profile' } as const;
export const NOT_FOUND = { ...rootPath, path: '404' } as const;
export const REDIRECT = { ...rootPath } as const;
export const RESET_PASSWORD = { ...rootPath, path: 'reset-password' } as const;
export const TEMPLATE_VIEW = {
  ...rootPath,
  path: 'view-template/:templateId',
} as const;
export const WEB_BUILDER = { ...rootPath, path: 'web-builder' } as const;

const rootCompanyPath = {
  prefixPath: `/${CLIENT.path}`,
  path: '',
} as const;

export const COURSES = { ...rootCompanyPath, path: 'courses' } as const;
export const ANALYTICS = { ...rootCompanyPath, path: 'analytics' } as const;
export const COURSES_TEMPLATE = {
  ...rootCompanyPath,
  path: 'templates',
} as const;
export const MEMBERS = {
  ...rootCompanyPath,
  path: 'members',
} as const;
export const SETTINGS = { ...rootCompanyPath, path: 'settings' } as const;
export const PERMISSION_REDIRECT = {
  ...rootPath,
  path: 'permission-redirect',
} as const;

export const FOLDERS = {
  ...rootCompanyPath,
  path: 'folder/:typeFolder',
} as const;
export const FOLDER = {
  ...rootCompanyPath,
  path: 'folder/:typeFolder/:folderId',
} as const;
const rootFolderPath = {
  prefixPath: getRouteFrom(FOLDER),
  path: '',
} as const;
export const DOCUMENTS = {
  ...rootFolderPath,
  path: 'documents',
} as const;

// edit course
const rootPathEditCourseModalButtonLink = {
  prefixPath: getRouteFrom(EDIT_COURSE),
  path: '',
} as const;

export const EDIT_COURSE_MODAL_BUTTON_LINK_FOLDER = {
  ...rootPathEditCourseModalButtonLink,
  path: 'folder',
} as const;

const rootPathEditCourseModalButtonItemsLink = {
  prefixPath: getRouteFrom(EDIT_COURSE_MODAL_BUTTON_LINK_FOLDER),
  path: '',
} as const;

export const EDIT_COURSE_MODAL_BUTTON_LINK_FOLDER_ITEMS = {
  ...rootPathEditCourseModalButtonItemsLink,
  path: ':folderId/items',
} as const;

// template
const rootPathEditTemplateModalButtonLink = {
  prefixPath: getRouteFrom(CREATE_TEMPLATE),
  path: '',
} as const;

export const CREATE_TEMPLATE_MODAL_BUTTON_LINK_FOLDER = {
  ...rootPathEditTemplateModalButtonLink,
  path: 'folder',
} as const;

const rootPathEditTemplateModalButtonItemsLink = {
  prefixPath: getRouteFrom(CREATE_TEMPLATE_MODAL_BUTTON_LINK_FOLDER),
  path: '',
} as const;

export const CREATE_TEMPLATE_MODAL_BUTTON_LINK_FOLDER_ITEMS = {
  ...rootPathEditTemplateModalButtonItemsLink,
  path: ':folderId/items',
} as const;
