import { type AxiosInstance } from 'axios';
import { produce } from 'immer';
import {
  type Dispatch,
  type MouseEvent,
  type ReactNode,
  type SetStateAction,
  createContext,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { generatePath, useLocation, useNavigate } from 'react-router-dom';

import { DefaultDescription, LIMIT_PAGE, StorageKey } from '../constants';
import { useToast } from '../hooks';
import {
  type IApiThrowsError,
  type IChatTopicResponseDTO,
  type IFrontendUrls,
  type IGroupedNotificationDTO,
  type IMessageDTO,
  type INotificationDTO,
  type IPaginationMetaProps,
  type IRedirectNotification,
  type IRenewalBaseSerializedDataProps,
  type IRenewalCreationSerializedDataProps,
  NotificationService,
  NotificationType,
} from '../services';
import {
  ABORT_REQUEST_REASON,
  type IRequestAbortController,
  Logger,
  assertAbortReason,
  generateAbortRequest,
  getRouteFrom,
  getStorage,
  isAbortError,
  removeStorage,
  setStorage,
} from '../utils';

export interface INotificationContextProps<T = any> {
  notifications: INotificationDTO[];
  notificationsFiltered: INotificationDTO[];
  setNotificationsFiltered: Dispatch<SetStateAction<INotificationDTO[]>>;
  showOnlyUnread: boolean;
  setShowOnlyUnread: Dispatch<SetStateAction<boolean>>;
  showNotificationBadge: boolean;
  setShowNotificationBadge: Dispatch<SetStateAction<boolean>>;
  loadingListNotifications: boolean;
  loadingToggleNotificationId: number | null;
  loadingMarkAllNotifications: boolean;
  hubUrl: string | null;
  setHubUrl: Dispatch<SetStateAction<string | null>>;
  topics: Record<string, string> | null;
  setTopics: Dispatch<SetStateAction<Record<string, string> | null>>;
  showMarkAllButton: boolean;
  isOpen: boolean;
  setIsOpen: Dispatch<SetStateAction<boolean>>;
  paginationMeta: IPaginationMetaProps | null;
  clientIdSelected: number | null;
  setClientIdSelected: Dispatch<SetStateAction<number | null>>;
  getHubUrl: () => Promise<void>;
  listNextNotifications: (currentPage?: number, limit?: number) => void;
  listNotifications: (page?: number, limit?: number) => void;
  handleOnMessageReceived: (e: MessageEvent) => Promise<void>;
  handleMarkAllAsRead: () => void;
  handleClickNotification: (
    notification: INotificationDTO,
    clientId?: number,
    selectedByClientId?: (id: string) => Promise<T>
  ) => Promise<void>;
  handleToggleStatus: (
    e: MouseEvent<HTMLButtonElement | HTMLDivElement>,
    id: number[] | number
  ) => void;
  handleOpenChange: (value: boolean) => void;
  groupNotifications: (
    notificationsData: INotificationDTO[]
  ) => Array<IGroupedNotificationDTO | INotificationDTO>;
  recoverNotificationTitle: (notificationType: NotificationType) => string;
}

export const NotificationContext = createContext<INotificationContextProps>(
  {} as INotificationContextProps
);

interface INotificationProps {
  children: ReactNode;
  axiosInstance: AxiosInstance;
  frontendUrls?: Partial<IFrontendUrls>;
  repository: 'admin' | 'client' | 'edge';
  redirectNotification: IRedirectNotification[];
}

export const NotificationProvider = ({
  children,
  axiosInstance,
  repository,
  redirectNotification,
  frontendUrls = {
    edgeRoute: 'http://localhost:5173',
    adminRoute: 'http://localhost:5174',
    clientRoute: 'http://localhost:5175',
  },
}: INotificationProps): JSX.Element => {
  const notificationService = useMemo(
    () =>
      new NotificationService(
        axiosInstance,
        redirectNotification,
        repository,
        frontendUrls
      ),
    [axiosInstance, frontendUrls, redirectNotification, repository]
  );

  const [showOnlyUnread, setShowOnlyUnread] = useState(false);
  const [notifications, setNotifications] = useState<INotificationDTO[]>([]);
  const [notificationsFiltered, setNotificationsFiltered] = useState<
    INotificationDTO[]
  >([]);
  const [loadingListNotifications, setLoadingListNotifications] =
    useState(false);
  const [loadingToggleNotificationId, setLoadingToggleNotificationId] =
    useState<number | null>(null);
  const [loadingMarkAllNotifications, setLoadingMarkAllNotifications] =
    useState(false);
  const [paginationMeta, setPaginationMeta] =
    useState<IPaginationMetaProps | null>(null);
  const [isOpen, setIsOpen] = useState(false);
  const [showNotificationBadge, setShowNotificationBadge] = useState(false);
  const [hubUrl, setHubUrl] = useState<string | null>(null);
  const [topics, setTopics] = useState<Record<string, string> | null>(null);
  const [clientIdSelected, setClientIdSelected] = useState<number | null>(null);

  const lastNotificationSearchAbort = useRef<IRequestAbortController | null>(
    null
  );

  const navigate = useNavigate();
  const { pathname } = useLocation();
  const { addToast } = useToast();

  const paginationMetaRef = useRef(paginationMeta);

  const showMarkAllButton = useMemo(() => {
    return notifications.some((notification) => {
      return !notification.read;
    });
  }, [notifications]);

  useEffect(() => {
    paginationMetaRef.current = paginationMeta;
  }, [paginationMeta]);

  const getHubUrl = useCallback(async () => {
    await notificationService.getHubUrl().then(({ url, topics }) => {
      setHubUrl(url);
      setTopics(topics);
    });

    const unreadNotification = getStorage<boolean | null>(
      StorageKey.UNREAD_NOTIFICATION
    );
    if (unreadNotification === true) {
      setShowNotificationBadge(true);
    }
  }, [notificationService]);

  const groupNotifications = (
    notificationsData: INotificationDTO[]
  ): Array<IGroupedNotificationDTO | INotificationDTO> => {
    const notificationsList: Array<IGroupedNotificationDTO | INotificationDTO> =
      [];

    notificationsData.forEach((notification) => {
      switch (notification.type) {
        case NotificationType.CHAT_MESSAGE:
        case NotificationType.DOWNLOAD_PDF:
        case NotificationType.RENEWAL_CREATION: {
          notificationsList.push(notification);
          break;
        }

        case NotificationType.RENEWAL_DOCUMENT_ADD:
        case NotificationType.RENEWAL_ADDITIONAL_DOCUMENT_ADD:
        case NotificationType.RENEWAL_DOCUMENT_DELETE:
        case NotificationType.RENEWAL_ADDITIONAL_DOCUMENT_DELETE:
        case NotificationType.RENEWAL_DOCUMENT_APPROVE:
        case NotificationType.RENEWAL_ADDITIONAL_DOCUMENT_APPROVE:
        case NotificationType.RENEWAL_DOCUMENT_DECLINE:
        case NotificationType.RENEWAL_ADDITIONAL_DOCUMENT_DECLINE: {
          const notificationData = JSON.parse(
            notification.jsonSerializedTarget
          ) as IRenewalBaseSerializedDataProps;

          const renewalGroupId = `Renewal${notificationData.renewalId}${notificationData.clientName}`;

          const groupIndex = notificationsList.findIndex(
            (groupedNotification) =>
              groupedNotification.id === renewalGroupId &&
              'clientName' in groupedNotification &&
              groupedNotification.clientName === notificationData.clientName
          );

          if (groupIndex > -1) {
            const groupedNotification = notificationsList.at(groupIndex);

            if (
              groupedNotification === undefined ||
              !('notifications' in groupedNotification)
            ) {
              throw new Error('Unexpected notification type');
            }

            groupedNotification.notifications.push(notification);

            if (!notification.read) {
              groupedNotification.read = false;
            }
          } else {
            notificationsList.push({
              id: renewalGroupId,
              read: notification.read,
              title: `'${notificationData.renewalName}' was updated`,
              clientName: notificationData.clientName,
              notifications: [notification],
            });
          }
        }
      }
    });

    return notificationsList;
  };

  const listNotifications = useCallback(
    (page = 1, limit = 10) => {
      if (lastNotificationSearchAbort.current?.avoidConcurrency ?? false) {
        Logger.info('Skip new page request due to concurrency');
        return;
      }

      setLoadingListNotifications(true);

      const requestAbort = generateAbortRequest(true);
      lastNotificationSearchAbort.current = requestAbort;

      notificationService
        .getNotificationsByUser(page, limit, { signal: requestAbort.signal })
        .then(({ data, meta }) => {
          setNotifications(
            page !== 1
              ? produce((draft) => {
                  draft.push(...data);
                })
              : data
          );
          setPaginationMeta(meta);
        })
        .catch((error: IApiThrowsError) => {
          if (
            isAbortError(error) &&
            assertAbortReason(requestAbort, ABORT_REQUEST_REASON)
          ) {
            Logger.info(
              'Request was aborted, because there is a new search request'
            );
            return;
          }

          Logger.error('error: ', error);
        })
        .finally(() => {
          if (lastNotificationSearchAbort.current === requestAbort) {
            lastNotificationSearchAbort.current = null;
          }

          setLoadingListNotifications(false);
        });
    },
    [notificationService]
  );

  const handleOnMessageReceived = useCallback(
    async (e: MessageEvent) => {
      const parsedData = JSON.parse(e.data as string) as IChatTopicResponseDTO;
      let parsedPayload = JSON.parse(parsedData.payload) as INotificationDTO;

      switch (parsedPayload.type) {
        case NotificationType.CHAT_MESSAGE: {
          const message = JSON.parse(
            parsedPayload.jsonSerializedTarget
          ) as IMessageDTO;

          const messageClientId = message.clientChat.client.id;
          const currentPage = pathname.split('/').pop();
          const isMessagePage = currentPage === 'messages';
          const shouldMarkAsRead =
            repository !== 'client'
              ? messageClientId === clientIdSelected && isMessagePage
              : isMessagePage;

          if (shouldMarkAsRead) {
            try {
              const response =
                await notificationService.toggleNotificationReadStatus([
                  parsedPayload.id,
                ]);
              parsedPayload = response[0];
            } catch (error) {
              Logger.debug('error: ', error);
            }
          } else {
            setShowNotificationBadge(true);
            setStorage(StorageKey.UNREAD_NOTIFICATION, true);
          }
          break;
        }

        case NotificationType.RENEWAL_CREATION:
        case NotificationType.RENEWAL_DOCUMENT_ADD:
        case NotificationType.RENEWAL_ADDITIONAL_DOCUMENT_ADD:
        case NotificationType.RENEWAL_DOCUMENT_DELETE:
        case NotificationType.RENEWAL_ADDITIONAL_DOCUMENT_DELETE:
        case NotificationType.RENEWAL_DOCUMENT_APPROVE:
        case NotificationType.RENEWAL_ADDITIONAL_DOCUMENT_APPROVE:
        case NotificationType.RENEWAL_DOCUMENT_DECLINE:
        case NotificationType.RENEWAL_ADDITIONAL_DOCUMENT_DECLINE:
          setShowNotificationBadge(true);
          setStorage(StorageKey.UNREAD_NOTIFICATION, true);
          break;

        default:
          Logger.info('Received unknown notification', parsedPayload);
      }

      setNotifications(
        produce((draft) => {
          if (
            draft.length >= LIMIT_PAGE &&
            paginationMetaRef.current?.page !==
              paginationMetaRef.current?.total_pages
          ) {
            draft.pop();
          }

          draft.unshift(parsedPayload);
        })
      );

      setPaginationMeta(
        produce((draft) => {
          if (draft?.total !== undefined) {
            draft.total += 1;
          }
        })
      );
    },
    [pathname, repository, clientIdSelected, notificationService]
  );

  const listNextNotifications = useCallback(
    (currentPage = paginationMeta?.page ?? 1, limit?: number) => {
      const page =
        currentPage === paginationMeta?.total_pages
          ? currentPage
          : currentPage + 1;
      listNotifications(page, limit);
    },
    [listNotifications, paginationMeta]
  );

  const handleMarkAllAsRead = useCallback(() => {
    setLoadingMarkAllNotifications(true);

    const notificationIds = notifications
      .filter((notification) => !notification.read)
      .map((notification) => notification.id);

    notificationService
      .toggleNotificationReadStatus(notificationIds)
      .then((data) => {
        const result = notifications.map((notification) => {
          const item = data.find((dataItem) => dataItem.id === notification.id);
          return item ?? notification;
        });

        setNotifications(result);
      })
      .catch((error: IApiThrowsError) => {
        Logger.error('error: ', error);
      })
      .finally(() => {
        setLoadingMarkAllNotifications(false);
      });
  }, [notifications]);

  const redirectToScreen = useCallback(
    async <T,>(
      notification: INotificationDTO,
      clientId?: number,
      selectedByClientId?: (id: string) => Promise<T>
    ) => {
      if (repository === 'client') {
        const page = redirectNotification.find(
          (item) => item.type === notification.type
        );
        if (!page?.url) return;

        navigate(page?.url.path);
        setIsOpen(false);
      }

      let notificationClientId = clientId;

      if (notification.type === NotificationType.CHAT_MESSAGE) {
        const message = JSON.parse(
          notification.jsonSerializedTarget
        ) as IMessageDTO;

        notificationClientId = message.clientChat.client.id;
      }

      if (notificationClientId !== clientId && selectedByClientId) {
        await selectedByClientId(String(notificationClientId)).catch(() => {
          addToast({
            title: 'Error on selecting client',
            description: DefaultDescription,
            styleType: 'error',
            dataCy: 'select-client-error-toast',
          });
        });
      }

      if (notificationClientId !== undefined) {
        const page = redirectNotification.find(
          (item) => item.type === notification.type
        );

        if (!page?.url) return;

        switch (notification.type) {
          case NotificationType.CHAT_MESSAGE:
            {
              const MessagesRoute = getRouteFrom(page.url);
              navigate(
                generatePath(MessagesRoute, {
                  clientId: notificationClientId,
                })
              );
            }
            break;

          case NotificationType.RENEWAL_CREATION:
            {
              const notificationData = JSON.parse(
                notification.jsonSerializedTarget
              ) as IRenewalCreationSerializedDataProps;

              const RenewalsRoute = getRouteFrom(page.url);
              navigate(
                `${generatePath(RenewalsRoute, {
                  clientId: notificationClientId,
                })}?renewalId=${notificationData.renewalId}`
              );
            }
            break;

          case NotificationType.RENEWAL_DOCUMENT_ADD:
          case NotificationType.RENEWAL_ADDITIONAL_DOCUMENT_ADD:
          case NotificationType.RENEWAL_DOCUMENT_DELETE:
          case NotificationType.RENEWAL_ADDITIONAL_DOCUMENT_DELETE:
          case NotificationType.RENEWAL_DOCUMENT_APPROVE:
          case NotificationType.RENEWAL_ADDITIONAL_DOCUMENT_APPROVE:
          case NotificationType.RENEWAL_DOCUMENT_DECLINE:
          case NotificationType.RENEWAL_ADDITIONAL_DOCUMENT_DECLINE:
            {
              const notificationData = JSON.parse(
                notification.jsonSerializedTarget
              ) as IRenewalBaseSerializedDataProps;

              const RenewalsRoute = getRouteFrom(page.url);
              navigate(
                `${generatePath(RenewalsRoute, {
                  clientId: notificationClientId,
                })}?renewalId=${notificationData.renewalId}`
              );
            }
            break;
        }
      }
      setIsOpen(false);
    },
    [addToast, navigate, redirectNotification, repository]
  );

  const handleClickNotification = useCallback(
    async <T,>(
      notification: INotificationDTO,
      clientId?: number,
      selectedByClientId?: (id: string) => Promise<T>
    ) => {
      if (!notification.read) {
        const data = await notificationService.toggleNotificationReadStatus([
          notification.id,
        ]);
        const result = data[0];
        const index = notifications.findIndex(({ id }) => id === result.id);
        if (index !== -1) {
          setNotifications(
            produce((draft) => {
              draft.splice(index, 1, result);
            })
          );
        }
      }
      void redirectToScreen(notification, clientId, selectedByClientId);
    },
    [redirectToScreen, notificationService, notifications]
  );

  const handleToggleStatus = useCallback(
    (
      e: React.MouseEvent<HTMLButtonElement | HTMLDivElement>,
      id: number[] | number
    ) => {
      e.stopPropagation();

      if (!Array.isArray(id)) {
        setLoadingToggleNotificationId(id);
      }

      notificationService
        .toggleNotificationReadStatus(Array.isArray(id) ? id : [id])
        .then((data) => {
          const notificationsArray = [...notifications];

          data.forEach((notification) => {
            const index = notifications.findIndex(
              ({ id }) => id === notification.id
            );

            if (index !== -1) {
              notificationsArray.splice(index, 1, notification);
            }
          });

          setNotifications(notificationsArray);
        })
        .catch((error: IApiThrowsError) => {
          Logger.error('error: ', error);
        })
        .finally(() => {
          setLoadingToggleNotificationId(null);
        });
    },
    [notificationService, notifications]
  );

  const handleOpenChange = useCallback((value: boolean) => {
    setIsOpen(value);
    if (value) {
      setShowNotificationBadge(false);
      removeStorage(StorageKey.UNREAD_NOTIFICATION);
    }
  }, []);

  const recoverNotificationTitle = useCallback(
    (notificationType: NotificationType) => {
      switch (notificationType) {
        case NotificationType.RENEWAL_DOCUMENT_ADD:
          return 'uploaded a new file';

        case NotificationType.RENEWAL_ADDITIONAL_DOCUMENT_ADD:
          return 'uploaded a new additional file';

        case NotificationType.RENEWAL_DOCUMENT_DELETE:
          return 'deleted a file';

        case NotificationType.RENEWAL_ADDITIONAL_DOCUMENT_DELETE:
          return 'deleted an additional file';

        case NotificationType.RENEWAL_DOCUMENT_APPROVE:
          return 'approved a file';

        case NotificationType.RENEWAL_ADDITIONAL_DOCUMENT_APPROVE:
          return 'approved an additional file';

        case NotificationType.RENEWAL_DOCUMENT_DECLINE:
          return 'declined a file';

        case NotificationType.RENEWAL_ADDITIONAL_DOCUMENT_DECLINE:
          return 'declined an additional file';
        default:
          return '';
      }
    },
    []
  );

  return (
    <NotificationContext.Provider
      value={{
        notifications,
        notificationsFiltered,
        setNotificationsFiltered,
        showOnlyUnread,
        setShowOnlyUnread,
        showNotificationBadge,
        setShowNotificationBadge,
        loadingListNotifications,
        loadingToggleNotificationId,
        loadingMarkAllNotifications,
        hubUrl,
        setHubUrl,
        topics,
        setTopics,
        showMarkAllButton,
        isOpen,
        setIsOpen,
        paginationMeta,
        clientIdSelected,
        setClientIdSelected,
        getHubUrl,
        listNextNotifications,
        listNotifications,
        handleOnMessageReceived,
        handleMarkAllAsRead,
        handleClickNotification,
        handleToggleStatus,
        handleOpenChange,
        groupNotifications,
        recoverNotificationTitle,
      }}
    >
      {children}
    </NotificationContext.Provider>
  );
};
