import { MdNotificationsNone } from 'react-icons/md';
import styled from 'styled-components';

import { colors, radii } from '@gbs-monorepo-packages/styles';

export const NotificationButton = styled.button`
  margin-left: auto;
  margin-right: 1rem;
  background-color: transparent;
  border: none;
  border-radius: ${radii.full};
  color: ${colors.headerText};
  position: relative;

  width: 2rem;
  height: 2rem;

  &:hover {
    background-color: ${colors.backgroundHover};
  }
`;

export const NotificationIcon = styled(MdNotificationsNone)`
  height: 1.45rem;
  width: 1.45rem;
`;

interface INotificationBadgeProps {
  displayBadge: boolean;
}

export const NotificationBadge = styled.div<INotificationBadgeProps>`
  width: 8px;
  height: 8px;
  border-radius: ${radii.xs};
  background-color: ${colors.red200};
  position: absolute;
  display: ${({ displayBadge }) => (displayBadge ? 'block' : 'none')};
  right: 5px;
  top: 7px;
`;

export const Container = styled.div`
  min-width: 400px;
  max-width: 500px;
`;

export const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 1rem 0 3rem 0;
`;

export const Title = styled.h3`
  font-size: 1.5rem;
`;

export const LabelSwitch = styled.div`
  font-size: 0.875rem;
  margin-left: auto;
  margin-right: 1rem;
`;

export const ButtonMarkAll = styled.button`
  background-color: transparent;
  border: none;
  display: flex;
  margin-left: auto;
  margin-bottom: 0.75rem;

  &:hover {
    color: ${colors.black300};
  }
`;

export const TextContainer = styled.div`
  align-items: center;
  display: flex;
  flex-grow: 1;
  justify-content: center;
  margin-top: 1rem;
`;

export const Text = styled.p`
  color: ${colors.textLight};
  font-size: 1rem;
  font-weight: 400;
`;
