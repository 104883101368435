import debounce from 'lodash.debounce';
import {
  type ChangeEvent,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import {
  generatePath,
  useLocation,
  useNavigate,
  useParams,
} from 'react-router-dom';

import { useAuth } from '@gbs-monorepo-packages/auth';
import {
  BreadcrumbsComponent,
  DEBOUNCE_TIME,
  DefaultDescription,
  HighUserRoles,
  type IFolderDTO,
  INIT_PAGE,
  type ISort,
  LIMIT_PAGE,
  Logger,
  NO_LIMIT_PAGE,
  SearchBar,
  getRouteFrom,
  useBreadcrumbs,
  useToast,
} from '@gbs-monorepo-packages/common';

import {
  CREATE_TEMPLATE_MODAL_BUTTON_LINK_FOLDER_ITEMS,
  EDIT_COURSE_MODAL_BUTTON_LINK_FOLDER_ITEMS,
} from '../../../../../../../../constants/RoutePaths';
import { useDocument } from '../../../../../../../../hooks/useDocuments';
import { type IDocumentDTO } from '../../../../../../../../services/documentsFolder';
import { ListDocument } from '../../../../../../../ListDocuments';
import { Content } from './styles';

export const DocumentsButtonLink = (): JSX.Element => {
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const {
    folderId = '',
    clientId = '',
    courseId = '',
    templateId = '',
  } = useParams();
  const { user } = useAuth();
  const { addToast } = useToast();
  const [search, setSearch] = useState('');
  const lastSearch = useRef(search);
  const { breadcrumbs, addBreadcrumb, removeBreadcrumb } = useBreadcrumbs();
  const {
    documents,
    subFolders,
    setSortOrder,
    listDocuments,
    sortOrder,
    setDocuments,
    setSubFolders,
    loadingDocuments,
    paginationMeta,
    handleSelectDocumentLink,
  } = useDocument();

  const userIsHighRole = useMemo(() => {
    return HighUserRoles.some((role) => user?.roles.includes(role));
  }, [user]);

  const editCourseFolderItemRoutes = getRouteFrom(
    EDIT_COURSE_MODAL_BUTTON_LINK_FOLDER_ITEMS
  );
  const editTemplateFolderRoute = getRouteFrom(
    CREATE_TEMPLATE_MODAL_BUTTON_LINK_FOLDER_ITEMS
  );

  const DocumentHeaderColumns = useMemo(() => {
    const headersArray = [];

    headersArray.push({
      id: 'originalName',
      name: 'File Name',
      textAlign: 'start',
    });
    headersArray.push({
      id: 'name',
      name: 'Description',
      textAlign: 'start',
    });

    if (userIsHighRole) {
      headersArray.push({
        id: 'highlightedDate',
        name: 'Display on Dashboard',
        textAlign: 'center',
      });
    }

    headersArray.push({
      id: 'createdAt',
      name: 'Upload Date',
      textAlign: 'end',
    });
    headersArray.push({
      id: 'options',
      name: '',
      textAlign: 'center',
    });

    return headersArray;
  }, [userIsHighRole]);

  const getDocuments = useCallback(
    async (
      folderId: string,
      page: number,
      limit: number,
      filter?: string,
      sort?: ISort | null
    ) => {
      try {
        await listDocuments({
          folderId,
          page,
          limit,
          filter: filter ?? '',
          sort: JSON.stringify(sort),
        });
      } catch (err) {
        Logger.debug('err: ', err);
        addToast({
          title: 'Error on load documents',
          description: DefaultDescription,
          styleType: 'error',
          dataCy: 'get-documents-error-toast',
          duration: 3000,
        });
      }
    },
    [addToast, listDocuments]
  );

  useEffect(() => {
    void getDocuments(folderId, INIT_PAGE, NO_LIMIT_PAGE, '', sortOrder);
  }, [getDocuments, sortOrder, folderId]);

  useEffect(() => {
    let timer: NodeJS.Timeout | null = null;

    if (search.trim() !== lastSearch.current) {
      const execSearch = () => {
        lastSearch.current = search.trim();
        setDocuments([]);
        setSubFolders([]);
        searchDocument(true);
      };

      if (search.trim() === '') {
        execSearch();
      } else {
        timer = setTimeout(execSearch, DEBOUNCE_TIME);
      }
    }

    return () => {
      if (timer) {
        clearTimeout(timer);
      }
    };
  }, [search]);

  useEffect(() => {
    const debouncedGetFolders = debounce(() => {
      if (sortOrder) {
        void getDocuments(folderId, INIT_PAGE, LIMIT_PAGE, search, sortOrder);
      }
    }, DEBOUNCE_TIME);

    debouncedGetFolders();

    return () => {
      debouncedGetFolders.cancel();
    };
  }, [getDocuments, search, sortOrder]);

  const searchDocument = (searchByButton = false) => {
    if (!loadingDocuments || searchByButton) {
      const pageAux = searchByButton
        ? 0
        : Number((paginationMeta?.page ?? 0) > 0 ? paginationMeta?.page : 0);
      void getDocuments(folderId, pageAux + 1, LIMIT_PAGE, search, sortOrder);
    }
  };

  const removeBreadcrumbPage = useCallback(
    (index: number) => {
      removeBreadcrumb(index);
    },
    [removeBreadcrumb]
  );

  const toRouteSubFolderEditCourse = useCallback(
    (folderId: number | string) =>
      generatePath(editCourseFolderItemRoutes, {
        folderId: folderId.toString(),
        clientId,
        courseId,
      }),
    [clientId, courseId, editCourseFolderItemRoutes]
  );

  const toRouteSubFolderEditTemplate = useCallback(
    (folderId: number | string) =>
      generatePath(editTemplateFolderRoute, {
        folderId: folderId.toString(),
        clientId,
        templateId,
      }),
    [clientId, templateId, editTemplateFolderRoute]
  );

  const handleFolderClick = useCallback(
    (item: IFolderDTO) => {
      let route = '';
      if (courseId) {
        route = toRouteSubFolderEditCourse(item.id);
      } else {
        route = toRouteSubFolderEditTemplate(item.id);
      }
      addBreadcrumb({
        name: item.name,
        url: route,
      });
      navigate(route, { state: { from: pathname } });
    },
    [
      addBreadcrumb,
      courseId,
      navigate,
      pathname,
      toRouteSubFolderEditCourse,
      toRouteSubFolderEditTemplate,
    ]
  );

  const handleSelectDocument = useCallback(
    (item: IDocumentDTO) => {
      handleSelectDocumentLink(item);
    },
    [handleSelectDocumentLink]
  );

  return (
    <Content>
      <SearchBar
        onChange={(e: ChangeEvent<HTMLInputElement>) => {
          setSearch(e.target.value);
        }}
        onClearInput={() => {
          setSearch('');
        }}
        search={search}
        maxLength={40}
        placeholder="Search Document"
        loading={loadingDocuments}
        id="search-document"
      />
      <BreadcrumbsComponent
        links={breadcrumbs}
        onClick={removeBreadcrumbPage}
      />
      <ListDocument
        folders={subFolders}
        documents={documents}
        loading={loadingDocuments}
        paginationMeta={paginationMeta}
        search={searchDocument}
        setSortOrder={setSortOrder}
        sortOrder={sortOrder}
        headerColumns={DocumentHeaderColumns}
        disabledActions
        handleFolderClick={handleFolderClick}
        handleSelectDocument={handleSelectDocument}
      />
    </Content>
  );
};
