import {
  BaseDropdown,
  BaseDropdownItem,
  TableImageCell,
  TableRow,
} from '../../../components';
import { ETypeFolder } from '../../../constants';
import { useCanDocuments } from '../../../hooks';
import { type IFolderDTO } from '../../../services/folders';
import { Logger, dateFormatFromJSON } from '../../../utils';
import { DropdownButtonContainer, FolderIcon, TableCellCustom } from './styles';

interface IFolderCardProps {
  folder: IFolderDTO;
  screen: 'documents' | 'folders';
  userId: number;
  userIsHighRole: boolean;
  handleRowClick: (item: IFolderDTO) => void;
  handleSelectEdit?: (item: IFolderDTO) => void;
  handleSelectDelete?: (item: IFolderDTO) => void;
  handleSetIsDropdownOpen?: (value: boolean) => void;
  disabledActions: boolean;
}

export const FolderCard = ({
  folder,
  userIsHighRole,
  userId,
  screen,
  handleRowClick,
  handleSelectEdit,
  handleSelectDelete,
  handleSetIsDropdownOpen,
  disabledActions = false,
}: IFolderCardProps): JSX.Element => {
  const constTypeFolder =
    folder.typeFolder === 2 ? ETypeFolder.PRIVATE : ETypeFolder.PUBLIC;

  const { allowEditAndDelete } = useCanDocuments({
    userId,
    userIsHighRole,
    folderData: {
      id: folder.id,
      userCreateId: folder.accountId,
      typeFolder: constTypeFolder,
    },
  });

  return (
    <TableRow
      key={folder.id}
      onClick={() => {
        handleRowClick(folder);
      }}
    >
      <TableImageCell
        text={folder.name}
        subtitle=""
        dataCy="folder-card"
        type="icon"
      >
        <FolderIcon />
      </TableImageCell>
      {screen === 'documents' && (
        <>
          {userIsHighRole && <TableCellCustom text="" />}
          <TableCellCustom text="" />
        </>
      )}
      <TableCellCustom
        textAlign="end"
        text={dateFormatFromJSON(
          folder.updatedAt ?? folder.createdAt,
          'MM-dd-yyyy'
        )}
      />
      <Actions
        disabledActions={disabledActions}
        allowEditAndDelete={allowEditAndDelete}
        folder={folder}
        handleSelectEdit={handleSelectEdit}
        handleSelectDelete={handleSelectDelete}
        handleSetIsDropdownOpen={handleSetIsDropdownOpen}
      />
    </TableRow>
  );
};

interface IActionsProps {
  disabledActions: boolean;
  allowEditAndDelete: boolean;
  folder: IFolderDTO;
  handleSelectEdit?: (item: IFolderDTO) => void;
  handleSelectDelete?: (item: IFolderDTO) => void;
  handleSetIsDropdownOpen?: (value: boolean) => void;
}

export const Actions = ({
  disabledActions,
  allowEditAndDelete,
  folder,
  handleSelectEdit,
  handleSelectDelete,
  handleSetIsDropdownOpen,
}: IActionsProps): JSX.Element => {
  return !disabledActions && allowEditAndDelete ? (
    <DropdownButtonContainer
      data-cy="dropdown-container"
      onClick={(e) => {
        e.stopPropagation();
      }}
    >
      <BaseDropdown
        dataCy={'dropdown-menu-button'}
        onOpenChange={(isOpen) => {
          handleSetIsDropdownOpen?.(isOpen);
          Logger.debug('isOpen', isOpen);
        }}
      >
        <BaseDropdownItem
          data-cy={'edit-item'}
          onClick={(e) => {
            e.stopPropagation();
            handleSelectEdit?.(folder);
          }}
        >
          Edit
        </BaseDropdownItem>

        <BaseDropdownItem
          data-cy={'delete-item'}
          onClick={(e) => {
            e.stopPropagation();
            handleSelectDelete?.(folder);
          }}
        >
          Delete
        </BaseDropdownItem>
      </BaseDropdown>
    </DropdownButtonContainer>
  ) : (
    <div></div>
  );
};
