import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import { useAuth } from '@gbs-monorepo-packages/auth';
import {
  DefaultDescription,
  getRouteFrom,
  getSessionStorage,
  useToast,
} from '@gbs-monorepo-packages/common';

import { LOGIN } from '../constants/RoutePaths';
import { EdgeStorageKey } from '../constants/Storage';
import { type IClientsContextData } from '../contexts/company';
import { useClients } from './useCompanies';

interface IUseSelectedClientData
  extends Pick<IClientsContextData, 'selectedClient'> {
  loadingSelectedClient: boolean;
}

export const useSelectedClient = (): IUseSelectedClientData => {
  const { addToast } = useToast();
  const { user } = useAuth();
  const { selectedClient, selectClientByID } = useClients();
  const { clientId = '' } = useParams();
  const [loadingSelectedClient, setLoadingSelectedClient] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    const clientIdNum = parseInt(clientId);
    const selectedClientId = selectedClient?.id ?? 0;

    const clientToSelect =
      isNaN(clientIdNum) && !selectedClientId
        ? getSessionStorage<string>(EdgeStorageKey.CLIENT, '')
        : clientIdNum > 0 && selectedClientId !== clientIdNum
        ? clientId
        : null;

    const needChangeClient = clientToSelect !== null;
    if (needChangeClient) {
      const changeClient = async () => {
        setLoadingSelectedClient(true);
        try {
          await selectClientByID(clientToSelect || String(user?.clientId));
        } catch (error) {
          addToast({
            title: 'Error on selecting client',
            description: DefaultDescription,
            styleType: 'error',
            dataCy: 'error-toast',
          });
          navigate(getRouteFrom(LOGIN));
        } finally {
          setLoadingSelectedClient(false);
        }
      };
      void changeClient();
    }
  }, [
    user,
    addToast,
    clientId,
    navigate,
    selectClientByID,
    selectedClient?.id,
  ]);

  return {
    selectedClient,
    loadingSelectedClient,
  };
};
