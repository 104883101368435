import { useCallback, useEffect, useMemo, useState } from 'react';
import {
  generatePath,
  matchPath,
  useLocation,
  useNavigate,
  useParams,
} from 'react-router-dom';

import { Can, useAuth } from '@gbs-monorepo-packages/auth';
import {
  DefaultDescription,
  HighUserRoles,
  NotViewerUsers,
  Roles,
  SidebarItem,
  getRouteFrom,
  getSessionStorage,
  useToast,
} from '@gbs-monorepo-packages/common';

import { SelectCompanies } from '../../components/SelectCompanies';
import { ADMIN_URL, CLIENT_URL } from '../../constants/Env';
import {
  ANALYTICS,
  CLIENT,
  CLIENTS,
  COURSES,
  COURSES_TEMPLATE,
  FOLDERS,
  LOGIN,
  MEMBERS,
  REDIRECT,
  SETTINGS,
} from '../../constants/RoutePaths';
import { EdgeStorageKey } from '../../constants/Storage';
import { useClients } from '../../hooks/useCompanies';
import { type IClientDTO } from '../../services/companies';
import { DefaultLayout } from '../DefaultLayout';
import {
  AdminPortalIcon,
  AnalyticsIcon,
  BaseLoaderContainer,
  ClientName,
  ClientPortalIcon,
  ClientsIcon,
  Container,
  CoursesIcon,
  DocumentsIcon,
  HeaderChildrenContent,
  Loading,
  MembersIcon,
  TemplatesIcon,
} from './styles';

const CoursesRoute = getRouteFrom(COURSES);
const CompaniesRoute = getRouteFrom(CLIENTS);
const SettingsRoute = getRouteFrom(SETTINGS);
const TeammatesRoute = getRouteFrom(MEMBERS);
const TemplatesRoute = getRouteFrom(COURSES_TEMPLATE);
const AnalyticsRoute = getRouteFrom(ANALYTICS);
const FolderRoute = getRouteFrom(FOLDERS);

const titleCompanies = 'Clients';
const titleCourse = 'Courses';
const titleDocuments = 'Documents';
const titleTeammates = 'Users';
const titleTemplateCourse = 'Templates';
const titleAnalytics = 'Analytics';

const RolesCanGoToCompanies = HighUserRoles;
const RolesCanSelectCompanies = RolesCanGoToCompanies;
const AlmostRoles = NotViewerUsers;
const CanSeeCourses = [Roles.EMPLOYEE];
const RolesCanRedirClient = [Roles.CLIENT];

const ParamRegex = /:\w+/;
interface ILocation extends Location {
  state: { initialAccess: boolean; from: string } | null;
}

export const CompanyLayout = (): JSX.Element => {
  const { addToast } = useToast();
  const { user } = useAuth();
  const { pathname, state = null }: Partial<ILocation> = useLocation();
  const { selectedClient, selectClientByID, selectClient } = useClients();
  const { clientId = '' } = useParams();
  const [loadingClients, setLoadingClient] = useState(false);
  const navigate = useNavigate();
  const clientIdSelected = selectedClient?.id ?? 0;

  const handleSelectCompany = useCallback(
    (id: string, client: IClientDTO) => {
      const matchCompany = matchPath(
        {
          path: getRouteFrom(CLIENT),
          end: false,
        },
        pathname
      );

      selectClient(client);
      if (matchCompany) {
        const { pathname: companyPathname, pattern } = matchCompany;
        const newCompanyPathname = pattern.path.replace(ParamRegex, id);

        navigate(pathname.replace(companyPathname, newCompanyPathname));
      }
    },
    [navigate, pathname, selectClient]
  );

  const {
    CoursesPath,
    TeammatesPath,
    TemplatesPath,
    AnalyticsPath,
    PrivateDocumentPath,
    PublicDocumentPath,
  } = useMemo(() => {
    const clientId = selectedClient?.id ?? 0;
    if (clientId <= 0 || loadingClients) {
      return {
        clientId,
        CoursesPath: '.',
        SettingsPath: '.',
        TeammatesPath: '.',
        TemplatesPath: '.',
        AnalyticsPath: '.',
        PublicDocumentPath: '.',
        PrivateDocumentPath: '.',
      };
    }
    return {
      clientId,
      CoursesPath: generatePath(CoursesRoute, { clientId }),
      SettingsPath: generatePath(SettingsRoute, { clientId }),
      TeammatesPath: generatePath(TeammatesRoute, { clientId }),
      TemplatesPath: generatePath(TemplatesRoute, { clientId }),
      AnalyticsPath: generatePath(AnalyticsRoute, { clientId }),
      PrivateDocumentPath:
        clientId <= 0
          ? '.'
          : generatePath(FolderRoute, { clientId, typeFolder: 'private' }),
      PublicDocumentPath:
        clientId <= 0
          ? '.'
          : generatePath(FolderRoute, { clientId, typeFolder: 'public' }),
    };
  }, [loadingClients, selectedClient?.id]);

  useEffect(() => {
    const clientIdNum = parseInt(clientId);
    const selectedClientId = selectedClient?.id ?? 0;

    let clientToSelect =
      isNaN(clientIdNum) && !selectedClientId
        ? getSessionStorage<string>(EdgeStorageKey.CLIENT, '')
        : clientIdNum > 0 && selectedClientId !== clientIdNum
        ? clientId
        : null;

    if (
      state?.initialAccess === true &&
      !getSessionStorage<string>(EdgeStorageKey.CLIENT, '') &&
      user &&
      !state?.from?.includes('companies')
    ) {
      clientToSelect = user?.clientId != null ? String(user?.clientId) : null;
    }

    const needChangeClient = clientToSelect !== null;
    if (needChangeClient) {
      const changeClient = async () => {
        setLoadingClient(true);
        try {
          await selectClientByID(clientToSelect || String(user?.clientId));
        } catch (error) {
          addToast({
            title: 'Error on selecting client',
            description: DefaultDescription,
            styleType: 'error',
            dataCy: 'error-toast',
          });
          navigate(getRouteFrom(LOGIN));
        } finally {
          setLoadingClient(false);
        }
      };
      void changeClient();
    }
  }, [
    state,
    user,
    addToast,
    clientId,
    navigate,
    selectClient,
    selectClientByID,
    selectedClient?.id,
  ]);

  return clientIdSelected > 0 ? (
    <DefaultLayout
      headerChildren={
        <HeaderChildrenContent>
          <Can
            roles={RolesCanSelectCompanies}
            alternativeChildren={
              <ClientName>{selectedClient?.name ?? 'Not found'}</ClientName>
            }
          >
            <SelectCompanies onValueChange={handleSelectCompany} />
          </Can>
        </HeaderChildrenContent>
      }
      sidebarChildren={(isShrunken) => (
        <>
          <Can roles={CanSeeCourses}>
            <SidebarItem
              dataCy={`navLink-courses${clientId}`}
              icon={<CoursesIcon />}
              isShrunken={isShrunken}
              title={titleCourse}
              type="link"
              to={clientIdSelected > 0 ? CoursesPath : REDIRECT.path}
            />
          </Can>
          <Can roles={AlmostRoles}>
            <SidebarItem
              dataCy={`navLink-courses${clientId}`}
              icon={<CoursesIcon />}
              isShrunken={isShrunken}
              title={titleCourse}
              type="link"
              to={clientIdSelected > 0 ? CoursesPath : REDIRECT.path}
            />
            <SidebarItem
              accordionValue="documents"
              dataCy={`navLink-documents${clientId}`}
              icon={<DocumentsIcon />}
              isShrunken={isShrunken}
              title={titleDocuments}
              to={PublicDocumentPath}
              type="accordionLink"
            >
              <SidebarItem
                dataCy={`navLink-privateDocuments${clientId}`}
                isShrunken={isShrunken}
                title={'Private GBS Documents'}
                to={PrivateDocumentPath}
                type="link"
              />
              <SidebarItem
                dataCy={`navLink-publicDocuments${clientId}`}
                isShrunken={isShrunken}
                title={'Public Employee Documents'}
                to={PublicDocumentPath}
                type="link"
              />
            </SidebarItem>
            <SidebarItem
              dataCy={`navLink-users${clientId}`}
              icon={<MembersIcon />}
              isShrunken={isShrunken}
              title={titleTeammates}
              type="link"
              to={clientIdSelected > 0 ? TeammatesPath : REDIRECT.path}
            />
            <Can roles={HighUserRoles}>
              <SidebarItem
                dataCy={`navLink-templates${clientId}`}
                icon={<TemplatesIcon />}
                isShrunken={isShrunken}
                title={titleTemplateCourse}
                type="link"
                to={clientIdSelected > 0 ? TemplatesPath : REDIRECT.path}
              />
            </Can>
            <SidebarItem
              dataCy={`navLink-analytics${clientId}`}
              icon={<AnalyticsIcon />}
              isShrunken={isShrunken}
              title={titleAnalytics}
              type="link"
              to={clientIdSelected > 0 ? AnalyticsPath : REDIRECT.path}
            />
          </Can>
        </>
      )}
      sidebarFooterChildren={(isShrunken) => (
        <>
          <Can roles={RolesCanGoToCompanies}>
            <SidebarItem
              dataCy={`${CompaniesRoute}`}
              icon={<ClientsIcon />}
              isShrunken={isShrunken}
              title={titleCompanies}
              type="link"
              to={clientIdSelected > 0 ? CompaniesRoute : REDIRECT.path}
              end
            />
            <SidebarItem
              dataCy={`navLink-redirectAdmin`}
              icon={<AdminPortalIcon />}
              isShrunken={isShrunken}
              title={'Admin Portal'}
              type="link"
              to={
                ADMIN_URL +
                '/redirect-login' +
                (selectedClient !== null
                  ? '?selectedClientId=' + selectedClient.id.toString()
                  : '')
              }
            />
          </Can>
          <Can roles={RolesCanRedirClient}>
            <SidebarItem
              dataCy={`navLink-redirectClient`}
              icon={<ClientPortalIcon />}
              isShrunken={isShrunken}
              title={'Client Portal'}
              type="link"
              to={CLIENT_URL + '/redirect-login'}
            />
          </Can>
        </>
      )}
    />
  ) : (
    <Container data-cy="loading-company-layout-container">
      <BaseLoaderContainer>
        <Loading dataCy="loading-company-layout" />
      </BaseLoaderContainer>
    </Container>
  );
};
