import { enableMapSet } from 'immer';
import { StrictMode, useEffect } from 'react';
import { BrowserRouter } from 'react-router-dom';

import { AuthProvider } from '@gbs-monorepo-packages/auth';
import {
  BreadcrumbsProvider,
  SidebarProvider,
  ToastProvider,
} from '@gbs-monorepo-packages/common';
import { GlobalStyle } from '@gbs-monorepo-packages/styles';

import { FRONTEND_URLS } from './constants/Auth';
import { ROUTER_BASENAME } from './constants/Env';
import { HOME, LOGIN, REDIRECT, RESET_PASSWORD } from './constants/RoutePaths';
import { ClientsProvider } from './contexts/company';
import { DragDropProvider } from './contexts/dragDrop';
import { Router } from './Router';
import { RouterSubdomain } from './RouterSubdomain';
import { api } from './services/api';
import Logger from './utils/logger';

export const App = (): JSX.Element => {
  Logger.debug(window.location.hostname);
  useEffect(() => {
    enableMapSet();
  }, []);

  return (
    <BrowserRouter basename={ROUTER_BASENAME}>
      <GlobalStyle />
      <DragDropProvider>
        {/* DragDropProvider is out of StrictMode because, he isn't 100% compatible with react 18 */}
        {/* Issues Ref: https://github.com/atlassian/react-beautiful-dnd/issues/2350 */}
        <StrictMode>
          <ToastProvider>
            <BreadcrumbsProvider>
              <AuthProvider
                axiosInstance={api}
                mainContentRoute={REDIRECT}
                authorizationRoute={LOGIN}
                resetPasswordRoute={RESET_PASSWORD}
                homeRoute={HOME}
                frontendUrls={FRONTEND_URLS}
              >
                <ClientsProvider>
                  <SidebarProvider>
                    {window.location.hostname === 'courses.gbsbenefits.com' ||
                    window.location.hostname ===
                      'dev.courses.gbsbenefits.com' ||
                    window.location.hostname ===
                      'stg.courses.gbsbenefits.com' ? (
                      <RouterSubdomain />
                    ) : (
                      <Router />
                    )}
                  </SidebarProvider>
                </ClientsProvider>
              </AuthProvider>
            </BreadcrumbsProvider>
          </ToastProvider>
        </StrictMode>
      </DragDropProvider>
    </BrowserRouter>
  );
};
