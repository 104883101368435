import {
  type IResponseAccountWithCodeDTO,
  type IUserDTO,
} from '@gbs-monorepo-packages/auth';
import { type IResponseData } from '@gbs-monorepo-packages/common';

import Logger from '../utils/logger';
import {
  type IPaginationMetaProps,
  type IPaginationResponseData,
  type IResponseDefaultContent,
  type IThrowsError,
  api,
} from './api';
import { type IClientDTO } from './companies';

export type IInviteUserDTO = Pick<
  IUserDTO,
  'currentRole' | 'email' | 'firstName' | 'lastName' | 'phone' | 'roles'
> & {
  clientId: number;
  profile?: File;
};

export interface IUpdateUserDto extends Omit<IUserDTO, 'status'> {
  password: string;
}
export interface IPaginationUserDTO {
  data: IUserDTO[];
  meta: IPaginationMetaProps;
}
export interface IUserMembershipDto extends IUserDTO {
  client?: IClientDTO;
}
export interface IPaginationUserMembershipDTO {
  data: IUserMembershipDto[];
  meta: IPaginationMetaProps;
}
export interface IProfileUpdateDTO {
  email: string;
  firstName: string;
  lastName: string;
  profile?: File;
}
export interface IChangePasswordDTO {
  email: string;
  password: string;
  currentPassword: string;
}

export type IUpdateMemberDto = Pick<
  Partial<IUserDTO>,
  'firstName' | 'lastName' | 'phone' | 'roles'
> &
  Pick<IUserDTO, 'email'> & {
    profile?: File;
  };

export const update = async ({
  id,
  firstName,
  lastName,
  password,
  roles = [],
}: IUpdateUserDto): Promise<void> => {
  const response = await api
    .patch<IResponseDefaultContent>('change-account', {
      id,
      firstName,
      lastName,
      password,
      roles,
    })
    .then((resp) => resp.data)
    .catch((err: IThrowsError) => {
      throw err;
    });

  Logger.debug('response: ', response);
};

export const updateMember = async ({
  firstName,
  lastName,
  email,
  roles,
  phone,
  profile,
}: IUpdateMemberDto): Promise<void> => {
  const formData = new FormData();
  email && formData.append('email', email);
  firstName && formData.append('firstName', firstName);
  lastName && formData.append('lastName', lastName);
  roles?.forEach((role) => {
    formData.append('roles[]', role);
  });
  phone && formData.append('phone', phone);
  profile && formData.append('profile', profile, profile.name);

  const response = await api
    .post<IResponseDefaultContent>(
      `change-account/${email}?_METHOD=PATCH`,
      formData
    )
    .then((resp) => resp.data)
    .catch((err: IThrowsError) => {
      throw err;
    });

  Logger.debug('response: ', response);
};

export const deleteMemberProfile = async (id: number): Promise<void> => {
  await api
    .delete<IResponseData<null>>(`api/users/profile-image/${id}`)
    .then((resp) => resp.data.data)
    .catch((err) => {
      throw err;
    });
};

export const deleteMember = async (id: number): Promise<void> => {
  const response = await api
    .delete<IResponseDefaultContent>(`delete-account/${id}`)
    .then((resp) => resp.data)
    .catch((err: IThrowsError) => {
      throw err;
    });

  Logger.debug('response: ', response);
};

export const inviteUser = async ({
  email,
  firstName,
  lastName,
  roles,
  clientId,
  phone,
  profile,
}: IInviteUserDTO): Promise<IResponseAccountWithCodeDTO> => {
  const formData = new FormData();
  email && formData.append('email', email);
  firstName && formData.append('firstName', firstName);
  lastName && formData.append('lastName', lastName);
  roles?.forEach((role) => {
    formData.append('roles[]', role);
  });
  clientId && formData.append('clientId', clientId.toString());
  phone && formData.append('phone', phone);
  if (profile) {
    formData.append('profile', profile, profile?.name);
  }

  const response = await api
    .post<IResponseAccountWithCodeDTO>(`invite-user/`, formData)
    .then((resp) => resp.data)
    .catch((err: IThrowsError) => {
      throw err;
    });

  return response;
};

export const getUsersByClient = async (
  clientId: string,
  page: number,
  limit: number,
  filter: string
): Promise<IPaginationUserDTO> => {
  const result = await api
    .get<IPaginationResponseData<IUserDTO[]>>(
      `api/users/company/${clientId}?page=${page}&limit=${limit}&filter=${encodeURIComponent(
        filter
      )}`
    )
    .then((resp) => resp.data)
    .catch((err) => {
      throw err;
    });

  return result;
};

export const getUsersByMembership = async (
  page: number,
  limit: number,
  filter: string
): Promise<IPaginationUserMembershipDTO> => {
  const result = await api
    .get<IPaginationResponseData<IUserMembershipDto[]>>(
      `api/users/?page=${page}&limit=${limit}&filter=${encodeURIComponent(
        filter
      )}`
    )
    .then((resp) => resp.data)
    .catch((err) => {
      throw err;
    });

  return result;
};

export const profileUpdate = async ({
  email,
  firstName,
  lastName,
  profile,
}: IProfileUpdateDTO): Promise<IUserDTO> => {
  const formData = new FormData();
  email && formData.append('email', email);

  firstName && formData.append('firstName', firstName);
  lastName && formData.append('lastName', lastName);
  profile && formData.append('profile', profile, profile.name);

  const response = await api
    .post<IResponseData<IUserDTO>>(`change-account/${email}`, formData)
    .then((resp) => resp.data.data)
    .catch((err: IThrowsError) => {
      throw err;
    });

  Logger.debug('response: ', response);
  return response;
};

export const changePassword = async ({
  email,
  password,
  currentPassword,
}: IChangePasswordDTO): Promise<void> => {
  const formData = new FormData();
  password && formData.append('password', password);
  currentPassword && formData.append('currentPassword', currentPassword);

  const response = await api
    .post<IResponseDefaultContent>(`change-account/${email}`, formData)
    .then((resp) => resp.data)
    .catch((err: IThrowsError) => {
      throw err;
    });

  Logger.debug('response: ', response);
};

export const getUserById = async (id: number): Promise<IUserDTO> => {
  const result = await api
    .get<{ data: IUserDTO }>(`api/users/${id}`)
    .then((resp) => resp.data.data)
    .catch((err) => {
      throw err;
    });

  return result;
};
