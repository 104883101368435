import { BaseRadioGroupIndicator } from '@gbs-monorepo-packages/common';
import { type RadioGroupProps } from '@radix-ui/react-radio-group';

import { Flex, Label, RadioGroupItem, RadioGroupRoot } from './styles';

export interface IBaseRadioButtonProps extends RadioGroupProps {
  id?: string;
  dataCy?: string;
  onValue: (value: string) => void;
}

export const RadioButton = ({
  dataCy = 'radio-group-class-type',
  onValue,
  ...props
}: IBaseRadioButtonProps): JSX.Element => {
  return (
    <RadioGroupRoot
      defaultValue="_blank"
      aria-label="Redirect"
      data-cy={dataCy}
      {...props}
      onValueChange={(value) => {
        onValue(value);
      }}
    >
      <Flex>
        <RadioGroupItem
          value="_blank"
          id="option_blank"
          data-cy={`${dataCy}-blank`}
        >
          <BaseRadioGroupIndicator id="option_blank-input" />
        </RadioGroupItem>
        <Label htmlFor="option_blank">New Window</Label>
      </Flex>
      <Flex>
        <RadioGroupItem
          value="_self"
          id="option_self"
          data-cy={`${dataCy}-blank`}
        >
          <BaseRadioGroupIndicator id="option_self-input" />
        </RadioGroupItem>
        <Label htmlFor="option_self">This Window</Label>
      </Flex>
    </RadioGroupRoot>
  );
};
