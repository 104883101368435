import { IoPersonCircleSharp } from 'react-icons/io5';
import styled from 'styled-components';

import { colors } from '@gbs-monorepo-packages/styles';

import { LazyImage } from '../LazyImage';

export const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 1rem 0.5rem;
  cursor: pointer;

  &:hover {
    background-color: ${colors.backgroundHover};
  }
`;

export const ContainerImage = styled.div`
  width: auto;
`;

interface ILazyImageProps {
  $type?: 'icon' | 'image';
}

export const LazyImageCustom = styled(LazyImage)<ILazyImageProps>`
  border-radius: ${({ $type }) => ($type === 'icon' ? '50%' : 'none')};
  height: 40px;
  width: 40px;
  object-fit: cover;
`;

export const PersonCircleIcon = styled(IoPersonCircleSharp)`
  color: ${colors.text};
  width: 2.75rem;
  height: 2.75rem;
`;

export const NotificationContent = styled.div`
  margin: 0 auto 0 0.5rem;
`;

export const Title = styled.div`
  font-weight: 500;
  margin-bottom: 0.5rem;
  word-break: break-word;
`;

export const Description = styled.div`
  display: flex;
  align-items: center;
  gap: 0.875rem;
  font-size: 0.875rem;
`;

export const Time = styled.div`
  font-size: 0.75rem;
  color: ${colors.gray300};
`;

export const IndicatorButton = styled.button`
  min-width: 22px;
  width: 22px;
  height: 22px;
  background-color: transparent;
  border: none;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;

  &:hover {
    border: 1px solid ${colors.gray250};
  }
`;

interface IIndicatorIconProps {
  $read: boolean;
}

export const IndicatorIcon = styled.div<IIndicatorIconProps>`
  width: 8px;
  height: 8px;
  border-radius: 50%;
  background-color: ${({ $read }) =>
    $read ? 'transparent' : `${colors.blue300}`};
`;
