import { type AxiosRequestConfig } from 'axios';

import {
  IDefaultClientDTO,
  type IThrowsError,
} from '@gbs-monorepo-packages/common';

import {
  type IPaginationMetaProps,
  type IPaginationResponseData,
  type IResponseData,
  api,
} from './api';
import { type IClientFileDTO, type IDocumentDTO } from './documents';

export interface IClientDTO extends IDefaultClientDTO {
  clientFile?: IClientFileDTO;
  employeeCount: number;
  logoImage: IDocumentDTO | null;
  logoUrl: string;
  name: string;
}

export interface IPaginationCompaniesDTO {
  data: IClientDTO[];
  meta: IPaginationMetaProps;
}
export interface IGetCompaniesProps {
  page: number;
  limit: number;
  filter: string;
  all?: boolean;
}

export interface IGetAgenciesProps {
  clientId: string;
  page: number;
  limit: number;
  filter: string;
}

export interface IPaginationAgenciesDTO {
  data: IClientDTO[];
  meta: IPaginationMetaProps;
}

export const getCompanies = async (
  { page, limit, filter, all = false }: IGetCompaniesProps,
  config?: AxiosRequestConfig
): Promise<IPaginationCompaniesDTO> => {
  const result = await api
    .get<IPaginationResponseData<IClientDTO[]>>(
      `api/companies/?page=${all ? 1 : page}&limit=${
        all ? 9999 : limit
      }&filter=${all ? '' : encodeURIComponent(filter)}`,
      config
    )
    .then((resp) => resp.data)
    .catch((err) => {
      throw err;
    });

  return result;
};

export type IGetClientByIdDTO = Pick<IClientDTO, 'id'>;

export const getClientById = async ({
  id,
}: IGetClientByIdDTO): Promise<IClientDTO> => {
  const result = await api
    .get<IResponseData<IClientDTO>>(`api/companies/${String(id)}`)
    .then((resp) => resp.data.data)
    .catch((err) => {
      throw err;
    });

  return result;
};

export const getAgencies = async (
  { clientId, page, limit, filter }: IGetAgenciesProps,
  config?: AxiosRequestConfig
): Promise<IPaginationAgenciesDTO> => {
  const result = await api
    .get<IPaginationResponseData<IClientDTO[]>>(
      `api/companies/agencies/${clientId}?page=${page}&limit=${limit}&filter=${encodeURIComponent(
        filter
      )}`,
      config
    )
    .then((resp) => resp.data)
    .catch((err: IThrowsError) => {
      throw err;
    });

  return result;
};
