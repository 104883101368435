import { type CSSProperties } from 'react';
import styled from 'styled-components';

import { SelectFilter } from '@gbs-monorepo-packages/common';

import { type IClientDTO } from '../../../../services/companies';

export const SelectContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  height: 100%;
  width: 100%;

  & > div > button {
    border: none;
    height: 3.25rem;
  }
`;

const selectFilterContentCustom: CSSProperties = {
  maxHeight: '40vh',
  position: 'absolute',
  left: '12px',
};

export const SelectFilterData = styled(SelectFilter<IClientDTO>).attrs(
  ({ customContentStyle = selectFilterContentCustom }) => ({
    customContentStyle,
  })
)``;
