import { useMemo } from 'react';

import { useAuth } from '@gbs-monorepo-packages/auth';
import {
  FolderCard,
  HighUserRoles,
  type IColumn,
  type IFolderDTO,
  type IPaginationMetaProps,
  type ISort,
  ListLoading,
} from '@gbs-monorepo-packages/common';

import { TableCustom } from './styles';

interface IListFolderProps {
  loading: boolean;
  folders: IFolderDTO[];
  paginationMeta: IPaginationMetaProps | null;
  searchFolder: () => void;
  setSortOrder: (value: ISort | null) => void;
  sortOrder: ISort | null;
  headerColumns: Array<{
    id: string;
    name: string;
    textAlign: string;
  }>;
  disabledActions: boolean;

  handleFolderClick: (item: IFolderDTO) => void;
  handleSelectEdit?: (item: IFolderDTO) => void;
  handleSelectDelete?: (item: IFolderDTO) => void;
  handleSetIsDropdownOpen: (value: boolean) => void;
}

export const ListFolder = ({
  loading,
  folders,
  paginationMeta,
  searchFolder,
  setSortOrder,
  sortOrder,
  headerColumns,
  disabledActions = false,
  handleFolderClick,
  handleSelectEdit,
  handleSelectDelete,
  handleSetIsDropdownOpen,
}: IListFolderProps): JSX.Element => {
  const { user } = useAuth();
  const userIsHighRole = useMemo(() => {
    return HighUserRoles.some((role) => user?.roles.includes(role));
  }, [user]);

  return loading ? (
    <ListLoading />
  ) : (
    <TableCustom
      loading={loading}
      columns={headerColumns}
      dataLength={folders.length}
      next={searchFolder}
      hasMore={folders.length < (paginationMeta?.total ?? 0)}
      noItensMessage="No folders found."
      onClickHeader={(column: IColumn) => {
        setSortOrder({
          name: column.id,
          direction: sortOrder?.direction === 'ASC' ? 'DESC' : 'ASC',
        });
      }}
      columnSorted={sortOrder}
    >
      {folders.map((folder) => (
        <FolderCard
          key={folder.id}
          folder={folder}
          handleRowClick={() => {
            handleFolderClick(folder);
          }}
          handleSelectDelete={handleSelectDelete}
          handleSelectEdit={handleSelectEdit}
          handleSetIsDropdownOpen={handleSetIsDropdownOpen}
          userIsHighRole={userIsHighRole}
          screen="folders"
          userId={user?.id ?? 0}
          disabledActions={disabledActions}
        />
      ))}
    </TableCustom>
  );
};
