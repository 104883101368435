import { type Property } from 'csstype';
import { type ReactNode } from 'react';

import { type ITextStatusProps } from '../TextStatus';
import { TableButtonActionsCellContainer } from './styles';

export interface ITableButtonActionsCellProps
  extends Pick<ITextStatusProps, 'dataCy' | 'type'> {
  alignItems?: Property.AlignItems;
  children: ReactNode;
}

export const TableButtonActionsCell = ({
  dataCy = 'table-button-action-cell',
  alignItems,
  children,
}: ITableButtonActionsCellProps): JSX.Element => (
  <TableButtonActionsCellContainer data-cy={dataCy} $alignItems={alignItems}>
    {children}
  </TableButtonActionsCellContainer>
);
