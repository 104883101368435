import { useMemo } from 'react';

import { folderTypeCategories } from '../constants';

interface IFolderBase {
  typeFolder: 1 | 2 | 3 | 4 | 5;
  userCreateId?: number;
  id?: number;
}

interface IAllowedFolders {
  allowCreate: boolean;
  allowEditAndDelete: boolean;
  allowMove: boolean;
}

interface IAllowedFoldersProps<T extends IFolderBase> {
  folderData?: T;
  userId?: number;
  userIsHighRole: boolean;
  defaultFolderId?: number;
}
export const useCanDocuments = <T extends IFolderBase>({
  userId,
  userIsHighRole,
  folderData,
  defaultFolderId,
}: IAllowedFoldersProps<T>): IAllowedFolders => {
  const allowCreate = useMemo(() => {
    if (!folderData) {
      return false;
    }

    if (userIsHighRole) {
      return true;
    }

    if (
      folderTypeCategories[folderData?.typeFolder] === 'public' &&
      !userIsHighRole
    ) {
      return false;
    }

    if (folderData.userCreateId === userId) {
      return true;
    }

    if (
      folderData.userCreateId !== undefined &&
      folderData.userCreateId !== null
    ) {
      if (
        folderTypeCategories[folderData?.typeFolder] === 'private' &&
        !userIsHighRole &&
        folderData.userCreateId === userId
      ) {
        return true;
      }
    } else {
      return true;
    }

    return false;
  }, [userIsHighRole, folderData, userId]);

  const allowEditAndDelete = useMemo(() => {
    if (!folderData) {
      return false;
    }

    if (folderData.id === defaultFolderId) {
      return false;
    }

    if (userIsHighRole) {
      return true;
    }

    return folderData.userCreateId === userId;
  }, [folderData, defaultFolderId, userIsHighRole, userId]);

  const allowMove = useMemo(() => {
    if (!folderData) {
      return false;
    }

    if (folderData.id === defaultFolderId) {
      return false;
    }

    if (userIsHighRole) {
      return true;
    }

    return folderData.userCreateId === userId;
  }, [folderData, defaultFolderId, userIsHighRole, userId]);

  return {
    allowCreate,
    allowEditAndDelete,
    allowMove,
  };
};
