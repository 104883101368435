import { type HTMLAttributes, type ReactNode } from 'react';

import {
  ContainerWithError,
  ErrorMessage,
  Fieldset,
  Label,
  LabelSelectData,
} from './styles';

export interface IFormInputContentProps
  extends Pick<HTMLAttributes<HTMLFieldSetElement>, 'className' | 'style'> {
  label: string;
  inputRef?: string;
  filled: boolean;
  errorMessage?: string;
  children: ReactNode;
  dataCy?: string;
  value?: string;
  isSelectData?: boolean;
}
export const FormInputContent = ({
  label,
  inputRef,
  filled,
  errorMessage,
  children,
  value,
  dataCy = 'input',
  isSelectData = false,
  className,
  style,
}: IFormInputContentProps): JSX.Element => {
  return (
    <ContainerWithError data-cy={`container-withError-${dataCy}`}>
      <Fieldset
        data-cy={label.toLowerCase()}
        filled={filled}
        isInvalid={!!errorMessage}
        isSelectData={isSelectData}
        className={className}
        style={style}
      >
        {isSelectData ? (
          value !== undefined && label ? (
            <LabelSelectData data-cy={dataCy}>{label}</LabelSelectData>
          ) : null
        ) : (
          label && (
            <Label htmlFor={inputRef} data-cy={dataCy}>
              {label}
            </Label>
          )
        )}
        {children}
      </Fieldset>
      <ErrorMessage data-cy={`error-message-${dataCy ?? ''}`}>
        {errorMessage}
      </ErrorMessage>
    </ContainerWithError>
  );
};
