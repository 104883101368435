import { type FormEvent, useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';

import { type IApiThrowsError, useToast } from '@gbs-monorepo-packages/common';

import { FormLayout } from '../../components/FormLayout';
import { strongPasswordValidRegex } from '../../constants/Auth';
import { useAuth } from '../../hooks/useAuth';
import {
  Description,
  ErrorMessage,
  Fieldset,
  Form,
  Input,
  Label,
  LockButton,
  LockedIcon,
  SubmitButton,
  TextContent,
  Title,
  UnLockedIcon,
} from './styles';

interface IError {
  password?: string;
  other?: string;
}

export const ResetPassword = (): JSX.Element => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState<IError>({});
  const [show, setShow] = useState(false);
  const [loading, setLoading] = useState(false);
  const location = useLocation();
  const urlParams = new URLSearchParams(location.search);

  const { addToast } = useToast();

  const { resetPassword, signIn } = useAuth();

  const handlerShowPassword = () => {
    setShow((show) => !show);
  };

  const handlerSubmit = (e: FormEvent) => {
    e.preventDefault();

    if (password && !strongPasswordValidRegex.test(password)) {
      setError({
        password:
          'Password must contain at least 8 characters, 1 lowercase letter, 1 uppercase letter, 1 number, 1 special character and no whitespace.',
      });
      return;
    }

    if (loading) return;
    setLoading(true);
    setError({});

    resetPassword({
      email,
      password,
    })
      .then(() => {
        addToast({
          title: 'Password updated successfully',
          styleType: 'success',
          dataCy: 'reset-password-success-toast',
        });
        void signIn({ email, password }).finally(() => {
          setLoading(false);
        });
      })
      .catch((error: IApiThrowsError) => {
        if (!error.isAxiosError) {
          setError({
            other: 'Unexpected error, please try again later',
          });
          return;
        }
        const { response } = error;
        if (!response) {
          setError({
            other: 'Unexpected error, please try again later',
          });
          return;
        }

        const { status, data } = response;
        if (status >= 500) {
          setError({
            other: 'Server error, please try again later',
          });
        }

        if (data.error.message) {
          setError({
            other:
              data.error.message ??
              'Wrong email or password, please check your answer',
          });
        }
      })
      .finally(() => {
        setLoading(false);
      });
  };

  useEffect(() => {
    const email = urlParams.get('email') ?? '';
    setEmail(email);
  }, []);

  return (
    <FormLayout dataCy="reset-password-content-modal">
      <TextContent data-cy="text-container">
        <Title data-cy="title-resetPassword">Reset Password</Title>
        <Description data-cy="description-resetPassword">
          Enter your email and a new password to reset your password account.
        </Description>
      </TextContent>
      <Form onSubmit={handlerSubmit} data-cy="reset-password-form">
        <Fieldset filled={!!email}>
          <Input
            data-cy="input-email"
            defaultValue={email}
            id="email"
            required
            type="email"
            maxLength={40}
            disabled
            autoComplete="email"
          />
          <Label data-cy="label-email" htmlFor="email">
            Email
          </Label>
        </Fieldset>
        <Fieldset filled={!!password} isInvalid={!!error.password}>
          <Input
            data-cy="input-password"
            defaultValue={password}
            id="password"
            onChange={(e) => {
              setPassword(e.target.value);
            }}
            required
            type={show ? 'text' : 'password'}
            maxLength={20}
          />
          <Label data-cy="label-password" htmlFor="password">
            Password
          </Label>
          <LockButton
            data-cy="buttonEye"
            onClick={handlerShowPassword}
            type="button"
          >
            {show ? (
              <LockedIcon data-cy="icon-locked" />
            ) : (
              <UnLockedIcon data-cy="icon-unlocked" />
            )}
          </LockButton>
        </Fieldset>
        <ErrorMessage data-cy={error.password ?? error.other}>
          {error.password ?? error.other}
        </ErrorMessage>
        <SubmitButton
          type="submit"
          data-cy="button-resetPassword"
          loading={loading}
        >
          Reset Password
        </SubmitButton>
      </Form>
    </FormLayout>
  );
};
