import { type Editor } from 'grapesjs';

export const openGenerateAutobuildModal = 'open-generate-autobuild-modal';

const CustomText = (editor: Editor): void => {
  editor.TraitManager.addType('autobuild-button', {
    // Expects as return a simple HTML string or an HTML element
    createInput({ trait }) {
      // Create a new element container and add some content
      const el = document.createElement('div');
      el.innerHTML = `
          <button class="buttonTrait autobuild-button">
        <span class="spanButtonTrait">Auto Build Settings</span>
      </button>
        <style>
    
        .buttonTrait {
          display: flex;
          flex-direction: column;
          gap: 2px;
          background-color: rgb(0, 66, 107);
          color: white;
          border-radius: 6px;
          align-items: center;
          padding: 0.5rem;
          border-color: transparent;
          width: 100%;
          font-weight: 600;
        }

        .spanButtonTrait {
          font-size: 12px;
          padding: 3px;
        }
        </style>
          `;

      const autobuildButton = el.querySelector('.autobuild-button');
      if (autobuildButton) {
        autobuildButton.addEventListener('click', () => {
          editor.runCommand(openGenerateAutobuildModal);
        });
      }

      return el;
    },
  });

  const commands = editor.Commands;

  commands.add(openGenerateAutobuildModal, () => {
    const component = editor.getSelected();
    editor.trigger('autobuild:click', component);
  });

  editor.Components.addType('text', {
    model: {
      defaults: {
        attributes: {
          'data-gjs-autobuild-marker': '',
          'data-gjs-autobuild-type': '',
          'data-gjs-autobuild-order': '',
        },
        traits: [
          'id',
          'title',
          {
            type: 'autobuild-button',
            name: 'customButton',
            label: false,
          },
        ],
      },
    },
  });
};

export default CustomText;
