import { type CSSProperties } from 'react';
import styled from 'styled-components';

import { SelectFilter } from '@gbs-monorepo-packages/common';
import { colors } from '@gbs-monorepo-packages/styles';

import { type IClientDTO } from '../../../../services/companies';

export const SelectContainer = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;

  & > div > button {
    border: none;
    height: 3.25rem;
  }
`;

const selectFilterContentCustom: CSSProperties = {
  // overflow: 'auto !important',
  // height: '100%',
  maxHeight: '40vh',
  width: '100%',
  position: 'absolute',
  left: '12px',
};

export const SelectFilterData = styled(SelectFilter<IClientDTO>).attrs(
  ({ customContentStyle = selectFilterContentCustom }) => ({
    customContentStyle,
  })
)``;

export const ErrorMessage = styled.p`
  font-size: 0.875rem;
  color: ${colors.red300};
  margin: -5px 0px 15px 0px;
`;
