import { type MouseEventHandler, type ReactNode } from 'react';

import { type Roles } from '../../constants';
import { Button } from '../Button';
import { ButtonContainer, Header, Title } from './styles';

export interface IPageHeaderProps {
  title: string;
  buttonText?: string;
  buttonVisibleRoles?: Roles[];
  userRoles?: string[];
  dataCy?: string;
  additionalActions?: ReactNode;
  onClick?: MouseEventHandler<HTMLButtonElement>;
  themeStyle?: 'admin' | 'default';
  disabledButton?: boolean;
}

export const PageHeader = ({
  title,
  buttonText,
  buttonVisibleRoles = undefined,
  userRoles,
  dataCy = 'page-header',
  additionalActions = null,
  onClick,
  themeStyle,
  disabledButton = false,
  ...props
}: IPageHeaderProps): JSX.Element => {
  const hasSomeRole = buttonVisibleRoles?.some((role) => {
    return userRoles?.includes(role);
  });
  return (
    <Header data-cy={dataCy} {...props}>
      <Title data-cy={`title-${title.toLowerCase()}`}>{title}</Title>
      {(hasSomeRole ?? buttonVisibleRoles === undefined) && (
        <ButtonContainer data-cy="button-container">
          {additionalActions}
          {buttonText !== undefined && buttonText !== '' && (
            <Button
              dataCy={`button-${buttonText.toLowerCase()}`}
              onClick={onClick}
              type="button"
              styleType={themeStyle !== 'admin' ? 'default' : themeStyle}
              disabled={disabledButton}
            >
              {buttonText}
            </Button>
          )}
        </ButtonContainer>
      )}
    </Header>
  );
};
