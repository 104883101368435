import { AxiosError } from 'axios';
import {
  type KeyboardEvent,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react';
import { type SubmitHandler, useForm } from 'react-hook-form';

import {
  BaseCheckbox,
  DefaultDescription,
  FormBase,
  Logger,
  SelectData,
  useToast,
} from '@gbs-monorepo-packages/common';
import { zodResolver } from '@hookform/resolvers/zod';

import { type IDialogModalProps } from '../../../../components/DialogModal';
import {
  type CourseSettingSchema,
  courseSettingSchema,
} from '../../../../formSchemas/courseSchema';
import { useClients } from '../../../../hooks/useCompanies';
import { useCourse } from '../../../../hooks/useCourse';
import { type ICourseSettingsProps } from '../../../../services/courses';
import {
  type ITranslationSettings,
  searchTranslationSettingsByClient,
} from '../../../../services/translation';
import {
  AddIcon,
  CheckContainer,
  CloseIcon,
  CustomBaseTabs,
  CustomFormModal,
  CustomTabContainer,
  CustomTabList,
  CustomTabsTrigger,
  ExcludedWordCard,
  ExcludedWordCardInput,
  ExcludedWordCardTitle,
  ExcludedWordsFieldset,
  ExcludedWordsFieldsetLegend,
  FieldsetCheckBox,
  LabelCheckBox,
  LockedIcon,
  UnLockedIcon,
} from './styles';

interface ISettingCourseModalProps
  extends Partial<
    Omit<IDialogModalProps, 'children' | 'onAccept' | 'onOpenChange'>
  > {
  courseTitle?: string;
  onAccept: (
    passcodeProtected: boolean,
    passcode: string,
    viewURL: string,
    courseStatus: string,
    translationSettings: ITranslationSettings | null
  ) => void;
  onDecline: () => void;
  open: boolean;
  waitToOpen?: boolean;
  courseSettings: ICourseSettingsProps | null;
}

type ICourseModalSettingsTabs = 'course-tab' | 'translation-tab';

export const SettingCourseModal = ({
  onAccept,
  onDecline,
  open,
  waitToOpen = false,
  courseSettings,
  ...props
}: ISettingCourseModalProps): JSX.Element | null => {
  const { selectedClient } = useClients();
  const { addToast } = useToast();
  const { translationSettings, updateTranslationSettings } = useCourse();

  const isSelectOpen = useRef(new Set());
  const inputRef = useRef<HTMLInputElement | null>(null);

  const [passcodeProtected, setPasscodeProtected] = useState(false);
  const [showPasscode, setShowPasscode] = useState(false);
  const [excludedWords, setExcludedWords] = useState<string[]>([]);
  const [loadingTranslationSettings, setLoadingTranslationSettings] =
    useState(false);
  const [currentTab, setCurrentTab] =
    useState<ICourseModalSettingsTabs>('course-tab');

  const courseSettingSchemaModal = useForm<CourseSettingSchema>({
    resolver: zodResolver(courseSettingSchema),
    defaultValues: {
      passcode: courseSettings?.passcode ?? '',
      viewUrl: courseSettings?.viewUrl ?? '',
      status: courseSettings?.status ?? 'In Progress',
    },
  });

  const {
    handleSubmit,
    formState: { errors },
    setValue,
    watch,
  } = courseSettingSchemaModal;

  const values = watch();

  const resetForm = useCallback(() => {
    setValue('passcode', courseSettings?.passcode ?? '');
    setValue('viewUrl', courseSettings?.viewUrl ?? '');
    setValue('status', courseSettings?.status ?? 'In Progress');
    setExcludedWords(translationSettings?.excludedWords?.split('||') ?? []);
  }, [
    setValue,
    courseSettings?.passcode,
    courseSettings?.viewUrl,
    courseSettings?.status,
    translationSettings?.excludedWords,
  ]);

  useEffect(() => {
    resetForm();
  }, [courseSettings, resetForm, setValue, open]);

  const onSubmit: SubmitHandler<CourseSettingSchema> = (
    data: CourseSettingSchema
  ) => {
    onAccept?.(
      passcodeProtected,
      data.passcode ?? '',
      data.viewUrl ?? '',
      data.status ?? 'In Progress',
      {
        id: translationSettings?.id ?? null,
        excludedWords: excludedWords.join('||'),
      }
    );
  };

  const handleDeclineNewCourseSettings = useCallback(() => {
    if (!isSelectOpen.current.size) {
      resetForm();
      onDecline?.();
    }
  }, [resetForm, onDecline]);

  const handleOpenChange = useCallback((isOpen: boolean, key: string) => {
    if (isOpen) {
      isSelectOpen.current.add(key);
    } else {
      isSelectOpen.current.delete(key);
    }
  }, []);

  useEffect(() => {
    if (selectedClient === null || loadingTranslationSettings) {
      return;
    }

    setLoadingTranslationSettings(true);

    searchTranslationSettingsByClient({ clientId: selectedClient.id })
      .then((data) => {
        updateTranslationSettings(data);

        const excludedWords = data.excludedWords?.split('||') ?? [];

        setExcludedWords(excludedWords);
      })
      .catch((err: AxiosError) => {
        // Course Settings can be dynamically created, so we skip 404 errors
        if (err.status !== 404) {
          Logger.error('Error fetching translation settings', err);

          addToast({
            title: DefaultDescription,
            styleType: 'error',
            dataCy: 'error-fetching-translation-settings-toast',
          });
        }
      })
      .finally(() => {
        setLoadingTranslationSettings(false);
      });
  }, [addToast, selectedClient]);

  const handleAddExcludedWord = useCallback(() => {
    if (!inputRef.current) {
      Logger.error('Failed to recover the InputRef');

      addToast({
        title: DefaultDescription,
        styleType: 'error',
        dataCy: 'error-adding-excluded-word-toast',
      });
      return;
    }

    const word = inputRef.current.value.trim();

    if (word === '') {
      addToast({
        title: 'The word cannot be empty',
        styleType: 'error',
        dataCy: 'error-adding-empty-word-toast',
      });
      return;
    }

    if (excludedWords.includes(word)) {
      addToast({
        title: 'The word informed already exists on the list',
        styleType: 'error',
        dataCy: 'warning-adding-duplicated-word-toast',
      });
      return;
    }

    if (word.includes('<') || word.includes('>') || word.includes('|')) {
      addToast({
        title: "The word cannot contain the characters '<', '|' or '>'",
        styleType: 'error',
        dataCy: 'error-adding-invalid-char-toast',
      });
      return;
    }

    const wordRegex = /\w/;

    if (!wordRegex.test(word)) {
      addToast({
        title: 'The word should have at least a letter or number',
        styleType: 'error',
        dataCy: 'error-adding-special-chars-only-toast',
      });
      return;
    }

    if (
      word.includes('class') ||
      word.includes('notranslate') ||
      word.includes('span')
    ) {
      addToast({
        title:
          "The word cannot contain reserved words like 'span', 'class' or 'notranslate'",
        styleType: 'error',
        dataCy: 'error-adding-reserved-word-toast',
      });
      return;
    }

    setExcludedWords((prev) => [...prev, word]);

    inputRef.current.value = '';
  }, [addToast, excludedWords]);

  const onExcludedWordsKeyDown = useCallback(
    (e: KeyboardEvent<HTMLInputElement>) => {
      if (e.key === 'Enter') {
        handleAddExcludedWord();
        e.preventDefault();
      }
    },
    [handleAddExcludedWord]
  );

  const handleRemoveExcludedWord = useCallback((word: string) => {
    setExcludedWords((prev) => prev.filter((w) => w !== word));
  }, []);

  return !open ? null : (
    <FormBase.Provider {...courseSettingSchemaModal}>
      <CustomFormModal
        acceptText="Change"
        dataCy="setting-course-dialog-modal"
        declineText="Cancel"
        {...props}
        onAccept={handleSubmit(onSubmit)}
        onDecline={handleDeclineNewCourseSettings}
        onOpenChange={handleDeclineNewCourseSettings}
        open={!waitToOpen}
      >
        <CustomBaseTabs
          value={currentTab}
          onValueChange={(value) => {
            setCurrentTab(value as ICourseModalSettingsTabs);
          }}
        >
          <CustomTabList>
            <CustomTabsTrigger value="course-tab">
              Course Settings
            </CustomTabsTrigger>
            <CustomTabsTrigger value="translation-tab">
              Translation Settings
            </CustomTabsTrigger>
          </CustomTabList>

          <CustomTabContainer
            value="course-tab"
            data-cy="course-settings-tab-container"
          >
            <FormBase.Content data-cy="course-settings-container">
              <FieldsetCheckBox>
                <CheckContainer data-cy="passcode-checkbox-container">
                  <BaseCheckbox
                    id="change-passcode"
                    dataCy={'passcode-checkbox'}
                    name="change-passcode"
                    onCheckedChange={(checked) => {
                      setPasscodeProtected(checked.valueOf() === true);
                      setValue('passcode', '');
                    }}
                  />
                </CheckContainer>
                <LabelCheckBox
                  data-cy="label-checkbox"
                  htmlFor="change-passcode"
                >
                  Change Passcode
                </LabelCheckBox>
              </FieldsetCheckBox>

              <FormBase.InputContent
                filled={!!values?.passcode}
                inputRef="passcode"
                label="Passcode"
                errorMessage={errors.passcode?.message}
                dataCy="changePassword-fieldSet"
              >
                <FormBase.InputText
                  dataCy="passcode-input"
                  id="passcode"
                  name="passcode"
                  type={showPasscode ? 'text' : 'password'}
                  maxLength={20}
                  autoComplete="off"
                  disabled={!passcodeProtected}
                />
                <FormBase.InputActions
                  icon={showPasscode ? LockedIcon : UnLockedIcon}
                  onClick={() => {
                    setShowPasscode(!showPasscode);
                  }}
                  dataCy={
                    showPasscode
                      ? 'locked-icon-confirm'
                      : 'unlocked-icon-confirm'
                  }
                />
              </FormBase.InputContent>

              <FormBase.InputContent
                filled={!!values?.viewUrl}
                inputRef="view-url"
                label="Course Link"
                errorMessage={errors.viewUrl?.message}
                dataCy="settings-fieldSet"
              >
                <FormBase.InputText
                  dataCy="input-link"
                  id="view-url"
                  name="viewUrl"
                  type="text"
                  maxLength={100}
                  autoComplete="off"
                />
              </FormBase.InputContent>

              <FormBase.InputContent
                filled={!!values.status}
                value={String(values.status)}
                inputRef="select-status"
                label="Status"
                errorMessage={errors.status?.message}
                dataCy="label-status"
                isSelectData
              >
                <SelectData
                  data={[
                    { key: 'Completed', value: 'Completed' },
                    { key: 'In Progress', value: 'In Progress' },
                    { key: 'Disabled', value: 'Disabled' },
                  ]}
                  dataCy="select-status"
                  name="course-status"
                  placeholder="Select Status"
                  zIndex={10}
                  defaultValue={values.status ?? 'In Progress'}
                  onValueChange={(value) => {
                    setValue('status', value);
                  }}
                  onOpenChange={(isOpen) => {
                    handleOpenChange(isOpen, 'status');
                  }}
                />
              </FormBase.InputContent>
            </FormBase.Content>
          </CustomTabContainer>

          <CustomTabContainer
            value="translation-tab"
            data-cy="translation-settings-tab-container"
          >
            <ExcludedWordsFieldset>
              <ExcludedWordsFieldsetLegend>
                Translation Excluded Words
              </ExcludedWordsFieldsetLegend>

              <ExcludedWordCard data-cy={`add-excluded-word-card`} isInput>
                <ExcludedWordCardInput
                  data-cy={`add-excluded-word-input`}
                  placeholder="Add Word"
                  style={{ all: 'unset', width: '6rem' }}
                  ref={inputRef}
                  type="text"
                  onKeyDown={onExcludedWordsKeyDown}
                />
                <AddIcon
                  data-cy={`add-excluded-word-add-icon`}
                  onClick={handleAddExcludedWord}
                />
              </ExcludedWordCard>

              {excludedWords.length > 0 &&
                excludedWords.map((word) => {
                  const dataCy = `excluded-word-${word}`;

                  return (
                    <ExcludedWordCard
                      key={`${dataCy}-card`}
                      data-cy={`${dataCy}-card`}
                    >
                      <ExcludedWordCardTitle data-cy={`${dataCy}-title`}>
                        {word}
                      </ExcludedWordCardTitle>
                      <CloseIcon
                        data-cy={`${dataCy}-remove-icon`}
                        onClick={() => {
                          handleRemoveExcludedWord(word);
                        }}
                      />
                    </ExcludedWordCard>
                  );
                })}
            </ExcludedWordsFieldset>
          </CustomTabContainer>
        </CustomBaseTabs>
      </CustomFormModal>
    </FormBase.Provider>
  );
};
