import { isAxiosError } from 'axios';
import { type ReactNode, createContext, useCallback, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { REDIRECT } from '../constants/RoutePaths';
import * as ClientsService from '../services/companies';
import { getRouteFrom } from '../utils/getRoutes';
import Logger from '../utils/logger';

export interface IClientsContextData {
  refreshClients: boolean;
  selectClient: (client: ClientsService.IClientDTO | null) => void;
  selectClientByID: (
    clientId: string
  ) => Promise<ClientsService.IClientDTO | null>;
  selectedClient: ClientsService.IClientDTO | null;
  selectedListOptionStyle: string | null;
  selectListOptionStyle: (optionStyle: IListStyleOptions) => void;
  setRefreshClients: (refresh: boolean) => void;
}

interface IClientsProps {
  children: ReactNode;
}

interface IListStyleOptions {
  optionStyle: string;
}

export const ClientsContext = createContext<IClientsContextData>(
  {} as IClientsContextData
);

export const ClientsProvider= ({
  children,
}: IClientsProps): JSX.Element => {
  const navigate = useNavigate();
  const [selectedClient, setSelectedCompany] =
    useState<ClientsService.IClientDTO | null>(null);
  const [selectedListOptionStyle, setSelectedListOptionStyle] =
    useState<string>('list');

  const [refreshClients, setRefreshClients] = useState(false);

  const selectClient = useCallback(
    (client: ClientsService.IClientDTO | null) => {
      setSelectedCompany(client);
    },
    []
  );

  const selectListOptionStyle = useCallback(
    (optionStyle: IListStyleOptions) => {
      setSelectedListOptionStyle(optionStyle.optionStyle);
    },
    []
  );

  const selectClientByID = useCallback(
    async (clientId: string): Promise<ClientsService.IClientDTO | null> => {
      try {
        const clientIdFromPath = Number(clientId);
        const result = await ClientsService.getClientById({
          id: clientIdFromPath,
        });

        setSelectedCompany(result);
        return result;
      } catch (error) {
        Logger.debug('error: ', error);
        if (!isAxiosError(error) || error.response?.status !== 403) {
          throw error;
        } else if (error.response?.status === 403) {
          navigate(getRouteFrom(REDIRECT));
        }
        return null;
      }
    },
    []
  );

  return (
    <ClientsContext.Provider
      value={{
        refreshClients,
        selectClient,
        selectClientByID,
        selectedClient,
        selectedListOptionStyle,
        selectListOptionStyle,
        setRefreshClients,
      }}
    >
      {children}
    </ClientsContext.Provider>
  );
};
