import { type ReactNode, createContext, useCallback, useState } from 'react';

import {
  type IFolderDTO,
  type IPaginationMetaProps,
  type ISort,
  type IUserDTO,
} from '@gbs-monorepo-packages/common';

import type * as DocumentsService from '../services/documentsFolder';
import { getListDocumentsByFolder } from '../services/documentsFolder';
import * as FolderService from '../services/folders';
import { getUserById } from '../services/users';

export interface IDocumentContextData {
  documents: DocumentsService.IDocumentDTO[];
  document: DocumentsService.IDocumentDTO | null;
  subFolders: IFolderDTO[];
  folder: IFolderDTO | null;
  userCreatedFolder: IUserDTO | null;
  loadingDocuments: boolean;
  loadingDocumentsPagination: boolean;
  paginationMeta: IPaginationMetaProps | null;
  sortOrder: ISort | null;

  setLoadingDocuments: (loading: boolean) => void;
  setLoadingDocumentsPagination: (loading: boolean) => void;
  setDocuments: (documents: DocumentsService.IDocumentDTO[]) => void;
  setSubFolders: (subFolders: IFolderDTO[]) => void;
  setPaginationMeta: (meta: IPaginationMetaProps) => void;
  setSortOrder: (sort: ISort | null) => void;
  setSelectedFolder: (folder: IFolderDTO) => void;
  handleSetDocument: (document: DocumentsService.IDocumentDTO | null) => void;

  selectedDocumentLink: DocumentsService.IDocumentDTO | null;
  handleSelectDocumentLink: (
    document: DocumentsService.IDocumentDTO | null
  ) => void;

  listDocuments: (params: {
    folderId: string;
    page: number;
    limit: number;
    filter: string;
    sort: string;
  }) => Promise<void>;

  handleSetSortOrder: (sort: ISort | null) => void;
}

interface IDocumentProps {
  children: ReactNode;
}

export const DocumentContext = createContext<IDocumentContextData>(
  {} as IDocumentContextData
);

export const DocumentProvider = ({ children }: IDocumentProps): JSX.Element => {
  const [loadingDocuments, setLoadingDocuments] = useState(false);
  const [loadingDocumentsPagination, setLoadingDocumentsPagination] =
    useState(false);
  const [documents, setDocuments] = useState<DocumentsService.IDocumentDTO[]>(
    []
  );
  const [document, setDocument] =
    useState<DocumentsService.IDocumentDTO | null>(null);
  const [subFolders, setSubFolders] = useState<IFolderDTO[]>([]);
  const [paginationMeta, setPaginationMeta] =
    useState<IPaginationMetaProps | null>(null);
  const [sortOrder, setSortOrder] = useState<ISort | null>(null);

  const [folder, setFolder] = useState<IFolderDTO | null>(null);

  const [userCreatedFolder, setUserCreatedFolder] = useState<IUserDTO | null>(
    null
  );

  const [selectedDocumentLink, setSelectedDocumentLink] =
    useState<DocumentsService.IDocumentDTO | null>(null);

  const setSelectedFolder = useCallback(
    (folder: IFolderDTO) => {
      setFolder(folder);
    },
    [setFolder]
  );

  const listDocuments = useCallback(
    async ({
      folderId,
      page,
      limit,
      filter,
      sort,
    }: {
      folderId: string;
      page: number;
      limit: number;
      filter: string;
      sort: string;
    }) => {
      setLoadingDocuments(true);
      try {
        const fetchedDocuments = await getListDocumentsByFolder({
          folderId: folderId ?? '',
          page,
          limit,
          filter: filter ?? '',
          sort,
        });

        const fetchFolderInformation = await FolderService.getListFolderById({
          id: Number(folderId),
        });

        const userCreateFolder = await getUserById(
          fetchFolderInformation.data.accountId
        );

        setSelectedFolder(fetchFolderInformation.data);
        setDocuments(fetchedDocuments.data.documents);
        setSubFolders(fetchedDocuments.data.subFolders);
        setPaginationMeta(fetchedDocuments.meta);
        setUserCreatedFolder(userCreateFolder);
      } finally {
        setLoadingDocuments(false);
      }
    },
    [setSelectedFolder]
  );

  const handleSetSortOrder = useCallback(
    (sort: ISort | null) => {
      setSortOrder(sort);
    },
    [setSortOrder]
  );

  const handleSelectDocumentLink = useCallback(
    (document: DocumentsService.IDocumentDTO | null) => {
      setSelectedDocumentLink(document);
    },
    [setSelectedDocumentLink]
  );

  const handleSetDocument = useCallback(
    (document: DocumentsService.IDocumentDTO | null) => {
      setDocument(document);
    },
    [setDocument]
  );

  return (
    <DocumentContext.Provider
      value={{
        documents,
        document,
        subFolders,
        folder,
        userCreatedFolder,
        loadingDocuments,
        loadingDocumentsPagination,
        paginationMeta,
        sortOrder,
        setLoadingDocuments,
        setLoadingDocumentsPagination,
        setDocuments,
        setSubFolders,
        setPaginationMeta,
        setSortOrder,
        setSelectedFolder,
        listDocuments,
        handleSetSortOrder,
        handleSelectDocumentLink,
        selectedDocumentLink,
        handleSetDocument,
      }}
    >
      {children}
    </DocumentContext.Provider>
  );
};
