import { type Editor } from 'grapesjs';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { type SubmitHandler } from 'react-hook-form';
import { generatePath, useNavigate, useParams } from 'react-router-dom';

import { getRouteFrom } from '@gbs-monorepo-packages/common';

import {
  CREATE_TEMPLATE_MODAL_BUTTON_LINK_FOLDER,
  EDIT_COURSE_MODAL_BUTTON_LINK_FOLDER,
} from '../../../../../constants/RoutePaths';
import { useDocument } from '../../../../../hooks/useDocuments';
import { type IFormPopoverProps } from '../../../../FormPopover';
import { FormDocumentLink } from './components/FormDocumentLink';
import { FormMailTo } from './components/FormMailtoLink';
import { FormWebLink } from './components/FormWebLink';
import { type EmailLinkSchema, type WebLinkSchema } from './modalLinkSchema';
import {
  BaseModalCustom,
  TabsContent,
  TabsList,
  TabsRoot,
  TabsTrigger,
} from './style';

export enum ConfigLinkTabs {
  WEB_LINK = 'web-link',
  DOCUMENT_LINK = 'document-link',
  EMAIL_LINK = 'email-link',
}

interface IModalUploadImage
  extends Partial<Omit<IFormPopoverProps, 'children'>> {
  editor: Editor | null;
  open: boolean;
  waitToOpen?: boolean;
  onClose: () => void;
}

interface IComponentValue {
  title?: string;
  url?: string;
  body?: string;
  email?: string;
  subject?: string;
}

export const ModalConfigLinks = ({
  editor,
  open,
  onClose,
  waitToOpen = false,
  ...props
}: IModalUploadImage): JSX.Element => {
  const navigate = useNavigate();
  const { clientId = '', courseId = '', templateId } = useParams();
  const { selectedDocumentLink, handleSelectDocumentLink } = useDocument();
  const [selectedTab, setSelectedTab] = useState<ConfigLinkTabs>(
    ConfigLinkTabs.WEB_LINK
  );
  const [componentValue, setComponentValue] = useState<IComponentValue>();

  const baseUrl = useMemo(() => {
    if (templateId) {
      return generatePath(
        `${getRouteFrom(CREATE_TEMPLATE_MODAL_BUTTON_LINK_FOLDER)}`,
        {
          clientId,
          templateId,
        }
      );
    }

    return generatePath(
      `${getRouteFrom(EDIT_COURSE_MODAL_BUTTON_LINK_FOLDER)}`,
      {
        clientId,
        courseId,
      }
    );
  }, [clientId, courseId, templateId]);

  useEffect(() => {
    if (!open) return;

    const component = editor?.getSelected();
    if (!component?.attributes) return;

    const attributes =
      (component.attributes.attributes as Record<string, string>) ?? {};
    const href = attributes.href || '';

    if (href.includes('mailto:')) {
      setSelectedTab(ConfigLinkTabs.EMAIL_LINK);
    }
  }, [editor, open]);

  useEffect(() => {
    if (!open) return;
    const component = editor?.getSelected();
    if (!component?.attributes) return;

    const attributes =
      (component.attributes.attributes as Record<string, string>) ?? {};
    const href = attributes.href || '';

    let updatedAttributes = {};

    switch (selectedTab) {
      case ConfigLinkTabs.WEB_LINK:
        updatedAttributes = {
          title: attributes.title || '',
          url: href.includes('mailto:') ? '' : href,
        };
        break;

      case ConfigLinkTabs.EMAIL_LINK:
        updatedAttributes = parseMailtoRegex(href) ?? {};
        break;
    }

    setComponentValue(updatedAttributes);
  }, [editor, open, selectedTab]);

  useEffect(() => {
    if (!open) {
      setSelectedTab(ConfigLinkTabs.WEB_LINK);
    }
  }, [open]);

  const parseMailtoRegex = (
    url: string
  ): {
    email: string;
    subject: string;
    body: string;
  } | null => {
    const regex =
      /^(?:mailto:)?([^?]+)?(?:\?subject=([^&]*)?)?(?:&body=([^&]*)?)?/;
    const match = url.match(regex);

    return match
      ? {
          email: match[1] || '',
          subject: decodeURIComponent(match[2] || ''),
          body: decodeURIComponent(match[3] || ''),
        }
      : null;
  };

  const closeModal = useCallback(() => {
    setSelectedTab(ConfigLinkTabs.WEB_LINK);
    onClose?.();
  }, [onClose]);

  useEffect(() => {
    if (selectedTab === ConfigLinkTabs.DOCUMENT_LINK) {
      navigate(baseUrl, {
        replace: true,
      });
    }
  }, [baseUrl, clientId, courseId, selectedTab]);

  useEffect(() => {
    if (selectedDocumentLink) {
      const component = editor?.getSelected();

      if (component !== undefined) {
        component?.setAttributes({
          target: '_blank',
          href: selectedDocumentLink.path,
        });
      }
      handleSelectDocumentLink(null);
      closeModal();
    }
  }, [closeModal, editor, onClose, selectedDocumentLink]);

  const handleSelectTable = useCallback((value: string) => {
    setSelectedTab(value as ConfigLinkTabs);
  }, []);

  const onSubmitWebLink: SubmitHandler<WebLinkSchema> = (
    data: WebLinkSchema
  ) => {
    const component = editor?.getSelected();

    if (component !== undefined) {
      component?.setAttributes({
        target: data.target,
        href: data.url,
        title: data.title,
      });
    }
    closeModal();
  };

  const onSubmitEmail: SubmitHandler<EmailLinkSchema> = (
    data: EmailLinkSchema
  ) => {
    const component = editor?.getSelected();
    if (component !== undefined) {
      component?.setAttributes({
        href: `mailto:${data.email}?subject=${data.subject}&body=${data.body}`,
      });
    }
    closeModal();
  };

  return !open ? (
    <></>
  ) : (
    <BaseModalCustom
      {...props}
      open={!waitToOpen}
      onOpenChange={onClose}
      hiddenCloseButton={false}
      zIndex={10}
      biggerModal
      fullWidth={selectedTab === ConfigLinkTabs.DOCUMENT_LINK}
      fullHeight={selectedTab === ConfigLinkTabs.DOCUMENT_LINK}
    >
      <TabsRoot
        defaultValue={ConfigLinkTabs.WEB_LINK}
        value={selectedTab}
        onValueChange={handleSelectTable}
        $fullscreen={selectedTab === ConfigLinkTabs.DOCUMENT_LINK}
      >
        <TabsList aria-label="Choose document link">
          <TabsTrigger
            value={ConfigLinkTabs.WEB_LINK}
            data-cy={ConfigLinkTabs.WEB_LINK}
          >
            Web Link
          </TabsTrigger>
          <TabsTrigger
            value={ConfigLinkTabs.DOCUMENT_LINK}
            data-cy={ConfigLinkTabs.DOCUMENT_LINK}
          >
            Document Link
          </TabsTrigger>
          <TabsTrigger
            value={ConfigLinkTabs.EMAIL_LINK}
            data-cy={ConfigLinkTabs.EMAIL_LINK}
          >
            Mailto Link
          </TabsTrigger>
        </TabsList>

        <TabsContent
          value={ConfigLinkTabs.WEB_LINK}
          data-cy={`${ConfigLinkTabs.WEB_LINK}-container`}
        >
          <FormWebLink
            onSubmit={onSubmitWebLink}
            onCancel={onClose}
            componentValue={{
              title: componentValue?.title ?? '',
              url: componentValue?.url ?? '',
            }}
          />
        </TabsContent>

        <TabsContent
          value={ConfigLinkTabs.DOCUMENT_LINK}
          data-cy={`${ConfigLinkTabs.DOCUMENT_LINK}-container`}
        >
          <FormDocumentLink />
        </TabsContent>

        <TabsContent
          value={ConfigLinkTabs.EMAIL_LINK}
          data-cy={`${ConfigLinkTabs.EMAIL_LINK}-container`}
        >
          <FormMailTo
            onSubmit={onSubmitEmail}
            onCancel={onClose}
            componentValue={{
              body: componentValue?.body ?? '',
              email: componentValue?.email ?? '',
              subject: componentValue?.subject ?? '',
            }}
          />
        </TabsContent>
      </TabsRoot>
    </BaseModalCustom>
  );
};
