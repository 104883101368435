import { FaFolder } from 'react-icons/fa';
import styled from 'styled-components';

import { TableCell } from '../../../components';

export const FolderIcon = styled(FaFolder)`
  height: 1.5rem;
  width: 1.5rem;
`;

export const TableCellCustom = styled(TableCell)`
  padding: 1rem;
`;

export const DropdownButtonContainer = styled.div`
  align-items: center;
  display: inline-flex;
  height: 100%;
  justify-content: flex-end;
  width: 2.75rem;
`;
