import styled from 'styled-components';

import { BaseRadioButton, BaseRadioGroup } from '@gbs-monorepo-packages/common';
import { colors } from '@gbs-monorepo-packages/styles';

export const RadioGroupRoot = styled(BaseRadioGroup)`
  display: flex;
  flex-direction: row;
  gap: 10px;
  margin: 0.5rem 0;
`;

export const RadioGroupItem = styled(BaseRadioButton)`
  all: unset;
  background-color: ${colors.white300};
  width: 1.02rem;
  height: 1.1rem;
  border-radius: 25%;
  border: 1px solid ${colors.blue400};
`;

export const Flex = styled.div`
  display: flex;
  align-items: center;
`;

export const Label = styled.label`
  color: ${colors.black300};
  font-size: 0.85rem;
  padding-left: 0.5rem;
`;
