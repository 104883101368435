import { useMemo } from 'react';

import { useAuth } from '@gbs-monorepo-packages/auth';
import {
  type IUserDTO,
  Roles,
  TemplatesType,
} from '@gbs-monorepo-packages/common';

import { type ITemplateOptionsDTO } from '../../../../services/templates';

interface ITemplateBase {
  userCreatedId?: number;
  templateOptions?: ITemplateOptionsDTO;
}

export const useCanTemplate = <T extends ITemplateBase>(
  templateData: T,
  user: IUserDTO | null
): boolean => {
  const { getCurrentRole } = useAuth();
  const currentRole = getCurrentRole(user?.roles ?? []);

  const isAdmin = useMemo(() => {
    return currentRole?.key === Roles.ADMIN;
  }, [currentRole?.key]);

  const allowEditAndDelete = useMemo(() => {
    if (!templateData.templateOptions) {
      return false;
    }

    if (isAdmin) {
      return true;
    }
    if (templateData.templateOptions?.id === TemplatesType.MY_TEMPLATES) {
      return templateData.userCreatedId === user?.id;
    }
    if (
      templateData.templateOptions?.id === TemplatesType.SHARE_TEMPLATES &&
      currentRole?.key === Roles.ACCOUNT_MANAGER
    ) {
      return true;
    }
    return templateData.userCreatedId === user?.id;
  }, [
    currentRole,
    isAdmin,
    templateData.templateOptions,
    templateData.userCreatedId,
    user?.id,
  ]);

  return allowEditAndDelete;
};
