import { type ReactNode, createContext, useCallback, useState } from 'react';

import {
  type IFolderDTO,
  type IPaginationMetaProps,
  type ISort,
  Logger,
} from '@gbs-monorepo-packages/common';

import * as FolderService from '../services/folders';

export interface IFolderContextData {
  folders: IFolderDTO[];
  selectedFolder: IFolderDTO | null;
  loadingFolders: boolean;
  loadingFoldersPagination: boolean;
  paginationMeta: IPaginationMetaProps | null;
  sortOrder: ISort | null;

  setLoadingFolders: (loading: boolean) => void;
  setLoadingFoldersPagination: (loading: boolean) => void;
  setPaginationMeta: (meta: IPaginationMetaProps) => void;
  setFolders: (folders: IFolderDTO[]) => void;

  handleSetSortOrder: (sort: ISort | null) => void;
  setSelectedFolder: (folder: IFolderDTO) => void;

  getListFolderByClient: (params: {
    clientId: string;
    page: number;
    limit: number;
    filter: string;
    search: string;
    sort: string;
  }) => Promise<FolderService.IPaginationFolderDTO | undefined>;

  createFolder: (folder: FolderService.ICreateFolderProps) => Promise<void>;
  updateFolder: (folder: FolderService.IUpdateFolderProps) => Promise<void>;
  deleteFolder: (folderId: number) => Promise<void>;
}

interface IFolderProps {
  children: ReactNode;
}

export const FolderContext = createContext<IFolderContextData>(
  {} as IFolderContextData
);

export const FolderProvider = ({ children }: IFolderProps): JSX.Element => {
  const [loadingFolders, setLoadingFolders] = useState(false);
  const [loadingFoldersPagination, setLoadingFoldersPagination] =
    useState(false);
  const [folders, setFolders] = useState<IFolderDTO[]>([]);
  const [paginationMeta, setPaginationMeta] =
    useState<IPaginationMetaProps | null>(null);
  const [sortOrder, setSortOrder] = useState<ISort | null>(null);

  const [folder, setFolder] = useState<IFolderDTO | null>(null);

  const getListFolderByClient = useCallback(
    async ({
      clientId,
      page,
      limit,
      filter,
      search,
      sort,
    }: {
      clientId: string;
      page: number;
      limit: number;
      filter: string;
      search: string;
      sort: string;
    }) => {
      try {
        setLoadingFolders(true);
        const response = await FolderService.getListFolderByClient({
          clientId,
          page,
          limit,
          filter,
          search,
          sort,
        });

        setFolders(response.data);
        setPaginationMeta(response.meta);
        return response;
      } catch (error) {
        Logger.debug('error: ', error);
        return undefined;
      } finally {
        setLoadingFolders(false);
      }
    },
    []
  );

  const handleSetSortOrder = useCallback(
    (sort: ISort | null) => {
      setSortOrder(sort);
    },
    [setSortOrder]
  );

  const setSelectedFolder = useCallback(
    (folder: IFolderDTO) => {
      setFolder(folder);
    },
    [setFolder]
  );

  const createFolder = useCallback(
    async (folder: FolderService.ICreateFolderProps) => {
      try {
        await FolderService.createFolder(folder);
      } catch (error) {
        Logger.debug('error: ', error);
      }
    },
    []
  );

  const updateFolder = useCallback(
    async (folder: FolderService.IUpdateFolderProps) => {
      try {
        await FolderService.updateFolder(folder);
      } catch (error) {
        Logger.debug('error: ', error);
      }
    },
    []
  );

  const deleteFolder = useCallback(async (folderId: number) => {
    try {
      await FolderService.deleteFolder({ id: folderId });
    } catch (error) {
      Logger.debug('error: ', error);
    }
  }, []);

  return (
    <FolderContext.Provider
      value={{
        folders,
        setFolders,
        selectedFolder: folder,
        loadingFolders,
        loadingFoldersPagination,
        paginationMeta,
        sortOrder,
        setLoadingFolders,
        setLoadingFoldersPagination,
        setPaginationMeta,
        getListFolderByClient,
        handleSetSortOrder,
        setSelectedFolder,
        createFolder,
        updateFolder,
        deleteFolder,
      }}
    >
      {children}
    </FolderContext.Provider>
  );
};
