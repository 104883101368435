import styled, { css } from 'styled-components';

import {
  BaseModal,
  BaseTabs,
  BaseTabsContent,
  BaseTabsList,
  BaseTabsTrigger,
} from '@gbs-monorepo-packages/common';
import { colors, radii } from '@gbs-monorepo-packages/styles';

export const BaseModalCustom = styled(BaseModal)`
  padding: 25px;
`;

export const TextContent = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  font-size: 0.875rem;
`;

export const Title = styled.h1`
  font-size: 1.125rem;
  padding: 1rem 1rem 1rem 0;
  margin-bottom: 0.875rem;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 100%;
`;

export const ClearButton = styled.p`
  color: ${colors.textLight};
  border: none;
  border-radius: 4px;
  margin-top: 16px;
  cursor: pointer;
  font-size: 16px;
  font-weight: 500;
  transition: background-color 0.3s;
  text-decoration: underline;
`;

interface ITabsRootProps {
  $fullscreen: boolean;
}

export const TabsRoot = styled(BaseTabs)<ITabsRootProps>`
  display: flex;
  flex-direction: column;
  width: 100%;

  ${({ $fullscreen = false }) =>
    $fullscreen &&
    css`
      height: 90vh;
      transition: height 0.8s ease;
    `}
`;

export const TabsList = styled(BaseTabsList)`
  flex-shrink: 0;
  display: flex;
  width: fit-content;
`;

export const TabsTrigger = styled(BaseTabsTrigger)`
  font-family: inherit;
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: start;
  font-size: 15px;
  line-height: 1;

  user-select: none;
  border: 1px solid ${colors.lightGray100};
  border-bottom: none;
  border-top-left-radius: ${radii.sm};
  border-top-right-radius: ${radii.sm};
  padding: 0.75rem;
  width: 10rem;
  background-color: transparent;
  color: ${colors.blue250};
  font-weight: 600;
  &[data-state='active'] {
    border: 1px solid ${colors.lightGray200};
    background-color: ${colors.lightGray200};
  }
`;

export const TabsContent = styled(BaseTabsContent)`
  flex-grow: 1;
  border-bottom-left-radius: ${radii.sm};
  border-bottom-right-radius: ${radii.sm};
  outline: none;
  margin-bottom: ${radii.sm};
  padding: ${radii.md} 0;
`;
