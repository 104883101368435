import styled from 'styled-components';

import { FormBase } from '@gbs-monorepo-packages/common';

export const FormContent = styled(FormBase.Content)`
  padding: 1rem 0;
`;

export const ContentButton = styled.div`
  display: flex;
  justify-content: flex-end;
  gap: 0.5rem;
`;
