import { type PropertiesHyphen } from 'csstype';
import { type Component, type Editor } from 'grapesjs';
import debounce from 'lodash.debounce';
import {
  type ChangeEvent,
  type DragEvent,
  type FormEvent,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react';

import { useAuth } from '@gbs-monorepo-packages/auth';
import {
  BaseCheckbox,
  Button,
  Roles,
  useToast,
} from '@gbs-monorepo-packages/common';

import { UPLOAD_MAX_FILESIZE } from '../../../../../constants/Env';
import { type ICoursePage } from '../../../../../contexts/coursePage';
import { useCourse } from '../../../../../hooks/useCourse';
import { useCoursePages } from '../../../../../hooks/useCoursePages';
import {
  type IImage,
  type IUploadResponseData,
  getCourseDocuments,
  getTemplateDocuments,
  globalGetDocuments,
  globalUploadDocument,
  uploadDocumentCourse,
  uploadDocumentTemplate,
} from '../../../../../services/courses';
import {
  type IDocumentDTO,
  type IDocumentFailedDTO,
  courseDeleteDocument,
  globalDeleteDocument,
  templateDeleteDocument,
} from '../../../../../services/documents';
import { type ITemplateColors } from '../../../../../services/templates';
import Logger from '../../../../../utils/logger';
import { DialogModal } from '../../../../DialogModal';
import { type IFormPopoverProps } from '../../../../FormPopover';
import { MatchSvgColorsModal } from './components/MatchSvgColorsModal';
import {
  ActionButton,
  ButtonDrop,
  CheckboxContainer,
  CheckboxLabel,
  ContainerListItems,
  DeleteIcon,
  DeleteIconLibrary,
  DraggableSpace,
  ErrorMessage,
  FooterContent,
  Form,
  FormContainer,
  ImageContainer,
  ImageContainerList,
  ImageDeleteButton,
  ImageGrid,
  ImageName,
  Input,
  Label,
  ListImageContainer,
  Loading,
  LoadingContainer,
  MainContainer,
  MainContent,
  MainContentGrid,
  PreviewImage,
  SectionPreviewImage,
  Separator,
  SimpleContainer,
  TabsContent,
  TabsList,
  TabsRoot,
  TabsTrigger,
  TextContent,
} from './styles';

const promiseSvg = async (url: string): Promise<string | undefined> => {
  try {
    const response = await fetch(url, {
      method: 'GET',
      mode: 'cors',
      cache: 'no-cache',
      headers: {
        Origin: window.location.origin,
      },
    });
    if (!response.ok) {
      throw new Error('An error occurred while fetching the SVG');
    }

    return await response.text();
  } catch (error) {
    Logger.alert('Failed to load image:', error);
    return '';
  }
};

const replaceCssId = (cssString: string, newId: string) => {
  return cssString.replace(/#([\w-]+)(?=\s*{)/g, `#${newId}`);
};

const handleAddSVG = (
  editor: Editor,
  compStr: string,
  source: Component,
  isNew: boolean
): void => {
  const { DomComponents, Layers } = editor;

  const svgContent = DomComponents.addComponent(compStr);

  if (Array.isArray(svgContent)) {
    svgContent.forEach((svgContent) => svgContent.remove());
    throw new Error('Multiple SVGs are not supported');
  }

  Layers.setVisible(svgContent, false);

  svgContent.set('resizable', true);

  const style: PropertiesHyphen = {
    width: 'calc(100% - 10px)',
    height: 'calc(100% - 10px)',
  };
  svgContent.setStyle(style as Record<string, string>);

  Layers.setVisible(svgContent, false);

  const parent = source.parent();
  if (!parent) {
    svgContent.remove();
    throw new Error('Error on find the component');
  }
  const getRules = editor.Css.getRules(`#${source?.ccid}`);
  // if not have style in dimension section, get the width and height from the SVG
  const hasNoStyleDimension =
    Object.keys(getRules?.at(0)?.attributes?.style ?? {}).length === 0;
  if (hasNoStyleDimension) {
    const width = source.attributes?.attributes?.width as string;
    const height = source.attributes?.attributes?.height as string;
    const newStyle = {
      width: `${width}px`,
      height: `${height}px`,
    };
    svgContent.setStyle(newStyle as Record<string, string>);
  } else {
    // if have style in dimension section, get the width and height from the style
    getRules.forEach((rule) => {
      const newCss = replaceCssId(rule.toCSS(), svgContent?.ccid);
      editor.Css.addRules(newCss);
    });
  }

  const at = source.index();

  if (isNew || parent.get('type') !== 'custom-svg') {
    let svgContainerComponent = DomComponents.addComponent({
      type: 'custom-svg',
    });

    if (Array.isArray(svgContainerComponent)) {
      const first = svgContainerComponent.at(0);
      if (!first) {
        svgContent.remove();
        throw new Error('Error adding the container of SVG');
      }
      svgContainerComponent = first;
    }

    svgContainerComponent.append(svgContent);
    parent.append(svgContainerComponent, { at });
  } else {
    if (parent.get('type') === 'custom-image') {
      svgContent.remove();
      throw new Error('It is not allowed to drag a SVG to image container');
    }

    parent.append(svgContent, { at });
  }
  Layers.setVisible(svgContent, true);
  editor.select(parent);
};

interface IModalUploadImage
  extends Partial<Omit<IFormPopoverProps, 'children'>> {
  editor: Editor | null;
  clientId: number;
  pageId: number;
  open: boolean;
  onClose: () => void;
  draggableArea?: Component;
  multipleChoose?: boolean;
  idImage?: string;
  screen: string;
}

interface IErrorUploadImage {
  message: string;
}
type IImageLibrary = IImage & {
  isGlobal?: boolean;
};

const allImagesTypes = 'image/*';
const imagesTypesClientRole = ['image/png', 'image/jpeg', 'image/jpg'];

export const ModalUploadImagePopover = ({
  editor,
  clientId,
  pageId,
  open,
  onClose,
  draggableArea,
  multipleChoose = true,
  idImage = '',
  screen,
}: IModalUploadImage): JSX.Element => {
  const { coursePages, selectedCoursePageIndex } = useCoursePages();
  const [svgColors, setSvgColors] = useState<ITemplateColors>();
  const [coursePageId, setCoursePageId] = useState<number>(0);
  const [svgStrToReplace, setSvgStrToReplace] = useState<string[]>([]);
  const [loadingMatchColors, setLoadingMatchColors] = useState(false);
  const [draggableAreaAux, setDraggableAreaAux] = useState<
    Component | undefined
  >(undefined);
  const [imageAux, setImageAux] = useState<Component | undefined>(undefined);
  const [isColorsMatchModalOpen, setIsColorsMatchModalOpen] = useState(false);
  const { selectedCourse } = useCourse();

  const [imageDescriptionArray, setImageDescriptionArray] = useState<string[]>(
    []
  );
  const [ckUploadIsGlobalArray, setCkUploadIsGlobalArray] = useState<boolean[]>(
    []
  );
  const [previewImagesArray, setPreviewImagesArray] = useState<string[]>([]);
  const [fileUploadArray, setFileUploadArray] = useState<Blob[] | File[]>([]);
  const [errorUploadArray, setErrorUploadArray] = useState<IErrorUploadImage[]>(
    []
  );

  const [errorMaxSizeMessage, setErrorMaxSizeMessage] = useState<string | null>(
    null
  );

  // Form inputs
  const [imageDescription, setImageDescription] = useState('');
  const [ckUploadIsGlobal, setCkUploadIsGlobal] = useState(false);
  const [previewImage, setPreviewImage] = useState<string[]>([]);
  const [fileUpload, setFileUpload] = useState<Blob[] | File[]>([]);
  const [imageUrl, setImageUrl] = useState('');
  const [isActionRunning, setIsActionRunning] = useState(false);

  // drag state
  const [dragActive, setDragActive] = useState(false);

  const [globalsImages, setGlobalImages] = useState<IImageLibrary | null>(null);
  const [imagesLibrary, setImageLibrary] = useState<IImageLibrary | null>(null);
  const [svgLibrary, setSvgLibrary] = useState<IImageLibrary | null>(null);

  const [loadingImage, setLoadingImage] = useState(false);
  const [tabOpened, setTabOpened] = useState('UI');

  const [selectedImage, setSelectedImage] = useState<number[]>([]);
  const [selectedSvg, setSelectedSvg] = useState<number[]>([]);
  const [selectedGlobalSvg, setSelectedGlobalSvg] = useState<number[]>([]);

  const [searchImageLibrary, setSearchImageLibrary] = useState('');
  const [searchGlobalLibrary, setSearchGlobalLibrary] = useState('');
  const [searchSvgLibrary, setSearchSvgLibrary] = useState('');

  const [openDelete, setModalConfirm] = useState(false);

  const [isAdmin, setIsAdmin] = useState(false);

  const inputRef = useRef<HTMLInputElement>(null);

  const { addToast } = useToast();
  const { user, getCurrentRole } = useAuth();

  const filteredImageLibrary = imagesLibrary?.data.filter((item) =>
    item.name.toLowerCase().includes(searchImageLibrary?.toLowerCase())
  );

  const filteredGlobalLibrary = globalsImages?.data.filter((item) =>
    item.name.toLowerCase().includes(searchGlobalLibrary?.toLowerCase())
  );

  const filteredSvgLibrary = svgLibrary?.data.filter((item) =>
    item.name.toLowerCase().includes(searchSvgLibrary?.toLowerCase())
  );

  const hasSvg = useRef(false);
  const hasGlobalImagesRef = useRef(false);

  const disabledButtonUpload =
    loadingImage ||
    isActionRunning ||
    (previewImage.length === 0 && previewImagesArray.length === 0);
  const disabledButtonSendImageGrape =
    loadingImage ||
    isActionRunning ||
    (selectedImage.length === 0 &&
      selectedSvg.length === 0 &&
      selectedGlobalSvg.length === 0);
  const disabledButtonSendSVGGrape =
    loadingImage ||
    isActionRunning ||
    (selectedSvg.length === 0 && selectedGlobalSvg.length === 0);

  useEffect(() => {
    const currentRole = getCurrentRole(user?.roles ?? []);
    if (currentRole?.key === Roles.ADMIN) {
      setIsAdmin(true);
    }
  }, [getCurrentRole, user]);

  useEffect(() => {
    let mount = true;
    const syncGlobalsImages = async () => {
      try {
        setLoadingImage(true);
        const result: IImage = await globalGetDocuments();

        if (mount) {
          result.data = result.data.filter((item) => item.extension !== 'svg');
          setGlobalImages(result);
        }
      } catch (err) {
        Logger.debug('err: ', err);
      } finally {
        if (mount) {
          setLoadingImage(false);
        }
      }
    };

    const syncImageLibrary = async () => {
      try {
        setLoadingImage(true);
        let result;
        if (screen === 'course') {
          result = await getCourseDocuments({
            pageId,
          });
        } else {
          result = await getTemplateDocuments({
            pageId,
          });
        }

        if (mount) {
          result.data = result.data.filter((item) => item.extension !== 'svg');
          // add new attribute to know if is global or not
          result.data = result.data.map((item) => ({
            ...item,
            isGlobal: false,
          }));
          setImageLibrary(result);
        }
      } catch (err) {
        Logger.debug('err: ', err);
      } finally {
        if (mount) {
          setLoadingImage(false);
        }
      }
    };

    const syncSvgLibrary = async () => {
      try {
        setLoadingImage(true);
        const resultSvgGlobal = await globalGetDocuments();
        let resultSvgImage;
        if (screen === 'course') {
          resultSvgImage = await getCourseDocuments({
            pageId,
          });
        } else {
          resultSvgImage = await getTemplateDocuments({
            pageId,
          });
        }

        if (mount) {
          resultSvgGlobal.data = resultSvgGlobal.data.filter(
            (item) => item.extension === 'svg'
          );
          resultSvgImage.data = resultSvgImage.data.filter(
            (item) => item.extension === 'svg'
          );
          // add new attribute to know if is global or not
          resultSvgGlobal.data = resultSvgGlobal.data.map((item) => ({
            ...item,
            isGlobal: true,
          }));
          resultSvgImage.data = resultSvgImage.data.map((item) => ({
            ...item,
            isGlobal: false,
          }));
          setSvgLibrary({
            data: [...resultSvgGlobal.data, ...resultSvgImage.data],
          });
        }
      } catch (err) {
        Logger.debug('err: ', err);
      } finally {
        if (mount) {
          setLoadingImage(false);
        }
      }
    };

    if (tabOpened === 'IL') {
      void syncImageLibrary();
    } else if (tabOpened === 'GI') {
      void syncGlobalsImages();
    } else if (tabOpened === 'SG') {
      void syncSvgLibrary();
    }

    return () => {
      mount = false;
    };
  }, [pageId, screen, tabOpened]);

  const isValidURL = (url: string): boolean => {
    const pattern = /^(https?|ftp):\/\/.+/;
    return pattern.test(url);
  };

  const isImageURL = async (url: string): Promise<boolean> => {
    try {
      const response = await fetch(url, { method: 'HEAD' });
      const contentType = response.headers.get('Content-Type');
      return contentType?.startsWith('image/') ?? false;
    } catch (error) {
      return false;
    }
  };

  const resetState = () => {
    hasSvg.current = false;
    hasGlobalImagesRef.current = false;
    setImageDescription('');
    setCkUploadIsGlobal(false);
    setFileUpload([]);
    setPreviewImage([]);
    setImageUrl('');
    setSelectedImage([]);
    setImageDescriptionArray([]);
    setCkUploadIsGlobalArray([]);
    setPreviewImagesArray([]);
    setFileUploadArray([]);
    setSelectedSvg([]);
    setSelectedGlobalSvg([]);
    setErrorUploadArray([]);
    setErrorMaxSizeMessage(null);
    setModalConfirm(false);
  };

  const loadImageFromUrl = useCallback(
    async (url: string): Promise<string> => {
      try {
        if (!isValidURL(url)) {
          throw new Error('Invalid URL');
        }

        if (!(await isImageURL(url))) {
          throw new Error('URL does not point to an image');
        }

        const response = await fetch(url);
        const blob = await response.blob();
        const reader = new FileReader();

        return await new Promise<string>((resolve, reject) => {
          reader.onloadend = () => {
            if (typeof reader.result === 'string') {
              setFileUpload((fileUpload) => [...fileUpload, blob]);
              resolve(reader.result);
            } else {
              reject(new Error('Failed to load image'));
            }
          };
          reader.readAsDataURL(blob);
          reader.onerror = reject;
        });
      } catch (err) {
        const customError: IErrorUploadImage = {
          message: 'Failed to fetch image from URL',
        };
        addToast({
          title: 'Failed to fetch image from URL',
          description: customError.message,
          styleType: 'error',
          dataCy: 'not-fetch-image-error-toast',
        });
        throw new Error('Failed to fetch image from URL');
      }
    },
    [addToast]
  );

  const handleLoadImage = useCallback(
    (currentImageLoad: string) => {
      if (currentImageLoad) {
        try {
          loadImageFromUrl(currentImageLoad)
            .then((response) => {
              setPreviewImage((previewImage) => [...previewImage, response]);
              setImageUrl('');
            })
            .catch((error) => {
              Logger.alert('Failed to load image:', error);
            });
        } catch (error) {
          Logger.alert('Failed to load image:', error);
        }
      }
    },
    [loadImageFromUrl, previewImage]
  );

  // URL image debounced
  const debouncedUrlImage = useRef(
    debounce((currentImageLoad: string) => {
      handleLoadImage(currentImageLoad);
    }, 1000)
  ).current;

  useEffect(() => {
    debouncedUrlImage(imageUrl);
  }, [debouncedUrlImage, imageUrl]);

  // handle drag events
  const handleDrag = (e: DragEvent) => {
    e.preventDefault();
    e.stopPropagation();
    if (e.type === 'dragenter' || e.type === 'dragover') {
      setDragActive(true);
    } else if (e.type === 'dragleave') {
      setDragActive(false);
    }
  };

  // triggers when file is dropped
  const handleDropMultipleImages = async (e: DragEvent): Promise<void> => {
    e.preventDefault();
    e.stopPropagation();
    setDragActive(false);

    const files = e.dataTransfer?.files;

    if (files && files.length > 0) {
      const totalFiles = [...fileUpload, ...files];
      const totalSize = totalFiles.reduce((sum, file) => sum + file.size, 0);

      if (setMaxSizeError(totalSize)) return;
      for (let i = 0; i < files.length; i++) {
        const file = files[i];

        const imageDataURL = (await readFileAsync(file)) as string;
        if (imageDataURL) {
          setPreviewImage((previewImage) => [...previewImage, imageDataURL]);
          setFileUpload((fileUpload) => [...fileUpload, file]);
        }
      }
    }
  };

  const handleImageMultipleChange = async (
    event: ChangeEvent<HTMLInputElement>
  ): Promise<void> => {
    event.preventDefault();
    event.stopPropagation();
    const files = event.target.files;

    if (files) {
      const totalFiles = [...fileUpload, ...files];
      const totalSize = totalFiles.reduce((sum, file) => sum + file.size, 0);

      if (setMaxSizeError(totalSize)) return;

      for (let i = 0; i < files.length; i++) {
        const file = files[i];

        const imageDataURL = (await readFileAsync(file)) as string;
        if (imageDataURL) {
          setPreviewImage((previewImage) => [...previewImage, imageDataURL]);
          setFileUpload((fileUpload) => [...fileUpload, file]);
        }
      }
    }
    event.target.value = '';
  };

  const setMaxSizeError = (totalSize: number) => {
    const maxSize = Number(UPLOAD_MAX_FILESIZE);
    const maxSizeToBytes: number = maxSize * 1024 * 1024;

    if (totalSize > maxSizeToBytes) {
      setErrorMaxSizeMessage(
        `The total size of the images exceeds the limit of ${maxSize}MB`
      );
      setPreviewImage([]);
      setFileUpload([]);
      return true;
    }
    setErrorMaxSizeMessage(null);
    return false;
  };

  const readFileAsync = async (file: File) => {
    return await new Promise((resolve, reject) => {
      const reader = new FileReader();

      reader.onload = () => {
        resolve(reader.result as string);
      };

      reader.onerror = (error) => {
        reject(error);
      };

      reader.readAsDataURL(file);
    });
  };

  const handleChooseImage = (
    imageId: number,
    extension: string,
    isGlobal: boolean
  ) => {
    if (extension === 'svg') {
      handleChooseSvg(imageId, isGlobal);
    } else {
      setSelectedImage([imageId]);
    }
  };

  const handleMultipleChooseSvg = (imageId: number, svgIsGlobal: boolean) => {
    if (svgIsGlobal) {
      if (selectedGlobalSvg.includes(imageId)) {
        setSelectedGlobalSvg(selectedGlobalSvg.filter((id) => id !== imageId));
      } else {
        setSelectedGlobalSvg([...selectedGlobalSvg, imageId]);
      }
    } else {
      if (selectedSvg.includes(imageId)) {
        setSelectedSvg(selectedSvg.filter((id) => id !== imageId));
      } else {
        setSelectedSvg([...selectedSvg, imageId]);
      }
    }
  };

  const handleChooseSvg = (imageId: number, isGlobal: boolean) => {
    if (isGlobal) {
      setSelectedGlobalSvg([imageId]);
    } else {
      setSelectedSvg([imageId]);
    }
  };

  const handleMultipleChooseImage = (
    imageId: number,
    extension: string,
    isGlobal: boolean
  ) => {
    if (extension === 'svg') {
      handleMultipleChooseSvg(imageId, isGlobal);
    } else {
      if (selectedImage.includes(imageId)) {
        setSelectedImage(selectedImage.filter((id) => id !== imageId));
      } else {
        setSelectedImage([...selectedImage, imageId]);
      }
    }
  };

  const handleAddImageList = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (fileUpload.length > 0) {
      const newFileUploadArray = [...fileUploadArray];
      const newImageDescriptionArray = [...imageDescriptionArray];
      const newCkUploadIsGlobalArray = [...ckUploadIsGlobalArray];
      const newPreviewImagesArray = [...previewImagesArray];

      fileUpload.forEach((file: Blob) => {
        newFileUploadArray.push(file);
        newImageDescriptionArray.push(imageDescription);
        newCkUploadIsGlobalArray.push(ckUploadIsGlobal);
      });

      previewImage.forEach((image) => {
        newPreviewImagesArray.push(image);
      });

      setFileUploadArray(newFileUploadArray);
      setImageDescriptionArray(newImageDescriptionArray);
      setCkUploadIsGlobalArray(newCkUploadIsGlobalArray);
      setPreviewImagesArray(newPreviewImagesArray);
    }

    setCkUploadIsGlobal(false);
    setImageDescription('');
    setPreviewImage([]);
    setImageUrl('');
    setFileUpload([]);
  };

  const dispatchUploadGlobalDocument = async (
    fileUploadArray: Blob[],
    description: string[]
  ) => {
    const result = await globalUploadDocument({
      file: fileUploadArray,
      documentName: description,
      clientId: clientId,
    });
    return result;
  };

  const dispatchUploadCourseDocument = async (
    fileUploadArray: Blob[],
    description: string[],
    courseId: number
  ) => {
    const result = await uploadDocumentCourse({
      file: fileUploadArray,
      documentName: description,
      pageId: courseId,
    });
    return result;
  };

  const dispatchUploadTemplateDocument = async (
    fileUploadArray: Blob[],
    description: string[],
    templateId: number
  ) => {
    const result = await uploadDocumentTemplate({
      file: fileUploadArray,
      documentName: description,
      pageId: templateId,
    });
    return result;
  };

  const handleUploadImages = useCallback(
    (e: FormEvent<HTMLFormElement>) => {
      e.preventDefault();
      if (!fileUploadArray.length) {
        return;
      }

      setLoadingImage(true);

      const fileUploadGlobal = [];
      const imageDescriptionGlobal = [];
      const fileLibrary = [];
      const imageDescriptionLibrary = [];
      setErrorUploadArray([]);
      for (let i = 0; i < ckUploadIsGlobalArray.length; i++) {
        if (ckUploadIsGlobalArray[i]) {
          fileUploadGlobal.push(fileUploadArray[i]);
          imageDescriptionGlobal.push(imageDescriptionArray[i]);
        } else {
          fileLibrary.push(fileUploadArray[i]);
          imageDescriptionLibrary.push(imageDescriptionArray[i]);
        }
      }

      const defaultPromise: Array<Promise<IUploadResponseData>> = [];

      if (fileUploadGlobal.length) {
        const promiseGlobal = dispatchUploadGlobalDocument(
          fileUploadGlobal,
          imageDescriptionGlobal
        );
        defaultPromise.push(promiseGlobal);
      }

      if (fileLibrary.length) {
        if (screen === 'course') {
          const promise = dispatchUploadCourseDocument(
            fileLibrary,
            imageDescriptionLibrary,
            pageId
          );
          defaultPromise.push(promise);
        } else {
          const promise = dispatchUploadTemplateDocument(
            fileLibrary,
            imageDescriptionLibrary,
            pageId
          );
          defaultPromise.push(promise);
        }
      }

      Promise.all(defaultPromise)
        .then((response) => {
          setLoadingImage(false);

          const resultWithIndex: IDocumentFailedDTO[] = (
            response[0]?.data?.error ?? []
          ).map((data, index) => ({
            ...data,
            ...data,
            index,
          }));

          if (resultWithIndex.length > 0) {
            const uploadFailedId = resultWithIndex.map(
              (item: IDocumentFailedDTO) => item.uploadFileId
            );

            resultWithIndex.map((data: IDocumentFailedDTO, index) => {
              const customError: IErrorUploadImage = {
                message: data.message,
              };

              setErrorUploadArray((prev) => {
                const newErrorUploadArray = [...prev];
                newErrorUploadArray[index] = customError;
                return newErrorUploadArray;
              });
              return null;
            });

            const newPreviewImagesArray = previewImagesArray.filter(
              (item, index) => uploadFailedId.includes(index)
            );
            const newImageDescriptionArray = imageDescriptionArray.filter(
              (item, index) => uploadFailedId.includes(index)
            );
            const newCkUploadIsGlobalArray = ckUploadIsGlobalArray.filter(
              (item, index) => uploadFailedId.includes(index)
            );
            const newFileUploadArray = fileUploadArray.filter((item, index) =>
              uploadFailedId.includes(index)
            );

            setImageDescriptionArray(newImageDescriptionArray);
            setCkUploadIsGlobalArray(newCkUploadIsGlobalArray);
            setPreviewImagesArray(newPreviewImagesArray);
            setFileUploadArray(newFileUploadArray);
          } else {
            ckUploadIsGlobalArray.forEach((item) => {
              if (item) {
                hasGlobalImagesRef.current = true;
              }
            });

            fileUploadArray.forEach((file) => {
              if (file.type === 'image/svg+xml') {
                hasSvg.current = true;
              }
            });

            if (hasGlobalImagesRef.current && hasSvg.current) {
              setTabOpened('SG');
            } else if (hasGlobalImagesRef.current) {
              setTabOpened('GI');
            } else {
              setTabOpened(hasSvg.current ? 'SG' : 'IL');
            }
            resetState();
          }
        })
        .catch(() => {
          setLoadingImage(false);
          addToast({
            title: `Image upload failed`,
            styleType: 'error',
            dataCy: 'no-image-description-error-toast',
          });
        });
    },
    [
      addToast,
      ckUploadIsGlobalArray,
      fileUploadArray,
      imageDescriptionArray,
      pageId,
      previewImagesArray,
      screen,
    ]
  );

  const onButtonClick = () => {
    if (inputRef.current) {
      inputRef.current.click();
    }
  };

  const handleTabClick = (event: string) => {
    setSearchImageLibrary('');
    setSearchGlobalLibrary('');
    setSearchSvgLibrary('');
    setSelectedImage([]);
    setSelectedSvg([]);
    setSelectedGlobalSvg([]);
    setTabOpened(event);
  };

  const flowInsertImage = () => {
    setIsActionRunning(true);
    selectedImage.forEach((id) => {
      const imageGallery = tabOpened === 'IL' ? imagesLibrary : globalsImages;
      const image = imageGallery?.data.filter((item) => item.id === id)[0];
      if (idImage) {
        // Change only src and alt
        const component = editor?.getSelected();
        if (!component) return;
        if (component?.get('type') === 'svg') {
          addToast({
            title: 'Failed to add image',
            description: 'It is not allowed to change from SVG to image.',
            styleType: 'error',
            dataCy: 'not-change-svg-to-image-error-toast',
          });
        } else {
          component?.setAttributes({
            src: image?.path,
            alt: image?.name,
          });
          if (
            component?.get('type') === 'img' ||
            component?.get('type') === 'image' ||
            component?.attributes.tagName === 'img'
          ) {
            component?.set('type', 'custom-image');
          }
        }
      } else {
        // insert a images
        if (image) {
          const imageComponent = editor?.DomComponents.addComponent(
            {
              tagName: 'img',
              type: 'custom-image',
              droppable: false,
              draggable: false,
              resizable: true,
              attributes: {
                src: image.path,
                alt: image.name,
              },
            },
            {}
          ) as Component | undefined;
          const style: PropertiesHyphen = {
            width: 'auto',
            height: 'auto',
          };

          if (imageComponent) {
            imageComponent.setStyle(style as Record<string, string>);
            draggableArea?.set('droppable', false);
            draggableArea?.append(imageComponent);
          }
        }
      }
    });
    setIsActionRunning(false);
    setSelectedImage([]);
  };

  const dispatchDeleteGlobalDocument = async (documentId: string) => {
    await globalDeleteDocument({
      documentId,
    });
  };
  const dispatchDeleteCourseDocument = async (documentId: string) => {
    await courseDeleteDocument({
      documentId,
    });
  };
  const dispatchDeleteTemplateDocument = async (documentId: string) => {
    await templateDeleteDocument({
      documentId,
    });
  };

  const handleDeleteImage = useCallback(
    async (documentId: string) => {
      setIsActionRunning(true);

      const defaultPromise: Array<Promise<void>> = [];

      if (tabOpened === 'GI') {
        const promiseGlobal = dispatchDeleteGlobalDocument(documentId);
        defaultPromise.push(promiseGlobal);
      } else if (tabOpened === 'IL') {
        if (screen === 'course') {
          const promise = dispatchDeleteCourseDocument(documentId);
          defaultPromise.push(promise);
        } else {
          const promise = dispatchDeleteTemplateDocument(documentId);
          defaultPromise.push(promise);
        }
      }

      await Promise.all(defaultPromise)
        .then(() => {
          addToast({
            title: `Image deleted`,
            styleType: 'success',
            dataCy: 'image-deleted-toast',
          });

          // verify if image is selected and remove from selectedImage
          if (selectedImage.includes(Number(documentId))) {
            setSelectedImage(
              selectedImage.filter((item) => item !== Number(documentId))
            );
          }

          // remove item from imagesLibrary
          if (tabOpened === 'IL') {
            const newImagesLibrary = imagesLibrary?.data.filter(
              (item) => item.id !== Number(documentId)
            );
            setImageLibrary({
              ...imagesLibrary,
              data: newImagesLibrary as IDocumentDTO[],
            });
          } else if (tabOpened === 'GI') {
            const newGlobalsImages = globalsImages?.data.filter(
              (item) => item.id !== Number(documentId)
            );
            setGlobalImages({
              ...globalsImages,
              data: newGlobalsImages as IDocumentDTO[],
            });
          }
        })
        .catch(() => {
          addToast({
            title: `Image delete failed`,
            styleType: 'error',
            dataCy: 'image-delete-failed-toast',
          });
        })
        .finally(() => {
          setIsActionRunning(false);
        });
    },
    [addToast, globalsImages, imagesLibrary, screen, selectedImage, tabOpened]
  );

  const handleDeleteSVG = useCallback(
    async (documentId: string, isGlobal: boolean) => {
      setIsActionRunning(true);

      const defaultPromise: Array<Promise<void>> = [];

      if (isGlobal) {
        const promise = dispatchDeleteGlobalDocument(documentId);
        defaultPromise.push(promise);
      } else {
        if (screen === 'course') {
          const promise = dispatchDeleteCourseDocument(documentId);
          defaultPromise.push(promise);
        } else {
          const promise = dispatchDeleteTemplateDocument(documentId);
          defaultPromise.push(promise);
        }
      }

      await Promise.all(defaultPromise)
        .then(() => {
          addToast({
            title: `Image deleted`,
            styleType: 'success',
            dataCy: 'image-deleted-toast',
          });

          const newSvgImages = svgLibrary?.data.filter(
            (item) => item.id !== Number(documentId)
          );
          setSvgLibrary({
            ...svgLibrary,
            data: newSvgImages as IDocumentDTO[],
          });
        })
        .catch(() => {
          addToast({
            title: `Image delete failed`,
            styleType: 'error',
            dataCy: 'image-delete-failed-toast',
          });
        })
        .finally(() => {
          setIsActionRunning(false);
        });
    },
    [addToast, svgLibrary]
  );

  const handleClose = useCallback(() => {
    resetState();
    setTabOpened('UI');
    onClose?.();
  }, [onClose]);

  const insertSvgWithNewColors = useCallback(
    (updatedSvgs: string[]) => {
      if (!editor) {
        return;
      }

      const toRemove = new Set<Component>();

      for (const svg of updatedSvgs) {
        try {
          if (!svg) {
            throw new Error('It was not possible to get the SVG.');
          }
          if (draggableAreaAux) {
            handleAddSVG(editor, svg, draggableAreaAux, true);
            toRemove.add(draggableAreaAux);
          }
          if (imageAux) {
            handleAddSVG(editor, svg, imageAux, false);
            imageAux.remove();
          }
        } catch (error) {
          Logger.debug('Failed to add svg:', error);
          if (error instanceof Error) {
            addToast({
              title: 'Failed to add svg',
              description: error.message,
              styleType: 'error',
              dataCy: 'add-svg-error-toast',
            });
          }
        }
      }
      toRemove.forEach((item) => item.remove());
      handleClose();
    },
    [handleClose, editor, addToast, draggableAreaAux, imageAux]
  );

  const flowInsertSvg = async () => {
    if (!editor) {
      return;
    }

    const processSvg = async (tmpSelectedSvg: number[], isGlobal: boolean) => {
      const uniqueColors: ITemplateColors = {};
      const svgs: string[] = [];
      for (const id of tmpSelectedSvg) {
        try {
          const url = svgLibrary?.data.find(
            (item) => item.id === id && item.isGlobal === isGlobal
          )?.path;
          if (!url) {
            continue;
          }

          const svgStr = await promiseSvg(url);
          if (!svgStr) {
            throw new Error('It was not possible to get the SVG.');
          }

          if (idImage) {
            const image = editor.getSelected();
            if (!image) continue;
            if (
              image.attributes.tagName === 'img' ||
              image?.get('type') === 'image' ||
              image?.get('type') === 'img'
            ) {
              throw new Error('It is not allowed to change from image to SVG.');
            } else {
              if (
                selectedCourse?.globalStyle?.primaryColor === null ||
                coursePages[selectedCoursePageIndex] === undefined
              ) {
                handleAddSVG(editor, svgStr, image, false);
                image.remove();
              } else {
                const regex = /fill="(#[a-fA-F\d]+)"/g;

                let match;
                while ((match = regex.exec(svgStr)) !== null) {
                  const color = match[1];
                  uniqueColors[color] = color;
                }

                svgs.push(svgStr);
              }
            }
          } else if (draggableArea) {
            if (
              selectedCourse?.globalStyle?.primaryColor === null ||
              coursePages[selectedCoursePageIndex] === undefined
            ) {
              handleAddSVG(editor, svgStr, draggableArea, true);
              toRemove.add(draggableArea);
            } else {
              const regex = /fill="(#[a-fA-F\d]+)"/g;

              let match;
              while ((match = regex.exec(svgStr)) !== null) {
                const color = match[1];
                uniqueColors[color] = color;
              }

              svgs.push(svgStr);
            }
          }
        } catch (error) {
          Logger.debug('Failed to add svg:', error);
          if (error instanceof Error) {
            addToast({
              title: 'Failed to add svg',
              description: error.message,
              styleType: 'error',
              dataCy: 'add-svg-error-toast',
            });
          }
        }
      }

      const coursePage = coursePages[selectedCoursePageIndex];
      if (
        Object.keys(uniqueColors).length > 0 &&
        selectedCourse?.globalStyle?.primaryColor !== null &&
        coursePage !== undefined
      ) {
        setSvgStrToReplace(svgs);
        setSvgColors(uniqueColors);
        setCoursePageId(coursePage.id);
        setIsColorsMatchModalOpen(true);
        setLoadingMatchColors(false);
        if (idImage) {
          setImageAux(editor.getSelected());
          setDraggableAreaAux(undefined);
        } else {
          setDraggableAreaAux(draggableArea);
          setImageAux(undefined);
        }
      }
    };

    const toRemove = new Set<Component>();
    await processSvg(selectedSvg, false);
    await processSvg(selectedGlobalSvg, true);
    toRemove.forEach((item) => item.remove());
    handleClose();
  };

  const replaceColorsInSVG = (
    svgString: string,
    colors: ITemplateColors
  ): string => {
    let result: string = svgString;
    const colorMarkers = new Map<string, string>();

    Object.keys(colors).forEach((originalColor, index) => {
      const tempMarker = `__COLOR_MARKER_${index}__`;
      result = result.split(originalColor).join(tempMarker);
      colorMarkers.set(tempMarker, colors[originalColor]);
    });

    colorMarkers.forEach((newColor, tempMarker) => {
      result = result.split(tempMarker).join(newColor);
    });

    return result;
  };

  const handleDeclineEditColorsPage = useCallback(() => {
    setIsColorsMatchModalOpen(false);
    setLoadingMatchColors(false);
    setSvgColors({});
  }, []);

  const handleAcceptEditColorsPage = useCallback(
    async (page: ICoursePage) => {
      setSvgColors({});
      setLoadingMatchColors(true);
      setIsColorsMatchModalOpen(false);
      const updatedSVGs = [];

      for (const svg of svgStrToReplace) {
        const updatedSVG = replaceColorsInSVG(
          svg,
          page.templateColorsFound ?? {}
        );
        updatedSVGs.push(updatedSVG);
      }

      if (!editor || !updatedSVGs.length) {
        addToast({
          title: 'Failed to add svg',
          description: 'An error occurred when matching the colors',
          styleType: 'error',
          dataCy: 'match-svg-color-error-toast',
        });
        return;
      }

      insertSvgWithNewColors(updatedSVGs);
      setSvgStrToReplace([]);
    },
    [svgStrToReplace, editor, insertSvgWithNewColors, addToast]
  );

  const handleSendImageGrape = async () => {
    flowInsertImage();
    await flowInsertSvg();
    setSelectedImage([]);
    setSelectedSvg([]);
  };

  const removeImageItemFromList = useCallback(
    (imageSelect: string, errorKey: number) => {
      const newImageDescriptionArray = [...imageDescriptionArray];
      const newCkUploadIsGlobalArray = [...ckUploadIsGlobalArray];
      const newFileUpload = [...fileUpload];
      const newPreviewImagesArray = [...previewImagesArray];
      const newFileUploadArray = [...fileUploadArray];

      const index = newPreviewImagesArray.indexOf(imageSelect);
      if (index > -1) {
        newImageDescriptionArray.splice(index, 1);
        newCkUploadIsGlobalArray.splice(index, 1);
        newFileUpload.splice(index, 1);
        newPreviewImagesArray.splice(index, 1);
        newFileUploadArray.splice(index, 1);
      }
      setImageDescriptionArray(newImageDescriptionArray);
      setCkUploadIsGlobalArray(newCkUploadIsGlobalArray);
      setPreviewImagesArray(newPreviewImagesArray);
      setFileUploadArray(newFileUploadArray);
      setErrorUploadArray((errorUploadArray) =>
        errorUploadArray.filter((_, key) => key !== errorKey)
      );
    },
    [
      ckUploadIsGlobalArray,
      fileUpload,
      imageDescriptionArray,
      previewImagesArray,
      fileUploadArray,
    ]
  );

  const handleInputDescriptionChange = (
    event: ChangeEvent<HTMLInputElement>,
    index: number
  ) => {
    const inputDescription = event.target.value;
    setImageDescriptionArray((imageDescriptionArray) => {
      const updatedDescription = [...imageDescriptionArray];
      updatedDescription[index] = inputDescription;
      return updatedDescription;
    });
  };

  const verifyList = useCallback(() => {
    if (previewImage.length > 0) {
      setModalConfirm(true);
    } else {
      handleClose();
    }
  }, [handleClose, previewImage]);

  return (
    <>
      <DialogModal
        dataCy="modal-upload-component"
        mainText="Insert Image"
        biggerModal={true}
        onOpenChange={verifyList}
        open={open}
        zIndex={10}
        setWidth="850px"
      >
        <MainContainer data-cy="modal-custom-upload-image">
          <TabsRoot value={tabOpened} onValueChange={handleTabClick}>
            <TabsList aria-label="Choose a image">
              <TabsTrigger
                value="UI"
                disabled={loadingImage || isActionRunning}
              >
                Upload Image
              </TabsTrigger>
              <TabsTrigger
                value="IL"
                disabled={loadingImage || isActionRunning}
              >
                Image Library
              </TabsTrigger>
              <TabsTrigger
                value="GI"
                disabled={loadingImage || isActionRunning}
              >
                Global Image
              </TabsTrigger>
              <TabsTrigger
                value="SG"
                disabled={loadingImage || isActionRunning}
              >
                {' '}
                Image SVG
              </TabsTrigger>
            </TabsList>

            <TabsContent value="UI" data-cy="tab-upload">
              <MainContent id="form-file-upload" onDragEnter={handleDrag}>
                <Form
                  id="frmUploadImage"
                  onSubmit={(event) => {
                    previewImagesArray.length === 0 || previewImage.length > 0
                      ? handleAddImageList(event)
                      : handleUploadImages(event);
                  }}
                >
                  <FormContainer>
                    <Input
                      id="input-file-upload"
                      ref={inputRef}
                      type="file"
                      hidden={true}
                      multiple={true}
                      onChange={(event) => {
                        void handleImageMultipleChange(event);
                      }}
                      accept={
                        isAdmin
                          ? allImagesTypes
                          : imagesTypesClientRole.join(', ')
                      }
                    />
                    <Label id="label-file-upload" htmlFor="input-file-upload">
                      <ButtonDrop type="button" onClick={onButtonClick}>
                        Drop files here or click to upload
                      </ButtonDrop>
                    </Label>
                    {dragActive && (
                      <DraggableSpace
                        onDragEnter={handleDrag}
                        onDragLeave={handleDrag}
                        onDragOver={handleDrag}
                        onDrop={(event) => {
                          void handleDropMultipleImages(event);
                        }}
                      />
                    )}
                    <Separator />
                    <Input
                      data-cy="image-url-input"
                      id="image-url"
                      type="text"
                      placeholder="Add URL Image"
                      value={imageUrl}
                      onChange={(e) => {
                        setImageUrl(e.target.value);
                      }}
                    />
                  </FormContainer>
                </Form>
              </MainContent>
              <FooterContent existPreview={true}>
                {errorMaxSizeMessage && (
                  <ErrorMessage data-cy="file-upload-error">
                    {errorMaxSizeMessage}
                  </ErrorMessage>
                )}
                <SectionPreviewImage>
                  {previewImage?.length > 0 ? (
                    previewImage.map((item, key) => (
                      <PreviewImage key={key} src={item} alt="Preview" />
                    ))
                  ) : (
                    <></>
                  )}
                </SectionPreviewImage>
                <SimpleContainer>
                  {isAdmin && (
                    <CheckboxContainer>
                      <CheckboxLabel
                        data-cy="ck-global-image-label"
                        htmlFor="set-is-global-image-checkbox"
                      >
                        Global Image
                      </CheckboxLabel>
                      <BaseCheckbox
                        form="frmUploadImage"
                        checked={ckUploadIsGlobal}
                        dataCy="set-is-global-image-checkbox"
                        id="set-is-global-image-checkbox"
                        name="set-is-global-image-checkbox"
                        disabled={loadingImage || isActionRunning}
                        onCheckedChange={(checked) => {
                          setCkUploadIsGlobal(
                            checked === 'indeterminate' ? false : checked
                          );
                        }}
                      />
                    </CheckboxContainer>
                  )}
                  <Button
                    type="button"
                    styleType="outline"
                    onClick={() => {
                      verifyList();
                    }}
                    disabled={loadingImage}
                  >
                    Cancel
                  </Button>
                  <Button
                    type="submit"
                    disabled={loadingImage || disabledButtonUpload}
                    loading={loadingImage || isActionRunning}
                    form="frmUploadImage"
                  >
                    {previewImagesArray.length === 0 || previewImage.length > 0
                      ? 'INSERT IMAGE'
                      : 'UPLOAD IMAGE'}
                  </Button>
                </SimpleContainer>
              </FooterContent>
              <ListImageContainer>
                {previewImagesArray?.map((file, key) => (
                  <ContainerListItems
                    key={key}
                    isGlobal={ckUploadIsGlobalArray[key]}
                  >
                    <ImageContainerList>
                      <PreviewImage src={file} alt="Preview" />
                    </ImageContainerList>
                    <TextContent>
                      <Input
                        data-cy="image-description-input"
                        id="image-description"
                        type="text"
                        placeholder="Image Description"
                        onChange={(event) => {
                          handleInputDescriptionChange(event, key);
                        }}
                        disabled={loadingImage}
                        readOnly={false}
                        value={imageDescriptionArray[key]}
                        isError={Boolean(errorUploadArray[key]?.message)}
                      />
                      {errorUploadArray[key]?.message && (
                        <ErrorMessage data-cy={errorUploadArray[key]?.message}>
                          {errorUploadArray[key]?.message}
                        </ErrorMessage>
                      )}
                    </TextContent>
                    <ActionButton
                      disabled={loadingImage || isActionRunning}
                      type="button"
                      onClick={() => {
                        removeImageItemFromList(file, key);
                      }}
                    >
                      <DeleteIcon />
                    </ActionButton>
                  </ContainerListItems>
                ))}
              </ListImageContainer>
            </TabsContent>

            <TabsContent value="IL" data-cy="tab-image-library">
              <MainContent>
                <Input
                  id="search-image-library"
                  type="text"
                  placeholder="Search Library"
                  onChange={(e) => {
                    setSearchImageLibrary(e.target.value);
                  }}
                />
                {tabOpened === 'IL' && !loadingImage ? (
                  <MainContentGrid>
                    {filteredImageLibrary &&
                    filteredImageLibrary?.length > 0 ? (
                      filteredImageLibrary.map((item) => (
                        <ImageContainer
                          key={`courseImage-${item.id}`}
                          onClick={() => {
                            if (multipleChoose) {
                              handleMultipleChooseImage(
                                item.id,
                                item.extension,
                                false
                              );
                            } else {
                              handleChooseImage(item.id, item.extension, false);
                            }
                          }}
                          isSelected={Boolean(selectedImage.includes(item.id))}
                          isActionRunning={isActionRunning}
                        >
                          <ImageGrid alt={item.name} src={item.path} />
                          <ImageName>{item.name}</ImageName>
                          <ImageDeleteButton
                            onClick={(e) => {
                              e.stopPropagation();
                              void handleDeleteImage(String(item.id));
                            }}
                            disabled={isActionRunning}
                          >
                            <DeleteIconLibrary />
                          </ImageDeleteButton>
                        </ImageContainer>
                      ))
                    ) : (
                      <p>No images found</p>
                    )}
                  </MainContentGrid>
                ) : (
                  <LoadingContainer data-cy="loading-members-container">
                    <Loading />
                  </LoadingContainer>
                )}
              </MainContent>
              <FooterContent>
                <Button
                  type="button"
                  styleType="outline"
                  onClick={() => {
                    handleClose();
                  }}
                  disabled={loadingImage}
                >
                  Cancel
                </Button>
                <Button
                  type="button"
                  onClick={() => {
                    void handleSendImageGrape();
                  }}
                  disabled={loadingImage || disabledButtonSendImageGrape}
                  loading={loadingImage || isActionRunning}
                >
                  INSERT IMAGE
                </Button>
              </FooterContent>
            </TabsContent>

            <TabsContent value="GI" data-cy="tab-global-library">
              <MainContent>
                <Input
                  id="search-global-library"
                  type="text"
                  placeholder="Search Global"
                  onChange={(e) => {
                    setSearchGlobalLibrary(e.target.value);
                  }}
                />
                {tabOpened === 'GI' && !loadingImage ? (
                  <MainContentGrid>
                    {filteredGlobalLibrary &&
                    filteredGlobalLibrary?.length > 0 ? (
                      filteredGlobalLibrary.map((item) => (
                        <ImageContainer
                          key={`globalImage-${item.id}`}
                          onClick={() => {
                            if (multipleChoose) {
                              handleMultipleChooseImage(
                                item.id,
                                item.extension,
                                true
                              );
                            } else {
                              handleChooseImage(item.id, item.extension, false);
                            }
                          }}
                          isSelected={Boolean(selectedImage.includes(item.id))}
                          isActionRunning={isActionRunning}
                        >
                          <ImageGrid alt={item.name} src={item.path} />
                          <ImageName>{item.name}</ImageName>
                          {isAdmin && (
                            <ImageDeleteButton
                              onClick={(e) => {
                                e.stopPropagation();
                                void handleDeleteImage(String(item.id));
                              }}
                              disabled={isActionRunning}
                            >
                              <DeleteIconLibrary />
                            </ImageDeleteButton>
                          )}
                        </ImageContainer>
                      ))
                    ) : (
                      <p>No images found.</p>
                    )}
                  </MainContentGrid>
                ) : (
                  <LoadingContainer data-cy="loading-members-container">
                    <Loading />
                  </LoadingContainer>
                )}
              </MainContent>
              <FooterContent>
                <Button
                  type="button"
                  styleType="outline"
                  onClick={() => {
                    handleClose();
                  }}
                  disabled={loadingImage}
                >
                  Cancel
                </Button>
                <Button
                  type="button"
                  onClick={() => {
                    void handleSendImageGrape();
                  }}
                  disabled={loadingImage || disabledButtonSendImageGrape}
                  loading={loadingImage || isActionRunning}
                >
                  INSERT IMAGE
                </Button>
              </FooterContent>
            </TabsContent>

            <TabsContent value="SG" data-cy="tab-svg-library">
              <MainContent>
                <Input
                  id="search-svg-library"
                  type="text"
                  placeholder="Search image SVG"
                  onChange={(e) => {
                    setSearchSvgLibrary(e.target.value);
                  }}
                />
                {tabOpened === 'SG' && !loadingImage ? (
                  <MainContentGrid>
                    {filteredSvgLibrary && filteredSvgLibrary?.length > 0 ? (
                      filteredSvgLibrary.map((item) => (
                        <ImageContainer
                          key={`svg-${item.id}-${
                            item.isGlobal ? 'global' : ''
                          }`}
                          onClick={() => {
                            if (multipleChoose) {
                              handleMultipleChooseImage(
                                item.id,
                                item.extension,
                                item.isGlobal
                              );
                            } else {
                              handleChooseImage(
                                item.id,
                                item.extension,
                                item.isGlobal
                              );
                            }
                          }}
                          isSelected={
                            item.isGlobal
                              ? Boolean(selectedGlobalSvg.includes(item.id))
                              : Boolean(selectedSvg.includes(item.id))
                          }
                          isGlobal={item.isGlobal}
                          isSvg={true}
                        >
                          <ImageGrid alt={item.name} src={item.path} />
                          <ImageName>{item.name}</ImageName>
                          {isAdmin && (
                            <ImageDeleteButton
                              onClick={(e) => {
                                e.stopPropagation();
                                void handleDeleteSVG(
                                  String(item.id),
                                  item.isGlobal
                                );
                              }}
                              disabled={isActionRunning}
                            >
                              <DeleteIcon />
                            </ImageDeleteButton>
                          )}
                        </ImageContainer>
                      ))
                    ) : (
                      <p>No images found.</p>
                    )}
                  </MainContentGrid>
                ) : (
                  <LoadingContainer data-cy="loading-members-container">
                    <Loading />
                  </LoadingContainer>
                )}
              </MainContent>
              <FooterContent>
                <Button
                  type="button"
                  styleType="outline"
                  onClick={() => {
                    handleClose();
                  }}
                  disabled={loadingImage || isActionRunning}
                >
                  Cancel
                </Button>
                <Button
                  type="button"
                  onClick={() => {
                    void handleSendImageGrape();
                  }}
                  disabled={
                    loadingImage ||
                    isActionRunning ||
                    disabledButtonSendSVGGrape
                  }
                  loading={loadingImage || isActionRunning}
                >
                  INSERT IMAGE
                </Button>
              </FooterContent>
            </TabsContent>
          </TabsRoot>
        </MainContainer>
      </DialogModal>

      {svgColors && (
        <MatchSvgColorsModal
          onDecline={handleDeclineEditColorsPage}
          onAccept={handleAcceptEditColorsPage}
          pageId={coursePageId}
          open={isColorsMatchModalOpen}
          loading={loadingMatchColors}
          colorsDefault={svgColors}
          zIndex={10}
        />
      )}
      <DialogModal
        acceptText="Confirm"
        dataCy="confirm-dialog-modal"
        declineText="Cancel"
        mainText={`Are you sure you want to cancel?`}
        onAccept={() => {
          handleClose();
        }}
        onDecline={() => {
          setModalConfirm(false);
        }}
        open={openDelete}
        hiddenCloseButton={true}
        zIndex={11}
      />
    </>
  );
};
