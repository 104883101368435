import { type MouseEvent, type ReactNode, forwardRef } from 'react';
import { type To } from 'react-router-dom';

import { BaseAccordionItem } from '../BaseAccordion';
import { BaseTooltip } from '../BaseTooltip';
import {
  ButtonItem,
  ExpandButton,
  ExpandButtonContent,
  LinkItem,
  NavLinkCustom,
  Text,
} from './styles';

interface ISidebarExpandItemProps {
  accordionValue: string;
  end?: undefined;
  onClick?: undefined;
  to?: undefined;
  type: 'accordion';
}

interface ISidebarExpandLinkItemProps {
  accordionValue: string;
  end?: boolean;
  onClick?: undefined;
  to: To;
  type: 'accordionLink';
}

interface ISidebarButtonItemProps {
  accordionValue?: undefined;
  end?: undefined;
  onClick?: (event: MouseEvent<HTMLButtonElement>) => void;
  to?: undefined;
  type: 'button';
}

interface ISidebarLinkItemProps {
  accordionValue?: undefined;
  end?: boolean;
  onClick?: undefined;
  to: To;
  type: 'link';
}

type ISidebarItemProps = {
  children?: ReactNode;
  dataCy?: string;
  icon?: ReactNode;
  isShrunken?: boolean;
  title?: string;
  isActive?: boolean;
} & (
    | ISidebarButtonItemProps
    | ISidebarExpandItemProps
    | ISidebarExpandLinkItemProps
    | ISidebarLinkItemProps
  );

type ISidebarItemRef = HTMLAnchorElement & HTMLButtonElement & HTMLDivElement;

export const SidebarItem = forwardRef<ISidebarItemRef, ISidebarItemProps>(
  // eslint-disable-next-line no-restricted-syntax
  function SidebarItem(
    {
      accordionValue,
      children,
      dataCy = 'sidebar-item',
      end,
      icon,
      isShrunken = false,
      onClick,
      title,
      to,
      type,
      isActive,
    },
    ref
  ): JSX.Element {
    return type === 'accordion' && !isShrunken ? (
      <BaseAccordionItem value={accordionValue} data-cy={dataCy} ref={ref}>
        <ExpandButton noArrow={isShrunken} $isShrunken={isShrunken}>
          {icon}
          <Text $isShrunken={isShrunken}>{title}</Text>
        </ExpandButton>
        <ExpandButtonContent $isShrunken={isShrunken}>
          {children}
        </ExpandButtonContent>
      </BaseAccordionItem>
    ) : type === 'accordion' ? (
      <BaseAccordionItem value={accordionValue} data-cy={dataCy} ref={ref}>
        <BaseTooltip message={title}>
          <ExpandButton noArrow={isShrunken} $isShrunken={isShrunken}>
            {icon}
            <Text $isShrunken={isShrunken}>{title}</Text>
          </ExpandButton>
        </BaseTooltip>
        <ExpandButtonContent $isShrunken={isShrunken}>
          {children}
        </ExpandButtonContent>
      </BaseAccordionItem>
    ) : type === 'accordionLink' && !isShrunken ? (
      <BaseAccordionItem value={accordionValue} data-cy={dataCy} ref={ref}>
        <NavLinkCustom to={to} end={end}>
          <ExpandButton noArrow={isShrunken} $isShrunken={isShrunken}>
            {icon}
            <Text $isShrunken={isShrunken}>{title}</Text>
          </ExpandButton>
        </NavLinkCustom>
        <ExpandButtonContent $isShrunken={isShrunken}>
          {children}
        </ExpandButtonContent>
      </BaseAccordionItem>
    ) : type === 'accordionLink' ? (
      <BaseAccordionItem value={accordionValue} data-cy={dataCy} ref={ref}>
        <BaseTooltip message={title}>
          <NavLinkCustom to={to} end={end}>
            <ExpandButton noArrow={isShrunken} $isShrunken={isShrunken}>
              {icon}
              <Text $isShrunken={isShrunken}>{title}</Text>
            </ExpandButton>
          </NavLinkCustom>
        </BaseTooltip>
        <ExpandButtonContent $isShrunken={isShrunken}>
          {children}
        </ExpandButtonContent>
      </BaseAccordionItem>
    ) : type === 'button' && !isShrunken ? (
      <ButtonItem
        $isShrunken={isShrunken}
        data-cy={dataCy}
        onClick={onClick}
        ref={ref}
      >
        {icon}
        <Text $isShrunken={isShrunken}>{title ?? children}</Text>
      </ButtonItem>
    ) : type === 'button' ? (
      <BaseTooltip message={title}>
        <ButtonItem
          $isShrunken={isShrunken}
          data-cy={dataCy}
          onClick={onClick}
          ref={ref}
        >
          {icon}
          <Text $isShrunken={isShrunken}>{title ?? children}</Text>
        </ButtonItem>
      </BaseTooltip>
    ) : type === 'link' && !isShrunken ? (
      <LinkItem
        $isShrunken={isShrunken}
        data-cy={dataCy}
        end={end}
        ref={ref}
        to={to}
        className={isActive === true ? 'active' : ''}
      >
        {icon}
        <Text $isShrunken={isShrunken}>{title ?? children}</Text>
      </LinkItem>
    ) : (
      <BaseTooltip message={title}>
        <LinkItem
          $isShrunken={isShrunken}
          data-cy={dataCy}
          end={end}
          ref={ref}
          to={to}
        >
          {icon}
          <Text $isShrunken={isShrunken}>{title ?? children}</Text>
        </LinkItem>
      </BaseTooltip>
    );
  }
);
