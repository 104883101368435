import styled from 'styled-components';

export const SearchContainer = styled.div`
  padding-bottom: 1rem;
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  padding: 1rem;
  overflow: auto;
`;
