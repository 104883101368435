import {
  type IPaginationMetaProps,
  type IPaginationResponseData,
  type IResponseData,
  api,
} from './api';
import { globalDocumentPath } from './courses';

export interface IDocumentDTO {
  id: number;
  name: string;
  filename: string;
  extension: string;
  path: string;
  isGlobal: boolean;
}

export interface IClientFileDTO {
  cloudStorageKey: string;
  path: string;
  extension: string;
  createdAt: string;
}

export interface IDocumentFailedDTO {
  uploadFileId: number;
  message: string;
  fileName: string;
  documentName: string | null;
}

export interface IUploadImageResponseData {
  error?: IDocumentFailedDTO[];
  success?: IDocumentDTO[];
}

export interface IPaginationDocumentDTO {
  data: IDocumentDTO[];
  meta: IPaginationMetaProps;
}

export interface IGetListDocumentsByCompanyIdProps {
  clientId: string;
  page: number;
  limit: number;
  filter: string;
}
export interface IGetDocumentsByCompanyIdProps {
  clientId: string;
}

export type IUploadDocumentProps = Pick<IDocumentDTO, 'id'> & {
  newImage: File;
};

export interface IRenameDocumentProps {
  documentId: string;
  newName: string;
}

export interface IDeleteDocumentProps {
  documentId: string;
}

export const getDocumentByID = async (documentId: string): Promise<string> => {
  const result = await api
    .get<string>(`api/documents/${documentId}`, {
      responseType: 'arraybuffer',
    })
    .then((resp) => {
      const raw64 = Buffer.from(resp.data, 'binary').toString('base64');
      return `data:${resp.headers['content-type']};base64, ${raw64}`;
    })
    .catch((err) => {
      throw err;
    });

  return result;
};

export const getListDocumentsByClient = async ({
  clientId,
  page,
  limit,
  filter,
}: IGetListDocumentsByCompanyIdProps): Promise<IPaginationDocumentDTO> => {
  const result = await api
    .get<IPaginationResponseData<IDocumentDTO[]>>(
      `api/documents/list/company/${clientId}?page=${page}&limit=${limit}&filter=${encodeURIComponent(
        filter
      )}`
    )
    .then((resp) => resp.data)
    .catch((err) => {
      throw err;
    });

  return result;
};

export const getDocumentsByClient = async ({
  clientId,
}: IGetDocumentsByCompanyIdProps): Promise<IDocumentDTO[]> => {
  const result = await api
    .get<IResponseData<IDocumentDTO[]>>(`api/documents/company/${clientId}`)
    .then((resp) => resp.data.data)
    .catch((err) => {
      throw err;
    });

  return result;
};

export const uploadDocument = async ({
  id,
  newImage,
}: IUploadDocumentProps): Promise<IDocumentDTO> => {
  const data = new FormData();
  data.append('document', newImage, newImage.name);
  const result = await api
    .post<IResponseData<IDocumentDTO>>(`api/documents/company/${id}`, data)
    .then((resp) => resp.data.data)
    .catch((err) => {
      throw err;
    });

  return result;
};

export const renameDocument = async ({
  documentId,
  newName,
}: IRenameDocumentProps): Promise<IDocumentDTO> => {
  const result = await api
    .patch<IResponseData<IDocumentDTO>>(`api/documents/${documentId}`, {
      name: newName,
    })
    .then((resp) => resp.data.data)
    .catch((err) => {
      throw err;
    });

  return result;
};

export const deleteDocument = async ({
  documentId,
}: IDeleteDocumentProps): Promise<{ message: string }> => {
  const result = await api
    .delete<IResponseData>(`api/documents/${documentId}`)
    .then((resp) => resp.data.data)
    .catch((err) => {
      throw err;
    });

  return result;
};

export const globalDeleteDocument = async ({
  documentId,
}: IDeleteDocumentProps): Promise<{ message: string }> => {
  const result = await api
    .delete<IResponseData>(`${globalDocumentPath}/${documentId}`)
    .then((resp) => resp.data.data)
    .catch((err) => {
      throw err;
    });
  return result;
};

export const courseDeleteDocument = async ({
  documentId,
}: IDeleteDocumentProps): Promise<{ message: string }> => {
  const result = await api
    .delete<IResponseData>(`/api/image-library-course/${documentId}`)
    .then((resp) => resp.data.data)
    .catch((err) => {
      throw err;
    });
  return result;
};

export const templateDeleteDocument = async ({
  documentId,
}: IDeleteDocumentProps): Promise<{ message: string }> => {
  const result = await api
    .delete<IResponseData>(`/api/image-library-template/${documentId}`)
    .then((resp) => resp.data.data)
    .catch((err) => {
      throw err;
    });
  return result;
};
