import { useEffect } from 'react';
import { generatePath, useNavigate, useSearchParams } from 'react-router-dom';

import {
  type IResponseAccountWithCodeDTO,
  useAuth,
} from '@gbs-monorepo-packages/auth';
import { Logger, getRouteFrom } from '@gbs-monorepo-packages/common';

import { ANALYTICS, COURSES, EDIT_COURSE } from '../../constants/RoutePaths';
import { redirectDashboard } from '../../services/dashboard';
import { BaseLoaderContainer, Container, Loading } from './styles';

export const RedirectLogin = (): JSX.Element => {
  const { saveUser } = useAuth();
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();

  useEffect(() => {
    const selectedClientId = searchParams.get('selectedClientId');

    const redirPage = searchParams.get('redirTo');
    const courseId = searchParams.get('course');

    redirectDashboard()
      .then((response: IResponseAccountWithCodeDTO) => {
        switch (redirPage) {
          case 'course':
            if (selectedClientId === null || courseId === null) {
              saveUser(response.data);
            } else {
              saveUser(response.data, null);

              navigate(
                generatePath(getRouteFrom(EDIT_COURSE), {
                  clientId: selectedClientId,
                  courseId,
                })
              );
            }
            break;

          case 'courses':
            if (selectedClientId === null) {
              saveUser(response.data);
            } else {
              saveUser(response.data, null);

              navigate(
                generatePath(getRouteFrom(COURSES), {
                  clientId: selectedClientId,
                })
              );
            }
            break;

          case 'analytics':
            if (selectedClientId === null) {
              saveUser(response.data);
            } else {
              saveUser(response.data, null);

              navigate(
                generatePath(getRouteFrom(ANALYTICS), {
                  clientId: selectedClientId,
                })
              );
            }
            break;

          default:
            saveUser(response.data);
            break;
        }
      })
      .catch((error) => {
        Logger.debug('error: ', error);
      });
  }, []);

  return (
    <Container data-cy="loading-container">
      <BaseLoaderContainer>
        <Loading />
      </BaseLoaderContainer>
    </Container>
  );
};
