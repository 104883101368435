import { type IResponseAccountDTO, type IUserDTO } from '../services';

export const generateUserUrlSearchParams = (
  { firstName, lastName, email, roles, clientId, id }: IUserDTO,
  token: string
): URLSearchParams => {
  const infoAsQueryParams = new URLSearchParams();
  infoAsQueryParams.append('firstName', firstName);
  infoAsQueryParams.append('lastName', lastName);
  infoAsQueryParams.append('email', email);
  roles.forEach((role) => {
    infoAsQueryParams.append('roles', role);
  });
  infoAsQueryParams.append('clientId', clientId.toString());
  infoAsQueryParams.append('id', id.toString());

  infoAsQueryParams.append('token', token);

  return infoAsQueryParams;
};
type IUserInfoUrl = Pick<IResponseAccountDTO, 'data' | 'token'>;

export const parseUserUrlSearchParams = (
  params: URLSearchParams
): IUserInfoUrl => {
  const token = params.get('token') ?? '';

  const firstName = params.get('firstName') ?? '';
  const lastName = params.get('lastName') ?? '';
  const email = params.get('email') ?? '';
  const roles = params.getAll('roles');
  const clientId = Number(params.get('clientId'));
  const id = Number(params.get('id'));

  const data: IUserInfoUrl['data'] = {
    firstName,
    lastName,
    email,
    roles,
    clientId,
    id,
  };

  return { data, token };
};
