import { FaFile } from 'react-icons/fa';
import styled, { css } from 'styled-components';

import { Button, TableCell } from '../../../components';

export const FileIcon = styled(FaFile)`
  height: 1.5rem;
  width: 1.5rem;
`;

export const TableCellCustom = styled(TableCell)`
  padding: 1rem;
`;

export const DropdownButtonContainer = styled.div`
  align-items: center;
  display: inline-flex;
  height: 100%;
  justify-content: flex-end;
  width: 2.75rem;
`;

export const ButtonContent = styled.div`
  align-items: center;
  display: flex;
  justify-content: space-between;
`;

export const IconContainer = styled.div`
  align-items: center;
  display: flex;
  justify-content: center;
  margin-left: 0.25rem;
`;

interface IButtonProps {
  $accept: boolean;
}

export const ButtonAction = styled(Button)<IButtonProps>`
  padding: 0.5rem;

  ${({ $accept }) =>
    $accept &&
    css`
      opacity: 0.5;
      &:hover {
        opacity: 1;
      }
    `}
`;
