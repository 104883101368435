import {
  type IFolderDTO,
  type IPaginationMetaProps,
  type IPaginationResponseData,
  type IResponseData,
} from '@gbs-monorepo-packages/common';

import { api } from './api';

const baseApiDocuments = 'api/documents/';

interface IUser {
  id: number;
  fullName: string;
}
export interface IDocumentDTO {
  id: number;
  name: string | null;
  folder: {
    id: number;
    name: string;
  };
  status: string;
  cloudStorageKey: string;
  path: string;
  extension: string;
  createdAt: string;
  updatedAt?: string;
  originalName: string | null;
  highlightedDate: string | null;
  documentType: string;
  createdBy: IUser | null;
}

export interface IResponseDataFolderItems {
  documents: IDocumentDTO[];
  subFolders: IFolderDTO[];
}

export interface IPaginationDocumentDTO {
  data: IResponseDataFolderItems;
  meta: IPaginationMetaProps;
}

export interface IPaginationDocumentAllDTO {
  data: IDocumentDTO[];
  meta: IPaginationMetaProps;
}

export interface IGetListDocumentsByFolderIdProps {
  folderId: string;
  page: number;
  limit: number;
  filter?: string;
  sort?: string;
}

export interface IGetDocumentsByFolderIdProps {
  folderId: string;
}

export interface IUploadDocumentProps {
  document: File;
  name: string;
  highlighted: boolean;
}

export interface IRenameDocumentProps {
  documentId: string;
  description: string | null;
  highlighted: boolean;
}

export interface IDeleteDocumentProps {
  documentId: string;
}

export interface IGetDocumentByIdProps {
  documentId: string;
}

export interface IDocumentFailedDTO {
  uploadFileId: number;
  message: string;
  fileName: string;
  documentName: string | null;
}

export const getListDocumentsByFolder = async ({
  folderId,
  page,
  limit,
  filter = '',
  sort = '',
}: IGetListDocumentsByFolderIdProps): Promise<IPaginationDocumentDTO> => {
  const result = await api
    .get<IPaginationResponseData<IResponseDataFolderItems>>(
      `${baseApiDocuments}${folderId}/list/pfm?page=${page}&limit=${limit}&filter=${encodeURIComponent(
        filter
      )}&sorted=${sort}`
    )
    .then((resp) => resp.data)
    .catch((err) => {
      throw err;
    });

  return result;
};

export const uploadDocument = async (
  folderId: string,
  documents: IUploadDocumentProps[]
): Promise<IDocumentDTO[]> => {
  const data = new FormData();

  data.append('folderId', folderId);

  documents.forEach((doc) => {
    data.append('files[]', doc.document, doc.document.name);
    data.append('names[]', doc.name);
    data.append('highlighted[]', (doc.highlighted ?? false).toString());
  });

  const result = await api
    .post<IResponseData<IDocumentDTO[]>>(`${baseApiDocuments}pfm`, data)
    .then((resp) => resp.data.data)
    .catch((err) => {
      throw err;
    });

  return result;
};

export const renameDocument = async ({
  documentId,
  description,
  highlighted,
}: IRenameDocumentProps): Promise<IDocumentDTO> => {
  const result = await api
    .patch<IResponseData<IDocumentDTO>>(
      `${baseApiDocuments}${documentId}/pfm`,
      {
        name: description,
        highlighted,
      }
    )
    .then((resp) => resp.data.data)
    .catch((err) => {
      throw err;
    });

  return result;
};

export const deleteDocument = async ({
  documentId,
}: IDeleteDocumentProps): Promise<{ message: string }> => {
  const result = await api
    .delete<IResponseData>(`${baseApiDocuments}${documentId}/pfm`)
    .then((resp) => resp.data.data)
    .catch((err) => {
      throw err;
    });

  return result;
};

export const getPrivateDocument = async ({
  documentId,
}: IGetDocumentByIdProps): Promise<{ data: string }> => {
  const result = await api
    .get<{ data: string }>(`${baseApiDocuments}private/${documentId}/`)
    .then((resp) => resp.data)
    .catch((err) => {
      throw err;
    });
  return result;
};

export const getPublicDocument = async ({
  documentId,
}: IGetDocumentByIdProps): Promise<{ data: string }> => {
  const result = await api
    .get<{ data: string }>(`${baseApiDocuments}public/${documentId}/`)
    .then((resp) => resp.data)
    .catch((err) => {
      throw err;
    });
  return result;
};

export const getListAllDocumentsByFolder = async ({
  folderId,
  page,
  limit,
  filter = '',
  sort = '',
}: IGetListDocumentsByFolderIdProps): Promise<IPaginationDocumentAllDTO> => {
  const result = await api
    .get<IPaginationResponseData<IDocumentDTO[]>>(
      `${baseApiDocuments}folder/${folderId}/all/documents?page=${page}&limit=${limit}&filter=${encodeURIComponent(
        filter
      )}&sorted=${sort}`
    )
    .then((resp) => resp.data)
    .catch((err) => {
      throw err;
    });

  return result;
};
